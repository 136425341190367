import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';
import TabNav from '@bfly/ui/TabNav';

import AppPage from 'components/AppPage';
import Page from 'components/Page';

const propTypes = {
  match: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

function TaskPage({ match, data, children }) {
  const { taskId } = match.params;

  useEffect(() => {
    document.title = `Task Detail - Butterfly`;
  });

  return (
    <AppPage viewer={data.viewer}>
      <Page.Header backTo="/-/admin/tasks">
        <Header.Title>{taskId}</Header.Title>
      </Page.Header>

      <MainContent>
        <TabNav>
          <TabNav.LinkItem to={`/-/admin/tasks/${taskId}`} exact>
            Definition
          </TabNav.LinkItem>
          <TabNav.LinkItem
            to={`/-/admin/tasks/${taskId}/reference-images`}
            exact
          >
            Reference Images
          </TabNav.LinkItem>
          <TabNav.LinkItem to={`/-/admin/tasks/${taskId}/worklists`}>
            Worklists
          </TabNav.LinkItem>
          <TabNav.LinkItem to={`/-/admin/tasks/${taskId}/contracts`}>
            Contracts
          </TabNav.LinkItem>
        </TabNav>
        {children}
      </MainContent>
    </AppPage>
  );
}

TaskPage.propTypes = propTypes;
TaskPage.getData = async (args) => ({
  viewer: await AppPage.getData(args),
});

export default TaskPage;
