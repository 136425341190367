import { css } from 'astroturf';
import classNames from 'classnames';
import React from 'react';
import Editor from 'react-simple-code-editor';

import * as Prism from '../utils/Prism';

import formStyles from '@bfly/ui/FormControls.module.scss';
import prismStyles from '../styles/prism.module.scss';

const styles = css`
  @import '~@bfly/ui//styles/theme';

  .container {
    flex: 1;
  }

  .error {
    display: block;
    font-weight: $font-weight-bold;
    font-style: normal;
    color: theme-color('danger');
    margin-top: 0.5em;
    text-align: left;
  }
  .control {
    composes: form-control from '~@bfly/ui/FormControls.module.scss';

    height: auto;
    width: 100%;
  }
`;

function DefinitionErrors({ errors, name }) {
  return (
    <div className={styles.error}>
      {[].concat(...Object.values(errors)).map((m) => (
        <div key={m.path}>
          {m.message.replace(new RegExp(`${name}\\.`, 'g'), '')}
        </div>
      ))}
    </div>
  );
}

function TaskDefinitionControl({
  className,
  onChange,
  value,
  theme = 'light',
  highlight = Prism.highlightJson,
  ...props
}) {
  return (
    <div className={classNames(className, styles.container)}>
      <Editor
        value={value}
        onValueChange={onChange}
        padding={16}
        highlight={highlight}
        className={classNames(
          styles.control,
          formStyles[theme],
          prismStyles.theme,
          prismStyles[theme],
        )}
      />
      <DefinitionErrors errors={props.meta.errors} name={props.name} />
    </div>
  );
}

export default TaskDefinitionControl;
