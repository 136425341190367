/**
 * A Queue-like class that debounces requests such that only the last queued
 * value is processed after the current pending one finishes. Useful for
 * preventing network requests while one is currently inflight but will fire
 * the next one with the most recent value when finished
 */
class RequestQueue<T = any> {
  // FIXME: Convert this to a hook. need to update this fn on every change.
  public _save: (key: string, value: T) => Promise<T>;

  private cache: Map<
    string,
    { currentRequest: Promise<void> | null; nextValue: T | null }
  >;

  constructor(save) {
    this._save = save;
    this.cache = new Map();
  }

  processValuesForKey(key) {
    const state = this.cache.get(key)!;

    const next = () => {
      state.currentRequest = null;
      this.processValuesForKey(key);
    };

    if (!state.currentRequest && state.nextValue) {
      state.currentRequest = this._save(key, state.nextValue).then(next, next);
      state.nextValue = null;
    }
  }

  save(key, value) {
    let state = this.cache.get(key);
    if (!state) {
      this.cache.set(
        key,
        (state = {
          currentRequest: null,
          nextValue: null,
        }),
      );
    }

    state.nextValue = value;
    this.processValuesForKey(key);
  }
}

export default RequestQueue;
