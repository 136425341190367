import styled from 'astroturf';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Layout from '@4c/layout';
import Form from '@bfly/ui/Form';
import MainContent from '@bfly/ui/MainContent';
import ToastContext from '@bfly/ui/ToastContext';
import useResponsive from '@bfly/ui/useResponsive';

import Api from '../Api';
import * as DlResult from '../schema/DlResultOutputs';
import Label, { deserialize, serialize } from '../schema/Label';
import * as Assignments from '../utils/Assignments';
import executeWithErrorToast from '../utils/executeWithErrorToast';
import AnnotatableCine from './AnnotatableCine';
import AppPage from './AppPage';
import { useApi } from './AuthProvider';
import { getCalculations } from '../utils/calculations';
import CommonShortcuts from './CommonShortcuts';
import InstructionsLightbox from './InstructionsLightbox';
import InstructionsModal from './InstructionsModal';
import IntervalsCine from './IntervalsCine';
import IntervalsField from './IntervalsField';
import MobileWarningPage from './MobileWarningPage';
import DataOutputsFieldset from './DataOutputsFieldset';
import QuestionsFieldset from './QuestionsFieldset';
import SkipButton from './SkipButton';
import SkipFieldset from './SkipFieldset';
import SubmitReviewButton from './SubmitReviewButton';
import SubmitSystemTaskButton from './SubmitSystemTaskButton';
import TracesCine from './TracesCine';
import TracesFieldset from './TracesFieldset';
import TracesGraphField from './TracesGraphField';

const graphFile = {
  x: [],
  y: [],
};

const Shortcuts = styled('div')`
  @import '~@bfly/ui/styles/theme';

  position: sticky;
  top: 60px;
  bottom: 0;
  padding: 1.6rem;
  width: 30rem;
`;

const Outputs = styled('div')`
  @import '~@bfly/ui/styles/theme';

  position: sticky;
  top: 6rem;
  padding: 1.6rem;
  width: 30rem;
`;

const TASK_TO_RENDER_MAP = {
  'probe-pose-points': 'probeIq',
};

async function getData({ params, context, router }) {
  const { worklistId, username, assignmentId } = params;
  const { api } = context;

  const [viewer, data] = await Promise.all([
    AppPage.getData({ context }),
    Assignments.fetch({
      username,
      assignmentId,
      worklistId,
      api,
    }),
  ]);

  if (!data || !data.assignment) {
    return router.replace(`/${viewer.username}`);
  }

  const {
    task,
    reviewingTask,
    reviewingLabel,
    label,
    enabledFrames,
    assignment,
    dlResultOutputs,
    ...rest
  } = data;

  const isReviewing = !!reviewingLabel;
  const { start_frame: startFrame, end_frame: endFrame } = assignment;
  const slicedDlResultOutputs =
    dlResultOutputs &&
    DlResult.sliceFrames(
      dlResultOutputs,
      startFrame,
      endFrame ? endFrame + 1 : undefined,
    );

  // When reviewing we swap the "main" task for the one being reviewed
  return {
    viewer,
    enabledFrames,
    task: isReviewing ? reviewingTask : task,
    reviewTask: isReviewing ? task : null,
    label: isReviewing ? reviewingLabel : label,
    reviewLabel: isReviewing ? label : null,
    // We don't allow assignments to be completed except by the assigned user
    // This will make the page read-only for other admin users
    canSubmitLabel: context.api.username === username,
    assignment,
    dlResultOutputs: slicedDlResultOutputs,
    ...rest,
  };
}

function AssignmentSubmit({ readOnly, data, onSubmit }) {
  const { viewer, reviewTask, task, reviewLabel, assignment, canSubmitLabel } =
    data;

  if ((readOnly && !reviewTask) || !canSubmitLabel) {
    return null;
  }

  if (task.taskType === 'system' || reviewTask?.taskType === 'system') {
    return (
      <SubmitSystemTaskButton
        assignment={assignment}
        viewer={viewer}
        task={reviewTask || task}
        reviewLabel={reviewLabel}
        onSubmit={onSubmit}
      />
    );
  }

  if (reviewTask) {
    return (
      <SubmitReviewButton
        viewer={viewer}
        reviewTask={reviewTask}
        reviewLabel={reviewLabel}
        assignment={assignment}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <>
      {task.definition.skip && <SkipButton skip={task.definition.skip} />}
      <Form.Submit>Submit and Continue</Form.Submit>
    </>
  );
}

function AssignmentInstructions({
  hasReferenceImages,
  hasVideoInstructions,
  ...props
}) {
  return !(hasReferenceImages || hasVideoInstructions) ? (
    <InstructionsModal {...props} />
  ) : (
    <InstructionsLightbox {...props} />
  );
}

const propTypes = {
  api: PropTypes.instanceOf(Api).isRequired,
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  header: PropTypes.node,
};

const defaultProps = {
  readOnly: false,
};

class AssignmentForm extends React.Component {
  constructor(props) {
    super(props);

    const { viewer, task, label, frames } = this.props.data;

    this.formRef = React.createRef();
    const value = deserialize(label || {}, task.definition, viewer);

    if (task.definition.intervals && frames.length === 1) {
      value.activeInterval = [0, 0];
    }

    this.state = {
      value,
      frameIndex: 0,
    };
  }

  componentDidMount() {
    this.timings = {
      timeAtLoad: Date.now(),
      timeToFirstFrame: null,
      timeToLastFrame: null,
      timeToSubmit: null,
    };
    document.addEventListener('keydown', this.handleKeydown);

    const { task } = this.props.data;
    document.title = `${task.name} - Butterfly`;
  }

  componentDidUpdate() {
    const { timeAtLoad, timeToFirstFrame, timeToLastFrame } = this.timings;
    const { images } = this.props;

    if (images[0] && !timeToFirstFrame) {
      this.timings.timeToFirstFrame = Date.now() - timeAtLoad;
    }
    if (images[images.length - 1] && !timeToLastFrame) {
      this.timings.timeToLastFrame = Date.now() - timeAtLoad;
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  handleKeydown = (event) => {
    const { readOnly } = this.props;

    if (!readOnly && event.key === 'Enter' && this.formRef.current) {
      this.formRef.current.submit();
    }
  };

  handleChange = (value, paths) => {
    // clear the selected frame interval after selecting _unless_ the Cine is a single
    // frame, in which case we keep it selected so a user doesn't need to click
    // on the one frame to change their annotation
    if (
      paths.find((d) => d.startsWith('intervals')) &&
      !this.isSingleFrameFrameTask
    ) {
      // eslint-disable-next-line no-param-reassign
      value.activeInterval = null;
    }
    this.setState({ value });
  };

  handleSelectFrame = (frameIndex) => {
    this.setState({ frameIndex });
  };

  get isSingleFrameFrameTask() {
    const { task, frames } = this.props.data;

    return frames.length === 1 && task.definition.intervals;
  }

  submitForm = async (formValue) => {
    const { data, api, onSubmit, readOnly } = this.props;
    const { file, assignment, task, canSubmitLabel } = data;

    if (readOnly || !canSubmitLabel) return;

    const serialized = serialize(formValue, task.definition);
    const annotations = serialized.results;

    const calculations = await getCalculations(
      task.definition.calculations,
      annotations,
      file,
      api,
    );

    Object.assign(annotations, calculations);

    const label = {
      annotations,
      assignment_id: assignment.assignment_id,
      version: task.version,
      timings: {
        ...this.timings,
        timeToSubmit: Date.now() - this.timings.timeAtLoad,
      },
    };

    await executeWithErrorToast(this.props.toast, () =>
      api.saveLabel(assignment.assignment_id, label),
    );

    onSubmit();
  };

  render() {
    const {
      api,
      data,
      images,
      compImages,
      readOnly,
      header,
      match: { params },
    } = this.props;
    const {
      viewer,
      file,
      compFile,
      task,
      reviewTask,
      enabledFrames,
      dlResultOutputs,
    } = data;
    const { value, frameIndex } = this.state;

    const wasSkipped = readOnly && value.skipped;
    const hasEnabledFrames = enabledFrames && enabledFrames.length > 0;
    const isEnabledFrame = hasEnabledFrames
      ? enabledFrames.includes(this.state.frameIndex)
      : true;
    const hasDlResultOverlays =
      !!dlResultOutputs?.lineComponentOutputs.length ||
      !!dlResultOutputs?.coordinateOutputs.length;

    const hasDlResultDataOutputs =
      !!dlResultOutputs?.frameDataOutputs.length ||
      !!dlResultOutputs?.cineDataOutputs.length;

    return (
      <Form
        as={AppPage}
        schema={Label}
        value={value}
        ref={this.formRef}
        onChange={this.handleChange}
        submitForm={this.submitForm}
        formContext={{ task: task.definition }}
        viewer={viewer}
        sidePanel={false}
      >
        {header}
        <Layout align="flex-start">
          {hasDlResultDataOutputs && (
            <Outputs>
              {!!dlResultOutputs.frameDataOutputs?.length && (
                <DataOutputsFieldset
                  legend="Frame Outputs"
                  outputs={dlResultOutputs.frameDataOutputs.map((output) => ({
                    key: output.key,
                    label: output.label,
                    value: output.value[frameIndex],
                  }))}
                />
              )}
              {!!dlResultOutputs.cineDataOutputs?.length && (
                <DataOutputsFieldset
                  legend="Cine Outputs"
                  outputs={dlResultOutputs.cineDataOutputs}
                />
              )}
            </Outputs>
          )}
          <MainContent size="large">
            <AssignmentInstructions
              readOnly={readOnly}
              task={reviewTask || task}
              username={params.username}
              hasReferenceImages={task.referenceImages.length > 0}
              hasVideoInstructions={!!task.definition.instructionsVimeoId}
            />
            {/* eslint-disable no-nested-ternary */}
            {task.definition.type === 'cine' ? (
              <AnnotatableCine
                api={api}
                file={file}
                frames={images}
                compFile={compFile}
                compFrames={compImages}
                task={task.definition}
                enabledFrames={enabledFrames}
                onSelectFrame={this.handleSelectFrame}
                dlResultOutputs={dlResultOutputs}
              />
            ) : task.definition.type === 'pixel' ? (
              <TracesCine
                api={api}
                file={file}
                task={task.definition}
                renderObject={TASK_TO_RENDER_MAP[task.name]}
                frames={images}
                assignment={data.assignment}
                readOnly={readOnly}
                activeTrace={value.activeTrace}
                intervalAnnotations={value.intervals}
                enabledFrames={enabledFrames}
                onSelectFrame={this.handleSelectFrame}
                dlResultOutputs={dlResultOutputs}
              />
            ) : task.definition.type === 'frame' ? (
              <IntervalsCine
                api={api}
                file={file}
                compFile={compFile}
                task={task.definition}
                frames={images}
                compFrames={compImages}
                readOnly={readOnly}
                intervalAnnotations={value.intervals}
                enabledFrames={enabledFrames}
                onSelectFrame={this.handleSelectFrame}
                dlResultOutputs={dlResultOutputs}
              />
            ) : task.definition.type === 'graph' ? (
              <TracesGraphField
                file={graphFile}
                task={task.definition}
                activeTrace={value.activeTrace}
              />
            ) : null}
            {/* eslint-disable no-nested-ternary */}
          </MainContent>
          <Shortcuts>
            {wasSkipped && (
              <SkipFieldset readOnly skip={task.definition.skip} />
            )}

            {task.definition.intervals && (
              <IntervalsField
                readOnly={readOnly}
                intervals={task.definition.intervals}
                activeInterval={value.activeInterval}
                activeTrace={value.activeTrace}
              />
            )}

            {task.definition.traces && (
              <TracesFieldset
                readOnly={readOnly || !isEnabledFrame}
                traces={task.definition.traces}
              />
            )}

            {task.definition.questions && (
              <QuestionsFieldset
                readOnly={readOnly}
                questions={task.definition.questions}
              />
            )}

            <CommonShortcuts
              task={task}
              readOnly={readOnly}
              hasEnabledFrames={hasEnabledFrames}
              hasDlResultOverlays={hasDlResultOverlays}
            />
          </Shortcuts>
        </Layout>
      </Form>
    );
  }
}

// FIXME(ethan): refactor to HOCs
const DecoratedAssignmentForm = (props) => {
  const api = useApi();
  const toast = useContext(ToastContext);

  const { images, compImages } = Assignments.useFrameData(props.data);

  const isDesktop = useResponsive('md', 'up');

  if (!isDesktop) {
    return <MobileWarningPage />;
  }

  return (
    <AssignmentForm
      {...props}
      images={images}
      compImages={compImages}
      api={api}
      toast={toast}
    />
  );
};

AssignmentForm.propTypes = propTypes;
AssignmentForm.defaultProps = defaultProps;

DecoratedAssignmentForm.getData = getData;
DecoratedAssignmentForm.Submit = AssignmentSubmit;

export default DecoratedAssignmentForm;
