import { routerShape } from 'found/lib/PropTypes';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';
import ToastContext from '@bfly/ui/ToastContext';

import AppPage from 'components/AppPage';
import { useApi } from 'components/AuthProvider';
import Page from 'components/Page';
import UserProfileForm from 'components/UserProfileForm';
import executeWithErrorToast from 'src/utils/executeWithErrorToast';

const propTypes = {
  data: PropTypes.object.isRequired,

  // The routing mechanism used for links.
  router: routerShape.isRequired,
};

function NewUserPage({ data, router }) {
  const { viewer } = data;
  const api = useApi();
  const toast = useContext(ToastContext);

  const params = new URLSearchParams(window.location.search);
  const initialEmail = params.get('email', null);

  const submitForm = async (updatedUser) => {
    await executeWithErrorToast(toast, () => api.createUser(updatedUser));
    router.push(`/-/admin/users/${updatedUser.username}/contracts`);
  };

  useEffect(() => {
    document.title = `New User - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Page.Header backTo="/-/admin/users">
        <Header.Title>New User</Header.Title>
      </Page.Header>
      <MainContent size="medium">
        <UserProfileForm
          user={null}
          initialEmail={initialEmail}
          submitForm={submitForm}
        />
      </MainContent>
    </AppPage>
  );
}

NewUserPage.propTypes = propTypes;
NewUserPage.getData = async (args) => ({
  viewer: await AppPage.getData(args),
});

export default NewUserPage;
