import React from 'react';
import Layout from '@4c/layout';

import Shortcut from './Shortcut';
import TagButton from './TagButton';

function ToggleButtonGroup({
  data,
  value,
  onChange,
  disabled,
  readOnly,
  name,
  meta: _,
  ...props
}) {
  const strValue = (value && value.value) || value;
  const isDisabled = disabled || readOnly;

  return (
    <Layout pad direction="column" justify="center" {...props}>
      {data.map((item) => {
        const key = item.value;
        const checked = strValue === key;

        return (
          <TagButton
            name={name}
            key={key}
            active={!!checked}
            disabled={isDisabled}
            onClick={() => {
              onChange(checked ? null : key);
            }}
          >
            {item.shortcut && (
              <Shortcut
                char={item.shortcut}
                color={item.color}
                handler={() => !isDisabled && onChange(item.value)}
              />
            )}{' '}
            {item.displayText}
          </TagButton>
        );
      })}
    </Layout>
  );
}

export default ToggleButtonGroup;
