export function formatForRoute(date: Date | null): string | undefined {
  if (!date) {
    return undefined;
  }

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function parseFromRoute(date: string | null): string | undefined {
  if (!date) {
    return undefined;
  }

  return new Date(date).toISOString();
}
