/* eslint-disable react/style-prop-object */
import { css } from 'astroturf';
import { Match } from 'found';
import React, { useEffect } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Layout from '@4c/layout';
import Card from '@bfly/ui/Card';
import Header from '@bfly/ui/Header';
import Heading from '@bfly/ui/Heading';
import MainContent from '@bfly/ui/MainContent';
import Text from '@bfly/ui/Text';
import Tooltip from '@bfly/ui/Tooltip';

import AppPage from '../components/AppPage';
import EarningSummary from '../components/EarningSummary';
import FormattedCurrency from '../components/FormattedCurrency';
import FormattedMonth from '../components/FormattedMonth';
import Page from '../components/Page';
import SummaryCard from '../components/SummaryCard';
import Table from '../components/Table';
import { User } from '../models';
import { Earning, deserializeEarnings } from '../schema/Earning';
import {
  EarningDetail,
  deserializeEarningDetails,
} from '../schema/EarningDetail';
import { parseFromRoute } from '../utils/date';

const styles = css`
  @import '~@bfly/ui/styles/theme';

  .card-top {
    width: 100%;
    max-width: 15rem;
  }

  .table .row {
    td,
    th {
      padding: spacer(5) spacer(4);
    }
  }
`;

async function getData({ params, context }) {
  const { username } = params;
  const { api } = context;

  const startDate = parseFromRoute(params.startDate)!;
  const endDate = parseFromRoute(params.endDate);

  const [viewer, { earnings, latest }, earningsDetails] = await Promise.all([
    AppPage.getData({ context }),
    api
      .getEarnings({ startDate, endDate, username })
      .then(deserializeEarnings),
    api
      .getEarningsDetails({ startDate, endDate, username })
      .then(deserializeEarningDetails),
  ]);

  const total = latest || earnings[0];

  return {
    viewer,
    earningsDetails,
    total,
  };
}

interface Props {
  match: Match;
  data: {
    viewer: User;
    total: Earning;
    earningsDetails: EarningDetail[];
  };
}

function EarningsPeriodPage({ data, match }: Props) {
  const { viewer, earningsDetails, total } = data;
  const { username } = match.params;

  useEffect(() => {
    const dateText = total.startDate.toLocaleDateString('en-US', {
      month: 'long',
    });

    document.title =
      viewer.username === username
        ? `Earnings Detail - ${dateText} - Butterfly`
        : `Earnings Detail - ${username} - ${dateText} - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Page.Header backTo={`/earnings/${username}/`}>
        <Header.Title>Back to Summary</Header.Title>
      </Page.Header>
      <MainContent size="medium">
        <Layout pad={4} direction="column">
          {total.endDate ? (
            <EarningSummary earning={total}>
              <Heading>
                <FormattedMonth value={total.startDate} />
                Earnings Detail
              </Heading>
            </EarningSummary>
          ) : (
            <>
              <Heading>Upcoming Earnings Detail</Heading>
              <Text>
                <FormattedMessage
                  id="earnings.since"
                  defaultMessage="Since {startDate}"
                  values={{
                    startDate: <FormattedDate value={total.startDate} />,
                  }}
                />
              </Text>
            </>
          )}
          <SummaryCard className={styles.cardTop}>
            <SummaryCard.Header>Total</SummaryCard.Header>
            <SummaryCard.Body>
              <FormattedCurrency value={total.totalAmount} />
            </SummaryCard.Body>
          </SummaryCard>

          {earningsDetails.length > 0 && (
            <Card className="py-3 px-5">
              <Table className={styles.table}>
                <thead>
                  <tr>
                    <th>Task Name</th>
                    <Tooltip
                      id="completed-rate"
                      tooltip="Rate for a completed assignment"
                      placement="bottom"
                    >
                      <th>Rate</th>
                    </Tooltip>
                    <th>Completed</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {earningsDetails.map((earningsItem, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={idx} className={styles.row}>
                      <td>
                        <Text variant="body-bold" color="headline">
                          {earningsItem.taskDescription}
                        </Text>
                      </td>
                      <td>
                        <FormattedCurrency value={earningsItem.rate} />
                      </td>
                      <td>
                        <Text>{earningsItem.completed}</Text>
                      </td>
                      <td>
                        <Text color="headline">
                          <FormattedCurrency
                            value={earningsItem.totalAmount}
                          />
                        </Text>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          )}
        </Layout>
      </MainContent>
    </AppPage>
  );
}

EarningsPeriodPage.getData = getData;

export default EarningsPeriodPage;
