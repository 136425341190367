import React from 'react';
import Layout from '@4c/layout';
import Form from '@bfly/ui/Form';
import {
  CustomEarnings,
  CustomEarningsSchema,
} from '../models/CustomEarnings';

interface Props {
  submitForm: (value: CustomEarnings) => Promise<CustomEarnings>;
}

function CustomEarningsForm({ submitForm }: Props) {
  return (
    <Form schema={CustomEarningsSchema as any} submitForm={submitForm}>
      <Form.HorizontalFieldGroup name="username" label="Username" autoFocus />

      <Form.HorizontalFieldGroup name="title" label="Title" />
      <Form.HorizontalFieldGroup name="description" label="Description" />
      <Form.HorizontalFieldGroup name="amount" label="Amount" />

      <Layout justify="flex-end">
        <Form.Submit>Save</Form.Submit>
      </Layout>
    </Form>
  );
}

export default CustomEarningsForm;
