import styled from 'astroturf';

type Variant = 'default' | 'styled-header';

const Table = styled('table')<{ variant?: Variant }>`
  @import '~@bfly/ui/styles/theme';

  border-collapse: collapse;
  width: 100%;

  &.variant-styled-header {
    thead > tr {
      th {
        background-color: $grey-80;
      }

      th:first-child {
        border-top-left-radius: 8px;
      }

      th:last-child {
        border-top-right-radius: 8px;
      }
    }

    thead > tr > th:first-child,
    tbody > tr > td:first-child {
      padding-left: spacer(5);
    }
  }

  & > thead > tr > th {
    color: white;
    padding: 10px 15px;
    font-weight: inherit;
  }

  & > tbody > tr > td {
    padding: 10px 15px;
    height: 54px;
  }

  & > * > tr {
    border-bottom: 1px solid lighten($grey-85, 7%);
  }

  & > tbody > tr > td:first-child,
  & > thead > tr > th:first-child {
    padding-left: 0;
    text-align: left;
  }

  & > tbody > tr > td:last-child,
  & > thead > tr > th:last-child {
    padding-right: 0;

    text-align: right;
  }

  & > tbody > tr:last-of-type {
    border-bottom: 0;
  }
`;

export default Table;
