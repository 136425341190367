/**
 * First-in First-out Cache built on top of Map
 */
interface Node<K, V> {
  value: V;
  key: K;
  next: Node<K, V> | null;
}

export default class Cache<K, V> extends Map<K, any> {
  private head: Node<K, V> | null;

  private tail: Node<K, V> | null;

  private readonly maxSize: number;

  constructor(maxSize = Infinity) {
    super();
    this.head = null;
    this.tail = null;
    this.maxSize = Math.max(1, maxSize);
  }

  get(key: K): V | undefined {
    const v = super.get(key);
    return v && v.value;
  }

  set(key: K, value: V) {
    const obj: Node<K, V> = { value, key, next: null };
    const { tail: oldTail } = this;

    if (oldTail) oldTail.next = obj;

    this.tail = obj;
    if (!this.head) this.head = obj;

    super.set(key, obj);

    if (this.size > this.maxSize) {
      this.delete(this.head.key);
      this.head = this.head.next;
    }
    return this;
  }

  forEach(fn) {
    super.forEach((value, key) => fn(value.value, key, this));
  }

  *values() {
    for (const { value } of super.values()) yield value;
  }

  *entries(): Generator<[K, V]> {
    for (const [key, { value }] of super.entries()) yield [key, value];
  }

  [Symbol.iterator]() {
    return this.entries();
  }
}
