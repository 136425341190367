import { css } from 'astroturf';
import React, { useEffect, useRef } from 'react';

const styles = css`
  .container {
    &,
    > iframe {
      width: 100%;
      height: 100%;
    }

    > iframe {
      border: 0;
    }
  }
`;

function createVimeoPlayerUrl(vimeoId: string) {
  return `${window.bflyConfig.VIMEO_PLAYER_URL}/${vimeoId}?loop=false&byline=false&title=false&transparent=false&gesture=media&dnt=false&autoplay=true`;
}

interface Props {
  instructionsVimeoId: string;
  onEnded: () => void;
}

function TaskInstructionsVideoPlayer({ instructionsVimeoId, onEnded }: Props) {
  const playerRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    import(/* webpackChunkName: "vimeo" */ '@vimeo/player').then(
      ({ default: Player }) => {
        if (playerRef.current) {
          const player = new Player(playerRef.current);
          player.on('ended', onEnded);
        }
      },
    );
  }, [onEnded, playerRef]);

  return (
    <div className={styles.container}>
      <iframe
        title={instructionsVimeoId}
        src={createVimeoPlayerUrl(instructionsVimeoId)}
        allowFullScreen
        ref={playerRef}
      />
    </div>
  );
}

export default TaskInstructionsVideoPlayer;
