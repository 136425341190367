import { css } from 'astroturf';
import React from 'react';
import FormCheck from '@bfly/ui/FormCheck';

import ToggleButtonGroup from './ToggleButtonGroup';

const styles = css`
  @import '~@bfly/ui/styles/theme';

  :export {
    red: $red;
    green: $green;
  }
`;

interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
  configuration: {
    style: 'checkbox' | 'button';
    trueDisplayText?: string;
    falseDisplayText?: string;
  };
}

const toTagOptions = (trueDisplayText, falseDisplayText) => [
  {
    value: true,
    shortcut: trueDisplayText[0].toLowerCase(),
    displayText: trueDisplayText,
    color: styles.red,
  },
  {
    value: false,
    shortcut: falseDisplayText[0].toLowerCase(),
    displayText: falseDisplayText,
    color: styles.green,
  },
];

function BooleanInput({
  value,
  onChange,
  configuration: { style, trueDisplayText, falseDisplayText },
}: Props) {
  if (style === 'button') {
    return (
      <ToggleButtonGroup
        value={value}
        onChange={onChange}
        data={toTagOptions(trueDisplayText, falseDisplayText)}
        direction="row"
      />
    );
  }

  return (
    <FormCheck
      theme="dark"
      type="checkbox"
      checked={!!value}
      onChange={onChange}
    />
  );
}

export default BooleanInput;
