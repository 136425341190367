/* import-sort-ignore */

import './styles/global.scss';
import 'react-spinner/react-spinner.css';
import './sentry';

import BrowserProtocol from 'farce/lib/BrowserProtocol';
import createFarceRouter from 'found/lib/createFarceRouter';
import createRender from 'found/lib/createRender';
import makeRouteConfig from 'found/lib/makeRouteConfig';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import ToastContainer from '@bfly/ui/ToastContainer';
import DialogProvider from '@bfly/ui/DialogProvider';
import ToastProvider from '@bfly/ui/ToastProvider';

import AuthProvider from './components/AuthProvider';
import KeyboardShortcutManager from './components/KeyboardShortcutManager';
import LoadingIndicator from './components/LoadingIndicator';
import LoginPage from './pages/LoginPage';
import routes from './routes';
import createResolver from './utils/Resolver';
import ConnectionErrorPage from './components/ConnectionErrorPage';
import NotFoundPage from './components/NotFoundPage';
import AppErrorBoundary from './components/AppErrorBoundary';
import AppErrorPage from './components/AppErrorPage';
import AuthErrorPage from './components/AuthErrorPage';

const render = createRender({
  renderPending: () => <LoadingIndicator />,
  renderError: (
    { error }, // eslint-disable-line react/prop-types
  ) => {
    switch (error.status) {
      case 500:
        return <ConnectionErrorPage />;
      case 400:
        return <AppErrorPage />;
      case 403:
        return <AuthErrorPage />;
      case 401:
        return <LoginPage />;
      case 404:
        return <NotFoundPage />;
      default:
        return <ConnectionErrorPage />;
    }
  },
});

const Router = createFarceRouter({
  render,
  routeConfig: makeRouteConfig(routes),
  historyProtocol: new BrowserProtocol(),
});

ReactDOM.render(
  <IntlProvider locale="en" textComponent={React.Fragment}>
    <AppErrorBoundary>
      <KeyboardShortcutManager>
        <DialogProvider>
          <ToastProvider>
            <AuthProvider>
              {(api, { isAuthenticated }) => (
                <Router
                  resolver={createResolver(isAuthenticated())}
                  matchContext={{ api }}
                />
              )}
            </AuthProvider>
            <ToastContainer dismissAfter={5000} />
          </ToastProvider>
        </DialogProvider>
      </KeyboardShortcutManager>
    </AppErrorBoundary>
  </IntlProvider>,
  document.getElementById('root'),
);
