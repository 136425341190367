import snakeCase from 'lodash/snakeCase';
import { ObjectSchema, ObjectSchemaDefinition, object } from 'yup';

/** A yup Object schema that checks for extra properties */
function strictObject<T extends object>(
  fields: ObjectSchemaDefinition<T>,
): ObjectSchema<T> {
  return object(fields)
    .camelCase()
    .test({
      name: 'noUnknown',
      exclusive: true,
      test: function test(value) {
        if (value == null) return true;
        const knownKeys = Object.keys((this.schema as ObjectSchema<T>).fields);
        const unknownKeys = Object.keys(value).filter(
          (key) => knownKeys.indexOf(key) === -1,
        );

        if (unknownKeys.length === 0) return true;

        return this.createError({
          message: (d) =>
            `${d.path} cannot contain unknown properties: ${unknownKeys
              .map(snakeCase)
              .join(', ')}`,
        } as any);
      },
    });
}

export default strictObject;
