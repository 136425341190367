import React, { useContext } from 'react';
import { Popover } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Card from '@bfly/ui/Card';
import Layout from '@4c/layout';
import Button from '@bfly/ui/Button';
import { useApi } from './AuthProvider';
import ToastContext, { ToastManager } from '@bfly/ui/ToastContext';

const propTypes = {};

function WorklistDownloadButton({ worklistId = '', taskName = '' }) {
  const api = useApi();
  const toast = useContext<ToastManager | null>(ToastContext);

  return (
    <OverlayTrigger
      placement="top"
      trigger="click"
      rootClose
      overlay={
        <Popover id="download_popover">
          <Popover.Title />
          <Popover.Content>
            <Card>
              <Card.Header>Download Results as CSV</Card.Header>
              <Card.Body>
                <Layout direction="row" pad={5}>
                  <Button
                    variant="outline-draft"
                    onClick={() =>
                      api
                        .downloadWorklistResults(worklistId, taskName, false)
                        .catch((err) => {
                          toast?.error(err);
                        })
                    }
                    target="_blank"
                  >
                    Without File Metadata
                  </Button>
                  <Button
                    variant="outline-draft"
                    onClick={() =>
                      api
                        .downloadWorklistResults(worklistId, taskName, true)
                        .catch((err) => {
                          toast?.error(err);
                        })
                    }
                    target="_blank"
                  >
                    With File Metadata
                  </Button>
                </Layout>
              </Card.Body>
            </Card>
          </Popover.Content>
        </Popover>
      }
    >
      <Button variant="outline-draft">CSV</Button>
    </OverlayTrigger>
  );
}

WorklistDownloadButton.propTypes = propTypes;

export default WorklistDownloadButton;
