import styled from 'astroturf';
import React, { useEffect, useState } from 'react';
import BaseModal from 'react-overlays/Modal';
import CloseIcon from '@bfly/icons/CloseSheet';
import Carousel from '@bfly/ui/Carousel';
import { provideTheme } from '@bfly/ui/ThemeContext';

import PreviewRoll from './PreviewRoll';
import TaskInstructionsVideoPlayer from './TaskInstructionsVideoPlayer';
import useFetchHandler from '../utils/useFetchHandler';

const Image = styled('img')`
  @import '~@bfly/ui/styles/theme';

  position: relative;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Backdrop = styled('div')`
  @import '~@bfly/ui/styles/theme';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $zindex-modal-backdrop;
  background: linear-gradient(fade-out(black, 0.3), $bg-color);
`;

const Dialog = styled('div')`
  @import '~@bfly/ui/styles/theme';

  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  height: 100%;
  max-width: 80vw;
  max-height: 85vh;
  width: calc(100vw - 4rem);
  z-index: $zindex-modal;
  transform: translate(-50%, -50%);
`;

const Description = styled('div')`
  @import '~@bfly/ui/styles/theme';

  flex: 1 0 0;
  background-color: black;
  margin: 1.5rem;
  padding: 1.5rem;
  align-self: center;
  color: white;
  max-height: 10rem;
  overflow: auto;
`;

const ImageContainer = styled('div')`
  width: 100%;
  display: flex;
  max-width: calc(100vw - 44rem);
  flex-direction: column;
  background-color: black;
  overflow: auto;

  &:only-child {
    overflow: none;
    max-width: calc(100vw - 4rem);
  }
`;

const Content = provideTheme('light')(
  styled('div')`
    @import '~@bfly/ui/styles/theme';

    background-color: $grey-5;
    width: 30vw;
    overflow: auto;

    /* FIXME: headings should be theme aware upstream */
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      color: theme-value(light, text-color-secondary);
    }
  `,
);

const CloseButton = styled('button')`
  @import '~@bfly/ui/styles/theme';

  position: absolute;
  top: 3rem;
  right: 3rem;
  color: white;

  &:hover {
    color: $grey-25;
  }
`;

function createVimeoVideoUrl(vimeoId) {
  return `${window.bflyConfig.VIMEO_BASE_URL}/${vimeoId}`;
}

function useVimeoThumbnail(vimeoId) {
  const { data, loading, error } = useFetchHandler(async () => {
    if (!vimeoId) {
      return null;
    }
    const encodedUrl = encodeURIComponent(createVimeoVideoUrl(vimeoId));
    const result = await fetch(
      `${window.bflyConfig.VIMEO_BASE_URL}/api/oembed.json?url=${encodedUrl}`,
    );
    const jsonData = await result.json();
    return jsonData.thumbnail_url_with_play_button;
  }, [vimeoId]);

  return {
    thumbnailUrl: data,
    isLoading: loading,
    error,
  };
}

function renderBackdrop(onHide, props) {
  return (
    <Backdrop {...props}>
      <CloseButton onClick={onHide}>
        <CloseIcon />
      </CloseButton>
    </Backdrop>
  );
}

function RenderDialog({
  images,
  instructionsVimeoId,
  activeIndex,
  setIndex,
  children,
  props,
}) {
  const [imagePreviewItems, setImagePreviewItems] = useState([]);
  const vimeoThumbnailResults = useVimeoThumbnail(instructionsVimeoId);

  useEffect(() => {
    if (instructionsVimeoId) {
      if (vimeoThumbnailResults.thumbnailUrl) {
        setImagePreviewItems([
          { src: vimeoThumbnailResults.thumbnailUrl },
          ...images,
        ]);
      } else {
        setImagePreviewItems([{ src: null }, ...images]);
      }
    } else {
      setImagePreviewItems(images);
    }
  }, [vimeoThumbnailResults.thumbnailUrl, instructionsVimeoId, images]);

  let media;
  if (instructionsVimeoId) {
    media = [instructionsVimeoId, ...images];
  } else {
    media = images;
  }

  const active = media[activeIndex];
  const isFirst = activeIndex === 0;
  const isLast = activeIndex === media.length - 1;

  const prev = () => setIndex(Math.max(0, activeIndex - 1));
  const next = () => setIndex(Math.min(media.length - 1, activeIndex + 1));

  return (
    <Dialog {...props}>
      <ImageContainer>
        <Carousel
          isFirst={isFirst}
          isLast={isLast}
          onPrev={prev}
          onNext={next}
        >
          {isFirst && instructionsVimeoId ? (
            <TaskInstructionsVideoPlayer
              instructionsVimeoId={instructionsVimeoId}
              onEnded={() => {}}
            />
          ) : (
            <Image src={active.src} />
          )}
        </Carousel>

        {active.description && <Description>{active.description}</Description>}

        {media.length > 1 && (
          <PreviewRoll
            images={imagePreviewItems}
            activeIndex={activeIndex}
            onSelect={setIndex}
          />
        )}
      </ImageContainer>
      {children && <Content>{children}</Content>}
    </Dialog>
  );
}

const Lightbox = React.forwardRef(
  ({ images, instructionsVimeoId, children, ...props }, ref) => {
    const [activeIndex, setIndex] = useState(0);

    return (
      <BaseModal
        {...props}
        ref={ref}
        renderBackdrop={(p) => renderBackdrop(props.onHide, p)}
        renderDialog={(p) => (
          <RenderDialog
            images={images}
            instructionsVimeoId={instructionsVimeoId}
            activeIndex={activeIndex}
            setIndex={setIndex}
            p={p}
          >
            {children}
          </RenderDialog>
        )}
      />
    );
  },
);

export default Lightbox;
