import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '@4c/layout';
import BlankSlate from '@bfly/ui/BlankSlate';
import Header from '@bfly/ui/Header';
import LoadingIndicator from '@bfly/ui/LoadingIndicator';
import MainContent from '@bfly/ui/MainContent';

import AppPage from '../components/AppPage';
import ProjectDistributionChart from '../components/ProjectDistributionChart';
import ProjectFilterDropdown from '../components/ProjectFilterDropdown';
import { Project } from '../models';

async function getData({ context }) {
  const { api } = context;

  const [viewer, projects] = await Promise.all([
    AppPage.getData({ context }),
    api.getProjects(),
  ]);

  return {
    viewer,
    projects,
  };
}

function DatasetAnalyticsPage({ data }) {
  const { viewer, projects } = data;

  const [project, setProject] = useState<Project>();

  const handleChangeProject = (selectedProject) => {
    setProject(selectedProject);
  };

  useEffect(() => {
    document.title = `Analytics - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>Datasets Analytics</Header.Title>
      </Header>
      <MainContent size="large">
        <Layout justify="flex-start" pad>
          {projects.length > 0 && (
            <ProjectFilterDropdown
              projects={projects}
              value={project && project.name}
              onChange={handleChangeProject}
            />
          )}
        </Layout>
        <React.Suspense fallback={<LoadingIndicator />}>
          {!project ? (
            <BlankSlate>
              <BlankSlate.Title>
                <FormattedMessage
                  defaultMessage="Select a Project"
                  id="projectAnalytics.title"
                />
              </BlankSlate.Title>
              <BlankSlate.Body>
                <FormattedMessage
                  defaultMessage="Please select a project to see analytics data."
                  id="projectAnalytics.body"
                />
              </BlankSlate.Body>
            </BlankSlate>
          ) : (
            <Layout justify="center" wrap pad>
              <ProjectDistributionChart
                project={project}
                distributionType="cine"
              />
              <ProjectDistributionChart
                project={project}
                distributionType="study"
              />
            </Layout>
          )}
        </React.Suspense>
      </MainContent>
    </AppPage>
  );
}

DatasetAnalyticsPage.propTypes = {};
DatasetAnalyticsPage.getData = getData;

export default DatasetAnalyticsPage;
