import React from 'react';
import Form from '@bfly/ui/Form';
import AccordionFieldArray from './AccordionFieldArray';
import { Model, ModelSchema } from '../schema/Process';
import { string } from 'yup';
import DropdownList from '@bfly/ui/DropdownList';
import { defineMessages } from 'react-intl';
import { compareModelsByVersion } from '../utils/compareByVersion';

const messages = defineMessages({
  projectNameDescription: {
    id: 'projectName.description',
    defaultMessage: 'Select Associated Project...',
  },
  versionDescription: {
    id: 'version.description',
    defaultMessage: 'Select Model Version...',
  },
});

const defaultModel = ModelSchema.shape({
  dlModelVersionId: string().default('').required(),
}).default();

function ProcessModelsFieldSet({
  projectModelMap,
  disabled,
}: {
  projectModelMap: Map<string, Model[]>;
  disabled: boolean;
}) {
  const projectData = Array.from(projectModelMap.keys());
  return (
    <Form.FieldSet legend="Models">
      <Form.Message for="dlModels" />
      <AccordionFieldArray<Model>
        name="dlModels"
        label="Models"
        defaultValue={defaultModel}
        disabled={disabled}
        events="all"
      >
        {({ value, helpers }) =>
          value.map((item, idx) => {
            const name = `dlModels[${idx}]`;
            return (
              <AccordionFieldArray.Item
                key={name}
                name={name}
                disabled={disabled}
                onRemove={helpers.remove(item)}
                title={
                  item && item.version
                    ? `${item.project} ${item.version}`
                    : 'New Model'
                }
              >
                <Form.Field name="">
                  {({ value: modelValue }) => {
                    const versions = modelValue.project
                      ? projectModelMap.get(modelValue.project)
                      : [];
                    versions?.sort((a, b) => -compareModelsByVersion(a, b));

                    return (
                      <>
                        <Form.HorizontalFieldGroup
                          name="project"
                          label="Project"
                          as={DropdownList}
                          data={projectData}
                          placeholder={messages.projectNameDescription}
                          textField="project"
                        />
                        <Form.HorizontalFieldGroup
                          name="version"
                          label="Version"
                          mapFromValue={{
                            version: 'version',
                            dlModelVersionId: 'dlModelVersionId',
                          }}
                          as={DropdownList}
                          textField={(v) =>
                            v.notes ? `${v.version}: ${v.notes}` : v.version
                          }
                          data={versions}
                          dataKey="version"
                          placeholder={messages.versionDescription}
                        />
                      </>
                    );
                  }}
                </Form.Field>
              </AccordionFieldArray.Item>
            );
          })
        }
      </AccordionFieldArray>
    </Form.FieldSet>
  );
}

export default ProcessModelsFieldSet;
