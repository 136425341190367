import { InferType, array, boolean, object, string } from 'yup';

export enum ProcessType {
  IMAGE = 'IMAGE',
  STUDY = 'STUDY',
}

export type CaptureMode = {
  value: string;
};

export const PROCESS_TYPES: Array<ProcessType> = Object.values(ProcessType);

export const ModelSchema = object({
  dlModelVersionId: string(),
  project: string().required('A project is required.'),
  version: string().required('A version is required.'),
}).camelCase();

export const ProcessSchema = object({
  dlcProcessId: string(),
  dlcProcessName: string().required('A process name is required.'),
  dlcProcessType: string().oneOf(PROCESS_TYPES).default(PROCESS_TYPES[0]),
  presetIds: array()
    .of(string())
    .required('At least one preset is required.')
    .default([]),
  captureMode: string().required('A capture mode is required.'),
  servable: boolean().required().default(false),
  deployed: boolean().required().default(false),
  dlModels: array()
    .of(ModelSchema)
    .required('At least one model is required.'),
}).camelCase();

export type Model = InferType<typeof ModelSchema>;
export type Process = InferType<typeof ProcessSchema>;

/**
 * Transform process to format expected by backend dlc code.
 *
 * We go from a full Model object array to reduced "id" array.
 *
 * @param value a Process object.
 * @return a suitable wire upload object format.
 */
export function serialize(value: Process): any {
  return ProcessSchema.shape({
    // Tried using dlModels here but that failed.
    // eslint-disable-next-line @typescript-eslint/camelcase
    dl_models: array(string()).transform((v) =>
      v.map((x) => x.dlModelVersionId),
    ),
  })
    .snakeCase()
    .cast(value);
}
