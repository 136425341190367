import { Base64 } from 'js-base64';

function uInt16Array(base64: string): Uint16Array {
  const raw = Base64.toUint8Array(base64);
  return new Uint16Array(raw.buffer);
}

type Height = number;
type Width = number;

type DecodedContourComponent = number[];
type DecodedContourComponents = DecodedContourComponent[];
type DecodedFrames = DecodedContourComponents[];
type DecodedInstances = DecodedFrames[];

type EncodedContourComponents = string[];
type EncodedFrames = EncodedContourComponents[];

type EncodedInstances = EncodedFrames[];
type EncodedContours = [EncodedInstances, Height, Width];

type FlattenedContourData = [DecodedInstances, number, number];

export function decodeContours(
  encodedContourData: EncodedContours,
): FlattenedContourData {
  /**
   * Decode an EncodedContours tuple to a FlattenedContourData tuple.
   *
   * @param: EncodedContours tuple collection of utf-8 base64 strings
   * @returns: FlattenedContourData tuple containing a collection of
   * flattened contour coordinate arrays.
   */
  const [encodedContours, height, width] = encodedContourData;
  const decodedContours: DecodedInstances = [];

  encodedContours.forEach((instance) => {
    const frames: DecodedFrames = [];
    instance.forEach((frame) => {
      const contours: DecodedContourComponents = [];
      frame.forEach((encodedContourComponent) => {
        const decodedContourComponents = Array.from(
          uInt16Array(encodedContourComponent),
        );
        contours.push(decodedContourComponents);
      });
      frames.push(contours);
    });
    decodedContours.push(frames);
  });

  return [decodedContours, height, width];
}
