import React from 'react';
import { FormattedMessage } from 'react-intl';
import BlankSlate from '@bfly/ui/BlankSlate';
import MainContent from '@bfly/ui/MainContent';
import Navbar from '@bfly/ui/Navbar';

import Page from './Page';

function ErrorPage() {
  return (
    <Page>
      <Navbar>
        <Navbar.Brand staticLink />
      </Navbar>
      <Page.Container center>
        <MainContent centerText>
          <BlankSlate>
            <BlankSlate.Title>
              <FormattedMessage
                tagName="span"
                defaultMessage="Not Authorized"
                id="errorPage.title"
              />
            </BlankSlate.Title>
            <BlankSlate.Body>
              <FormattedMessage
                tagName="span"
                defaultMessage="This account isn't authorized to access the requested resource.  If you believe this is in error, please contact an administrator."
                id="errorPage.body"
              />
            </BlankSlate.Body>
          </BlankSlate>
        </MainContent>
      </Page.Container>
    </Page>
  );
}

export default ErrorPage;
