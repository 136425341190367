export default async function executeWithErrorToast(toast, asyncFunction) {
  try {
    return await asyncFunction();
  } catch (e) {
    if (e.rawResponse) {
      const { status, body } = e.rawResponse;
      toast.error(`${status}: ${body}`);
    } else {
      toast.error(e.message);
    }
    throw e;
  }
}
