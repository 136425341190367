/*
 *  This page displays the inference results outputted for the
 *  specified cine.
 */
import ToastContext from '@bfly/ui/ToastContext';
import styled, { css } from 'astroturf';
import React, { useContext, useEffect, useState } from 'react';
import Button from '@bfly/ui/Button';
import Header from '@bfly/ui/Header';
import Layout from '@4c/layout';
import MainContent from '@bfly/ui/MainContent';
import BackArrowLink from '@bfly/ui/BackArrowLink';

import AppPage from '../components/AppPage';
import { useApi } from '../components/AuthProvider';
import CinePlayer from '../components/CinePlayer';
import DataOutputsFieldset from '../components/DataOutputsFieldset';
import LineOutputsOverlay from '../components/LineOutputsOverlay';
import CoordinateOutputsOverlay from '../components/CoordinateOutputsOverlay';
import Page from '../components/Page';
import ProjectLineOverlays from '../components/ProjectLineOverlays';
import QualityIndicator from '../components/QualityIndicator';
import { DlResult, User } from '../models';
import { DlResultOutputs, deserialize } from '../schema/DlResultOutputs';
import { ProjectOverlays } from '../schema/ProjectOverlays';
import * as Assignments from '../utils/Assignments';
import executeWithErrorToast from '../utils/executeWithErrorToast';
import getFileData from '../utils/getFileData';
import { getProjectOverlays } from '../utils/ProjectOverlays';

const Outputs = styled('div')`
  @import '~@bfly/ui/styles/theme';

  top: 6rem;
  width: 30rem;
`;

const styles = css`
  @import '~@bfly/ui/styles/theme';

  .sidePanel {
    padding: 1.6rem;
  }
`;

async function getData({ params, context }) {
  const { dlResultId } = params;
  const { api } = context;

  const [viewer, dlResult] = await Promise.all([
    AppPage.getData({ context }),
    api.getDlResultByDlResultId(dlResultId),
  ]);

  const dlResultFlags = await api.getDlResultFlags(
    dlResultId,
    viewer.username,
  );
  const flagSet = new Set();
  dlResultFlags.forEach((flag) => {
    if (flag.frame_number) {
      flagSet.add(flag.frame_number);
    } else {
      flagSet.add('cine');
    }
  });

  // Check for an image-type dl-result output. We assume dlResult.results.image
  // is an array of presigned AWS URLs.
  const outputImageURLs = dlResult.results.image;
  let outputImages;
  if (outputImageURLs) {
    outputImages = outputImageURLs.map((URL) => {
      const image = new Image();
      image.src = URL;
      return image;
    });
  }

  const imageId = dlResult.image_id;
  const { file, frames } = await getFileData({ api, imageId });

  // Check for biplane dl-results so that we can fetch the transverse file
  // and additionally deserialize the secondary set of inference results.
  const transverseFile =
    dlResult.results.transverse_b_mode &&
    (await api.getFiles(imageId, 'TRANSVERSE_B_MODE'));
  const transverseFileData =
    transverseFile &&
    (await getFileData({
      api,
      imageId: transverseFile[0].image_id,
    }));

  let outputs, transverseOutputs;
  if (transverseFileData) {
    outputs = deserialize(dlResult, 'b_mode');
    transverseOutputs = deserialize(dlResult, 'transverse_b_mode');
  } else {
    outputs = deserialize(dlResult);
  }

  const projectOverlays = await getProjectOverlays(dlResult.project, file);

  return {
    viewer,
    file,
    frames,
    secondaryFile: transverseFileData && transverseFileData.file,
    secondaryFrames: transverseFileData && transverseFileData.frames,
    outputImages,
    dlResult,
    flagSet,
    outputs,
    secondaryOutputs: transverseOutputs,
    projectOverlays,
  };
}

interface Props {
  data: {
    viewer: User;
    file: object;
    secondaryFile?: object;
    outputImages?: Array<string>;
    frames: object;
    secondaryFrames?: object;
    dlResult: DlResult;
    flagSet: Set<'cine' | number>;
    outputs: DlResultOutputs;
    secondaryOutputs?: DlResultOutputs;
    projectOverlays: ProjectOverlays;
  };
}

function InferenceInspectorPage({ data }: Props) {
  const {
    viewer,
    dlResult,
    flagSet,
    outputs,
    secondaryOutputs,
    projectOverlays,
    file,
    frames,
    secondaryFile,
    secondaryFrames,
    outputImages,
  } = data;
  const {
    qualities,
    frameDataOutputs,
    cineDataOutputs,
    lineComponentOutputs,
    coordinateOutputs,
  } = outputs;

  const { projectLineOverlays } = projectOverlays;
  const hasOverlayOutputs =
    !!lineComponentOutputs.length ||
    !!coordinateOutputs.length ||
    !!projectLineOverlays.length;
  const hasSmoothableOutputs =
    coordinateOutputs.filter((o) => !!o.smoothedPoints).length > 0;
  const api = useApi();
  const toast = useContext(ToastContext);
  const { images, secondaryImages } = Assignments.useFrameData({
    frames,
    compFrames: secondaryFrames,
  });
  const [paused, setPaused] = useState(false);
  const [pausedByHover, setPausedByHover] = useState(false);
  const [showingOverlays, setShowingOverlays] = useState(true);
  const [frameNumber, setFrameNumber] = useState(0);
  const [onPancakeMode, setOnPancakeMode] = useState(false);
  const [displayed, setDisplayed] = useState('primary');
  const [displayedFrames, setDisplayedFrames] = useState(images);
  const [useOverlaySmoothing, setUseOverlaySmoothing] = useState(true);

  const handleFlagCine = async () => {
    await executeWithErrorToast(toast, () =>
      api.createDlResultFlag(
        dlResult.dl_result_id,
        viewer.username,
        !flagSet.has('cine'),
      ),
    );
    if (flagSet.has('cine')) {
      flagSet.delete('cine');
      toast!.success('Cine unflagged');
    } else {
      flagSet.add('cine');
      toast!.success('Cine flagged');
    }
  };

  const handleFlagFrame = async () => {
    await executeWithErrorToast(toast, () =>
      api.createDlResultFlag(
        dlResult.dl_result_id,
        viewer.username,
        !flagSet.has(frameNumber),
        frameNumber,
      ),
    );
    if (flagSet.has(frameNumber)) {
      flagSet.delete(frameNumber);
      toast!.success('Frame unflagged');
    } else {
      flagSet.add(frameNumber);
      toast!.success('Frame flagged');
    }
  };

  const handleHover = () => {
    if (!paused) {
      setPausedByHover(true);
      setPaused(true);
    } else {
      setPausedByHover(false);
    }
  };

  const handleHoverOff = () => {
    if (pausedByHover) {
      setPausedByHover(false);
      setPaused(false);
    }
  };

  const onTogglePlayback = (status: boolean) => {
    setPausedByHover(false);
    setPaused(status);
  };

  useEffect(() => {
    if (onPancakeMode && !!outputImages) {
      if (displayed === 'primary') {
        setDisplayedFrames(images);
      } else {
        setDisplayedFrames(outputImages);
      }
    } else {
      setDisplayedFrames(images);
    }

    document.title = `🕵️‍♀ ${dlResult.project} ${dlResult.version} - ${dlResult.dl_result_id} - Butterfly`;
  }, [onPancakeMode, displayed, images, outputImages, dlResult]);

  return (
    <AppPage viewer={viewer} useDrawer>
      <Page.Header backTo={`/file/${dlResult.image_id}`}>
        <Header.Action>
          <BackArrowLink to={`/file/${dlResult.image_id}`} />
        </Header.Action>
        <Header.Title>
          {dlResult.project} {dlResult.version}. Tool: {dlResult.tool_name}{' '}
          Mode: {dlResult.tool_mode}.
        </Header.Title>
      </Page.Header>
      <Layout align="flex-start">
        <MainContent size="large">
          <CinePlayer
            readOnly
            api={api}
            cineWidth={960} // a width below 1000 keeps some side padding.
            cineHeight={720}
            file={file}
            secondaryFile={secondaryFile}
            frames={displayedFrames}
            secondaryFrames={
              onPancakeMode ? undefined : outputImages || secondaryImages
            }
            paused={paused}
            onTogglePlayback={onTogglePlayback}
            renderIndicator={
              !!qualities?.length &&
              (({ frameIndex }) => (
                <QualityIndicator
                  frameQuality={qualities[frameIndex]}
                  onHover={handleHover}
                  onHoverOff={handleHoverOff}
                />
              ))
            }
            renderSecondaryIndicator={
              !!secondaryOutputs?.qualities?.length &&
              (({ frameIndex }) => (
                <QualityIndicator
                  frameQuality={secondaryOutputs.qualities[frameIndex]}
                  onHover={handleHover}
                  onHoverOff={handleHoverOff}
                />
              ))
            }
            renderFrameOverlay={
              showingOverlays &&
              (({ frameIndex }) => (
                <>
                  <LineOutputsOverlay
                    lineComponentOutputs={lineComponentOutputs}
                    frameIndex={frameIndex}
                  />
                  <CoordinateOutputsOverlay
                    coordinateOutputs={coordinateOutputs}
                    frameIndex={frameIndex}
                    smoothing={useOverlaySmoothing}
                  />
                  <ProjectLineOverlays
                    projectLineOverlays={projectLineOverlays}
                  />
                </>
              ))
            }
            renderSecondaryFrameOverlay={
              showingOverlays &&
              secondaryOutputs &&
              (({ frameIndex }) => (
                <>
                  <LineOutputsOverlay
                    lineComponentOutputs={
                      secondaryOutputs.lineComponentOutputs
                    }
                    frameIndex={frameIndex}
                  />
                  <CoordinateOutputsOverlay
                    coordinateOutputs={secondaryOutputs.coordinateOutputs}
                    frameIndex={frameIndex}
                    smoothing={useOverlaySmoothing}
                  />
                </>
              ))
            }
            onFrameUpdate={setFrameNumber}
          />
        </MainContent>
        <Layout pad direction="column" className={styles.sidePanel}>
          <Outputs>
            {!!frameDataOutputs?.length && (
              <DataOutputsFieldset
                legend="Frame Outputs"
                outputs={frameDataOutputs.map((output) => ({
                  key: output.key,
                  label: output.label,
                  value: output.value[frameNumber],
                }))}
              />
            )}
            {!!cineDataOutputs?.length && (
              <DataOutputsFieldset
                legend="Cine Outputs"
                outputs={cineDataOutputs}
              />
            )}
            {!!secondaryOutputs?.frameDataOutputs?.length && (
              <DataOutputsFieldset
                legend="Secondary Frame Outputs"
                outputs={secondaryOutputs.frameDataOutputs.map((output) => ({
                  key: output.key,
                  label: output.label,
                  value: output.value[frameNumber],
                }))}
              />
            )}
            {!!secondaryOutputs?.cineDataOutputs?.length && (
              <DataOutputsFieldset
                legend="Secondary Cine Outputs"
                outputs={secondaryOutputs.cineDataOutputs}
              />
            )}
          </Outputs>
          {!!outputImages && (
            <Button
              size="medium"
              theme="secondary"
              onClick={() => setOnPancakeMode(!onPancakeMode)}
            >
              {onPancakeMode ? 'View side-by-side' : 'View pancake mode'}
            </Button>
          )}
          {onPancakeMode && (
            <Button
              size="medium"
              theme="secondary"
              onClick={() =>
                setDisplayed(displayed === 'primary' ? 'secondary' : 'primary')
              }
            >
              {displayed === 'primary'
                ? 'View right image'
                : 'View left image'}
            </Button>
          )}
          {hasOverlayOutputs && (
            <Button
              size="medium"
              theme={showingOverlays ? 'secondary' : 'primary'}
              onClick={() => setShowingOverlays(!showingOverlays)}
            >
              {showingOverlays ? 'Hide overlays' : 'Show overlays'}
            </Button>
          )}
          {hasOverlayOutputs && showingOverlays && hasSmoothableOutputs && (
            <Button
              size="medium"
              theme="secondary"
              onClick={() => setUseOverlaySmoothing(!useOverlaySmoothing)}
            >
              {useOverlaySmoothing
                ? "Don't Smooth Named Points"
                : 'Smooth Named Points'}
            </Button>
          )}
          <Button
            size="medium"
            theme={flagSet.has('cine') ? 'danger' : 'secondary'}
            onClick={() => handleFlagCine()}
          >
            {flagSet.has('cine') ? 'Unflag cine' : 'Flag cine'}
          </Button>
          {paused && (
            <Button
              size="medium"
              theme={flagSet.has(frameNumber) ? 'danger' : 'secondary'}
              onClick={() => handleFlagFrame()}
            >
              {flagSet.has(frameNumber) ? 'Unflag frame' : 'Flag frame'}
            </Button>
          )}
        </Layout>
      </Layout>
    </AppPage>
  );
}

InferenceInspectorPage.getData = getData;

export default InferenceInspectorPage;
