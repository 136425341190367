import React from 'react';

function FormattedMonth({ value }: { value: Date | string }) {
  const d = value instanceof Date ? value : new Date(value);
  return (
    <span>
      {d.toLocaleDateString('en-US', {
        month: 'long',
      })}{' '}
    </span>
  );
}

export default FormattedMonth;
