import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Layout from '@4c/layout';
import Text from '@bfly/ui/Text';

import { Earning } from '../schema/Earning';

interface Props {
  earning: Earning;
  children?: React.ReactNode;
}

function EarningSummary({ earning, children }: Props) {
  return (
    <Layout direction="column" pad>
      {children || (
        <Text variant="body-bold" color="headline">
          {earning.startDate!.toLocaleDateString('en-US', {
            month: 'long',
          })}
        </Text>
      )}
      <Text>
        <FormattedMessage
          id="earnings.dateRange"
          defaultMessage="From {startDate} to {endDate}"
          values={{
            startDate: <FormattedDate value={earning.startDate} />,
            endDate: <FormattedDate value={earning.endDate!} />,
          }}
        />
      </Text>
    </Layout>
  );
}

export default EarningSummary;
