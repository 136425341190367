import { resolver } from 'found';
import RedirectException from 'found/lib/RedirectException';

function determineRouteProp(routes, propName, defaultValue) {
  return routes.reduce(
    (prev, { [propName]: prop }) => (prop == null ? prev : prop),
    defaultValue,
  );
}

export default (authenticated) => ({
  ...resolver,
  resolveElements(match) {
    if (
      !authenticated &&
      !determineRouteProp(match.routes, 'allowPublic', false)
    ) {
      throw new RedirectException({
        pathname: '/login',
        state: { nextLocation: match.location },
      });
    }

    return resolver.resolveElements.call(this, match);
  },
});
