import { routerShape } from 'found/lib/PropTypes';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import ToastContext from '@bfly/ui/ToastContext';

import { useApi } from 'components/AuthProvider';
import UserProfileForm from 'components/UserProfileForm';
import executeWithErrorToast from 'src/utils/executeWithErrorToast';

const propTypes = {
  data: PropTypes.object.isRequired,

  // The routing mechanism used for links.
  router: routerShape.isRequired,
};

async function getData({ params, context }) {
  const { username } = params;
  const { api } = context;

  const user = await api.getUser(username);

  return { user };
}

function TaskPage({ data, router }) {
  const { user } = data;
  const api = useApi();
  const toast = useContext(ToastContext);

  const submitForm = async (updatedUser) => {
    await executeWithErrorToast(toast, () => api.updateUser(updatedUser));
    router.push(`/-/admin/users/${updatedUser.username}`);
  };

  useEffect(() => {
    document.title = `User Profile - ${user.username} - Butterfly`;
  });

  return <UserProfileForm user={user} submitForm={submitForm} />;
}

TaskPage.propTypes = propTypes;
TaskPage.getData = getData;

export default TaskPage;
