import React from 'react';
import FieldSet from '@bfly/ui/FieldSet';
import Form from '@bfly/ui/Form';

import BooleanInput from './BooleanInput';
import NumberInput from './NumberInput';
import TagInput from './TagInput';

const propTypes = {};

function getType(type) {
  if (type === 'number') return NumberInput;
  if (type === 'tag') return TagInput;
  if (type === 'bool') return BooleanInput;
  return undefined;
}

export function QuestionField({
  name,
  readOnly,
  question: { questionId, type, ...config },
}) {
  return (
    <Form.FieldGroup
      name={name}
      theme="light"
      key={questionId}
      readOnly={readOnly}
      as={getType(type)}
      label={config.displayText}
      configuration={config}
    />
  );
}

interface Props {
  questions: any[];
  readOnly?: boolean;
  className?: string;
  legendSrOnly?: boolean;
}
function QuestionsFieldset({
  questions,
  readOnly,
  className,
  legendSrOnly,
}: Props) {
  return (
    <FieldSet
      legend="Questions"
      className={className}
      legendSrOnly={legendSrOnly}
    >
      <Form.Field name="answers">
        {({ value }) =>
          questions.map((question) => {
            const { questionId, dependsOn } = question;
            if (
              dependsOn &&
              (value == null ||
                !dependsOn.values.includes(value[dependsOn.questionId]))
            ) {
              return null;
            }

            const name = `answers.${questionId}`;

            return (
              <QuestionField
                key={questionId}
                name={name}
                readOnly={readOnly}
                question={question}
              />
            );
          })
        }
      </Form.Field>
    </FieldSet>
  );
}

QuestionsFieldset.propTypes = propTypes;

export default QuestionsFieldset;
