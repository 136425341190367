export function movingPointAverageWithAlpha(
  data: number[][],
  window: number,
): number[][] {
  /**
   * Calculate smoothed point location and opacity values from an array of [x,y] coords
   *
   * @param data - array of [x, y] coordinates with the value [-1, -1] meaning "null"
   * @param window - integer number of frames across which to average
   * @returns array of [x, y, alpha] values which are the smoothed points
   */
  const result: number[][] = [];

  let sumX = 0;
  let sumY = 0;
  let count = 0;

  for (let i = 0; i < data.length; ++i) {
    if (data[i][0] > 0) {
      sumX += data[i][0];
      sumY += data[i][1];
      count += 1;
    }

    if (i >= window) {
      if (data[i - window][0] > 0) {
        sumX -= data[i - window][0];
        sumY -= data[i - window][1];
        count -= 1;
      }
    }

    if (count > 0) {
      result.push([sumX / count, sumY / count, count / window]);
    } else {
      result.push([-1, -1, 0]);
    }
  }

  return result;
}
