import React from 'react';
import { Line } from 'react-konva';

import {
  LineComponentOutputs,
  OPACITY,
  OUTPUT_TYPES,
} from '../schema/DlResultOutputs';

interface Props {
  lineComponentOutputs: LineComponentOutputs[];
  frameIndex: number;
}

function LineOutputsOverlay({ lineComponentOutputs, frameIndex }: Props) {
  return (
    <>
      {lineComponentOutputs.flatMap((output) => {
        const framePoints = output.points[frameIndex];
        return framePoints.map((endpoints, index) => (
          <Line
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${output.key}_${index}`}
            points={endpoints}
            closed={output.type === OUTPUT_TYPES.SEGMENTATIONS}
            fill={output.color}
            tension={output.type === OUTPUT_TYPES.SEGMENTATIONS ? 1 : 0}
            stroke={output.color}
            opacity={OPACITY}
          />
        ));
      })}
    </>
  );
}

export default LineOutputsOverlay;
