import React, { useContext, useEffect } from 'react';
import Header from '@bfly/ui/Header';
import Heading from '@bfly/ui/Heading';
import MainContent from '@bfly/ui/MainContent';

import AppPage from '../components/AppPage';
import Page from '../components/Page';
import ProcessDefinitionForm from '../components/ProcessDefinitionForm';
import { DlcImageProcessName, DlcModel, Preset, User } from '../models';
import { Router } from 'found';
import {
  CaptureMode,
  Model,
  ModelSchema,
  Process,
  ProcessSchema,
  serialize,
} from '../schema/Process';
import { useApi } from '../components/AuthProvider';
import ToastContext from '@bfly/ui/ToastContext';
import executeWithErrorToast from '../utils/executeWithErrorToast';
import { groupBy, toPairs } from 'lodash';

function modelsToMap(dlcModels: DlcModel[]): Map<string, Model[]> {
  const models = dlcModels.map((m) => ModelSchema.cast(m));
  return new Map(toPairs(groupBy(models, 'project')));
}

async function getData({ params, context }) {
  const { processId } = params;
  const { api } = context;
  const [
    viewer,
    captureModes,
    processNames,
    presets,
    models,
    dlcProcess,
    dlcProcessModels,
  ] = await Promise.all([
    AppPage.getData({ context }),
    api.getCaptureModes(),
    api.getDlcImagedProcessName(),
    api.getDlcPresets(),
    api.getDlcModels(),
    processId ? api.getDlcProcess(processId) : null,
    processId ? api.getDlcModelsForProcessId(processId) : null,
  ]);
  let process: Process | null = null;
  if (dlcProcess) {
    process = ProcessSchema.cast(dlcProcess);
    if (dlcProcessModels) {
      process.dlModels = dlcProcessModels.map((v) => ModelSchema.cast(v));
    }
  }
  const projectModelMap: Map<string, Model[]> = modelsToMap(models);

  return {
    viewer,
    captureModes,
    processNames,
    presets,
    projectModelMap,
    process,
  };
}

interface Props {
  data: {
    viewer: User;
    captureModes: CaptureMode[];
    processNames: DlcImageProcessName[];
    presets: Preset[];
    projectModelMap: Map<string, Model[]>;
    process?: Process;
  };
  router: Router;
}

function InferenceProcessPage(args: Props) {
  const api = useApi();
  const toast = useContext(ToastContext);
  const { data, router } = args;

  const handleSubmit = async (process: Process) => {
    await executeWithErrorToast(toast, () =>
      !process.dlcProcessId
        ? api.createDlcProcess(serialize(process))
        : api.updateDlcProcess(process.dlcProcessId, serialize(process)),
    );
    router.push('/-/admin/dlc/inference-processes');
  };

  useEffect(() => {
    document.title = `DLC Process Definition - Butterfly`;
  });

  return (
    <AppPage viewer={data.viewer}>
      <Page.Header backTo="/-/admin/dlc/inference-processes">
        <Header.Title>DLC Process Definition</Header.Title>
      </Page.Header>
      <MainContent size="medium">
        <Heading>
          {data.process && `Process ID: ${data.process.dlcProcessId}`}
        </Heading>
        <ProcessDefinitionForm
          onSave={handleSubmit}
          isDlcOwner={api.viewerIsDlcOwner}
          process={data.process}
          presets={data.presets}
          captureModes={data.captureModes}
          processNames={data.processNames}
          projectModelMap={data.projectModelMap}
        />
      </MainContent>
    </AppPage>
  );
}

InferenceProcessPage.getData = getData;

export default InferenceProcessPage;
