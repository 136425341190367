import React from 'react';
import { FormattedMessage } from 'react-intl';
import BlankSlate from '@bfly/ui/BlankSlate';
import MainContent from '@bfly/ui/MainContent';
import Navbar from '@bfly/ui/Navbar';

import Page from './Page';

function ErrorPage() {
  return (
    <Page>
      <Navbar>
        <Navbar.Brand staticLink />
      </Navbar>
      <Page.Container center>
        <MainContent centerText>
          <BlankSlate>
            <BlankSlate.Title>
              <FormattedMessage
                tagName="span"
                defaultMessage="Looks like something went wrong."
                id="errorPage.title"
              />
            </BlankSlate.Title>
            <BlankSlate.Body>
              <FormattedMessage
                tagName="span"
                defaultMessage="To retry, refresh the page."
                id="errorPage.body"
              />
            </BlankSlate.Body>
            <BlankSlate.Action href="mailto:annotator-support@butterflynetwork.com">
              <FormattedMessage
                tagName="span"
                defaultMessage="Contact Support"
                id="errorPage.support"
              />
            </BlankSlate.Action>
          </BlankSlate>
        </MainContent>
      </Page.Container>
    </Page>
  );
}

export default ErrorPage;
