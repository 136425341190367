import React from 'react';
import Form from '@bfly/ui/Form';

import AnnotatableGraph from './AnnotatableGraph';

function TracesGraphField(props) {
  return (
    <Form.Field name="traces">
      {({ value, onChange }) => (
        <AnnotatableGraph
          {...props}
          results={value}
          onTrace={onChange}
          activeTrace={props.activeTrace}
        />
      )}
    </Form.Field>
  );
}

export default TracesGraphField;
