/*
 * AnnotatableCine is a CinePlayer helper component that takes in task-related specifics (ie. intervals,
 * traces, DL result outputs etc.) and handles proper cine and frame scrubber functionality and display.
 * Use this component for cine visualization with tasks.
 */
import Layout from '@4c/layout';
import { File } from '../models';
import { useShortcut } from './KeyboardShortcutManager';
import React, { useState } from 'react';
import Api from '../Api';
import { DlResultOutputs } from '../schema/DlResultOutputs';
import Calculations from './Calculations';
import CinePlayer from './CinePlayer';
import LineOutputsOverlay from './LineOutputsOverlay';
import CoordinateOutputsOverlay from './CoordinateOutputsOverlay';
import QualityIndicator from './QualityIndicator';
import FrameScrubber from './FrameScrubber';
import { TaskTypes } from '../schema/AnnotationTask';

interface Props {
  /** An instance of a file. See models.File. */
  file: File;

  /** The list of frames in the cine. */
  frames: [HTMLImageElement];

  /** An instance of a file being compared */
  compFile?: File;

  /** The list of frames in the comparison cine */
  compFrames?: [HTMLImageElement];

  /** An instance of the frontend API. */
  api: typeof Api;

  /** The definition field of a Task */
  task: TaskTypes.frame | TaskTypes.cine | TaskTypes.pixel;

  /** An array of frame indices enabled for annotation. All frames are enabled if unspecified */
  enabledFrames?: number[];

  /** The selected frame interval on the frame scrubber component */
  selectedInterval?: any;

  /** Function called when an frame interval is selected on the frame scrubber component */
  onSelectInterval?: any;

  /** A DrawingCanvas overlay that displays existing task traces or allows new traces to be drawn */
  traceOverlay?: React.ReactNode;

  /** Interval values associated with a task */
  intervalAnnotations?: object;

  /** Trace values associated with a task */
  traceAnnotations?: object;

  /** Model inference result outputs associated with a task */
  dlResultOutputs?: DlResultOutputs;

  /** Current playback state of the cine */
  paused?: boolean;

  /** Initial playback state of the cine */
  defaultPaused?: boolean;

  /** Current frameIndex of the frame displayed in the cine */
  frameIndex?: number;

  /** Function that sets the paused state */
  onTogglePlayback?: (boolean) => void;

  /** Function that sets the current frameIndex */
  onSelectFrame?: (number) => void;

  /** A DrawingCanvas cursor style */
  cursor?: string | null | undefined;

  /** Annotation for a task is enabled or is view only */
  readOnly?: boolean;
}

function AnnotatableCine(props: Props) {
  const {
    task,
    intervalAnnotations,
    traceAnnotations,
    traceOverlay,
    selectedInterval,
    onSelectInterval,
    dlResultOutputs,
    readOnly = true,
    enabledFrames,
    file,
    frames,
    compFile,
    compFrames,
    ...cinePlayerProps
  } = props;

  const hasDlResultOverlays =
    dlResultOutputs?.lineComponentOutputs.length ||
    dlResultOutputs?.coordinateOutputs.length;

  const [showDlResultOverlays, setShowDlResultOverlays] = useState(true);

  useShortcut({
    h: () => {
      if (hasDlResultOverlays) {
        setShowDlResultOverlays(!showDlResultOverlays);
      }
    },
  });

  return (
    <CinePlayer
      file={file}
      frames={frames}
      secondaryFile={compFile}
      secondaryFrames={compFrames}
      {...cinePlayerProps}
      renderIndicator={({ frameIndex }) => (
        <Layout pad align="flex-end" direction="column">
          {task.calculations && traceAnnotations && (
            <Calculations
              file={props.file}
              traceAnnotations={traceAnnotations}
              calculations={task.calculations}
            />
          )}

          {!!dlResultOutputs?.qualities?.length && (
            <QualityIndicator
              frameQuality={dlResultOutputs.qualities[frameIndex]}
            />
          )}
        </Layout>
      )}
      renderFrameScrubber={({
        frameIndex,
        onSelectIndex,
        onTogglePlayback,
      }) => (
        <FrameScrubber
          frameCount={Math.max(
            props.frames.length,
            props.compFrames?.length ?? 0,
          )}
          activeIndex={frameIndex}
          onSelectIndex={onSelectIndex}
          onTogglePlayback={onTogglePlayback}
          tracesDefinition={task.traces || []}
          intervalsDefinition={task.intervals}
          readOnly={readOnly}
          enabledFrames={enabledFrames}
          intervalAnnotations={intervalAnnotations}
          traceAnnotations={traceAnnotations}
          selectedInterval={selectedInterval}
          onSelectInterval={onSelectInterval}
        />
      )}
      renderFrameOverlay={({ frameIndex }) => (
        <>
          {traceOverlay}
          {dlResultOutputs && showDlResultOverlays && (
            <>
              <LineOutputsOverlay
                lineComponentOutputs={dlResultOutputs.lineComponentOutputs}
                frameIndex={frameIndex}
              />
              <CoordinateOutputsOverlay
                coordinateOutputs={dlResultOutputs.coordinateOutputs}
                frameIndex={frameIndex}
              />
            </>
          )}
        </>
      )}
    />
  );
}

export default AnnotatableCine;
