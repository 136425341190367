import React, { useMemo } from 'react';
import { bool, object, string } from 'yup';
import Layout from '@4c/layout';
import DropdownList from '@bfly/ui/DropdownList';
import Form from '@bfly/ui/Form';

const userSchema = object({
  username: string().trim().lowercase().required().max(15),
  name: string().required(),
  phone: string().nullable(),
  enabled: bool().default(true),
  admin: bool().default(false),
  email: string().email().required(),
});

const newUserSchema = userSchema.shape({
  password: string()
    .required()
    .min(8)
    .matches(/[A-Z]/, 'password must contain an upper case ')
    .matches(/\d/, 'password must contain a number'),
});

const propTypes = {};

function UserProfileForm({ user, initialEmail, submitForm }) {
  const schema = user ? userSchema : newUserSchema;

  const defaultValue = useMemo(
    () => (user ? schema.cast(user) : schema.default()),
    [schema, user],
  );

  if (initialEmail && !user) {
    defaultValue.email = initialEmail;
  }

  return (
    <Form
      schema={schema as any}
      defaultValue={defaultValue}
      submitForm={submitForm}
    >
      <Form.HorizontalFieldGroup
        name="username"
        label="Username"
        autoFocus
        disabled={!!user}
      />
      {!user && (
        <Form.HorizontalFieldGroup
          name="password"
          type="password"
          label="Password"
          autoComplete="new-password"
        />
      )}
      <Form.HorizontalFieldGroup name="name" label="Name" />
      <Form.HorizontalFieldGroup
        name="email"
        label="Email"
        type="email"
        disabled={
          // Only disable for butterfly users since it would break AzureAD Link
          !!user && user.email.toLowerCase().endsWith('@butterflynetinc.com')
        }
      />

      <Form.HorizontalFieldGroup name="phone" label="Phone" type="tel" />

      <Form.HorizontalFieldGroup
        name="admin"
        label="Role"
        variant="light"
        as={DropdownList}
        mapFromValue={(v) => v && v.value}
        dataKey="value"
        textField="text"
        data={[
          { value: true, text: 'Admin' },
          { value: false, text: 'Annotator' },
        ]}
      />
      <Form.HorizontalFieldGroup
        name="enabled"
        label="Enabled"
        style={{ alignSelf: 'center' }}
      />

      <Layout justify="flex-end">
        <Form.Submit>{user ? 'Save Changes' : 'Create User'}</Form.Submit>
      </Layout>
    </Form>
  );
}

UserProfileForm.propTypes = propTypes;

export default UserProfileForm;
