import React from 'react';
import FieldSet from '@bfly/ui/FieldSet';

import { QuestionField } from './QuestionsFieldset';

const propTypes = {};

function SkipFieldset({ skip, readOnly }) {
  return (
    <FieldSet legend="Skip">
      <p>This assignment was skipped</p>
      {skip?.question && (
        <QuestionField
          name="skipAnswer"
          readOnly={readOnly}
          question={skip.question}
        />
      )}
    </FieldSet>
  );
}

SkipFieldset.propTypes = propTypes;

export default SkipFieldset;
