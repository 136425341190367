import React from 'react';
import Layout from '@4c/layout';
import FieldSet from '@bfly/ui/FieldSet';
import Form from '@bfly/ui/Form';

import Shortcut from './Shortcut';
import TagButton from './TagButton';

const propTypes = {};

function TracesFieldset({
  legend = 'Traces',
  traces,
  onSelectTrace,
  activeTraceId,
  readOnly,
}) {
  const allRequired = traces.every((trace) => trace.required);

  return (
    <Form.Field name="activeTrace">
      {({ value, onChange }) => (
        <FieldSet legend={legend}>
          <Layout pad direction="column" justify="center">
            {traces.map(
              ({ traceId, displayText, color, shortcut, required }) => {
                const checked = traceId === value;

                const handleChange = () => {
                  if (!readOnly) {
                    onChange(checked ? null : traceId);
                    if (onSelectTrace) {
                      onSelectTrace(traceId);
                    }
                  }
                };

                return (
                  <TagButton
                    key={traceId}
                    active={!!checked || activeTraceId === traceId}
                    disabled={readOnly}
                    onClick={handleChange}
                  >
                    {shortcut && (
                      <Shortcut
                        char={shortcut}
                        color={color}
                        handler={handleChange}
                      />
                    )}{' '}
                    {displayText}
                    {required && !allRequired && '*'}
                  </TagButton>
                );
              },
            )}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <Form.Message for="traces" />
          </Layout>
        </FieldSet>
      )}
    </Form.Field>
  );
}

TracesFieldset.propTypes = propTypes;

export default TracesFieldset;
