import { Model } from '../schema/Process';

const VERSION_REGEX = /v(\d+)\.(\d+)/;

export const compareVersions = (
  versionA: string,
  versionB: string,
): number => {
  /**
   * Compares model_version strings (e.g. 'v0.10').
   *
   * @param: versionA - the version of first model being compared
   * @param: versionB - the version of second model being compared
   * @returns: Returns -1 if versionA is less than versionB, 1 if versionA
   * is greater than versionB, and 0 if versionA is equal to versionB.
   */
  const aMatch = versionA.match(VERSION_REGEX);
  const bMatch = versionB.match(VERSION_REGEX);

  if (aMatch && bMatch) {
    const aMajor = parseInt(aMatch[1], 10);
    const aMinor = parseInt(aMatch[2], 10);

    const bMajor = parseInt(bMatch[1], 10);
    const bMinor = parseInt(bMatch[2], 10);

    if (aMajor < bMajor) {
      return -1;
    }
    if (aMajor > bMajor) {
      return 1;
    }

    if (aMinor < bMinor) {
      return -1;
    }
    if (aMinor > bMinor) {
      return 1;
    }

    return 0;
  }
  return 0;
};

export const compareModelsByVersion = (modelA: Model, modelB: Model): number =>
  /**
   * Compares two models by their version strings (e.g. 'v0.10').
   *
   * @param: modelA - the first model being compared
   * @param: modelB - the second model being compared
   * @returns: Returns -1 if modelA's version is less than modelB's, 1 if modelA's
   * version is greater than modelB's, and 0 if modelA's version is equal to
   * modelB's.
   */
  compareVersions(modelA.version, modelB.version);
