/* eslint-disable no-await-in-loop */
import chunk from 'lodash/chunk';

import Cache from './Cache';

function toAsyncIterable(arr, length = arr.length) {
  const iter = (async function* iterable() {
    for (const frameChunk of arr) yield await frameChunk;
  })();

  iter.length = length;
  return iter;
}

const getCacheKey = (imageId, startFrame, endFrame) =>
  `${imageId}-${startFrame}-${endFrame}`;

export default class CineFetcher {
  constructor(api) {
    this.api = api;
    this.cache = new Cache(2);
  }

  async fetch(imageId, startFrame, endFrame) {
    const cacheKey = getCacheKey(imageId, startFrame, endFrame);
    if (this.cache.has(cacheKey)) {
      return this.getImagesFromCache(cacheKey);
    }

    const extractedFrames = await this.api.getExtractedFrames(
      imageId,
      startFrame,
      endFrame,
    );

    if (extractedFrames.length === 0) {
      // Disables the file.
      this.api.saveCorruptFile(imageId, 'ExtractedFrames not found.');
      return toAsyncIterable([]);
    }

    const iter = this.fetchImages(extractedFrames, cacheKey);
    iter.length = extractedFrames.length;
    return iter;
  }

  async *fetchImages(extractedFrames, cacheKey) {
    const chunks = chunk(extractedFrames, 30);
    const cached = [];

    for (const frameChunk of chunks) {
      const frames = await Promise.all(
        frameChunk.map((f) => this.api.getDataImage(f.filename)),
      );
      cached.push(frames);
      yield frames;
    }

    this.cache.set(cacheKey, {
      chunks: cached,
      length: extractedFrames.length,
    });
  }

  getImagesFromCache(cacheKey) {
    const { chunks, length } = this.cache.get(cacheKey);
    return toAsyncIterable(chunks, length);
  }
}
