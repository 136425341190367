/* eslint-disable @typescript-eslint/camelcase */
import HttpError from 'found/lib/HttpError';
import jwtDecode from 'jwt-decode';
import captureException from '@bfly/ui/utils/captureException';
import * as Sentry from '@sentry/browser';

import { Task } from './models';
import Cache from './utils/Cache';
import * as Errors from './utils/Errors';
import * as qs from './utils/querystring';

import { saveAs } from 'file-saver';

const DEFAULT_FRAME_INTERVAL = 1000 / 30;

const USERNAME_CLAIM = 'https://label.bfly.io/username';
const ROLES_CLAIM = 'https://label.bfly.io/roles';

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface RequestOptions {
  fullData?: boolean;
  noAuth?: boolean;
}

export default class Api {
  private tasks: Cache<string, any>;

  private nextErrorId: number;

  // Initialized using resolveViewerIsAdmin()
  private _viewerIsAdmin = false;

  readonly username: string;

  readonly viewerIsInternal: boolean;

  readonly viewerIsDlcOwner: boolean;

  readonly authorization: string;

  constructor(token) {
    // No need to refetch tasks.
    this.tasks = new Cache(200);

    const payload = (token && jwtDecode(token)) || {};
    const roles = payload[ROLES_CLAIM] || [];

    this.username = payload[USERNAME_CLAIM] || null;
    this.viewerIsInternal = roles.includes('internal');
    this.viewerIsDlcOwner = roles.includes('dlc_owner');

    this.authorization = token && `Bearer ${token}`;
    this.nextErrorId = 0;

    Sentry.configureScope((scope) => {
      scope.setUser({ username: this.username });
    });
  }

  async resolveViewerIsAdmin(): Promise<Api> {
    try {
      this._viewerIsAdmin = (await this.getUser(this.username)).admin;
    } catch {
      this._viewerIsAdmin = false;
    }

    return this;
  }

  public get viewerIsAdmin(): boolean {
    return this._viewerIsAdmin;
  }

  getContracts(username: string): Promise<any> {
    const query = qs.stringify({ username });
    return this.request('GET', `contracts?${query}`);
  }

  getContract(contractId: string): Promise<any> {
    return this.request('GET', `contracts/${contractId}`);
  }

  getContractsByTask({ taskName }): Promise<any> {
    const query = qs.stringify({ task: taskName });
    return this.request('GET', `contracts?${query}`);
  }

  getUsersForWorklist({ taskName, grade }): Promise<any> {
    const query = qs.stringify({ task: taskName, grade });
    return this.request('GET', `contracts?${query}`);
  }

  /**
   * Creates a new contract. Note that this operation is NOT idempotent.
   *
   * @param contract - The contract to create.
   * @returns {Promise<*>}
   */
  createContract(data): Promise<any> {
    return this.request('POST', `contracts`, data);
  }

  /**
   * Saves the existing contract. Note that this operation is idempotent.
   *
   * @param contractId
   * @param data - An existing contract to update.
   * @returns {Promise<*>}
   */
  updateContract(contractId: string, data): Promise<any> {
    return this.request('PATCH', `contracts/${contractId}`, data);
  }

  getCustomEarnings({
    startDate,
    endDate,
    username,
  }: Record<string, string> = {}) {
    const query = qs.stringify({ username, startDate, endDate });
    return this.request('GET', `custom-earnings/details?${query}`);
  }

  /**
   * Given a custom earning id, delete the record.
   *
   * @param id - The custom earnings id.
   * @returns {Promise<*>}
   */
  deleteCustomEarnings(id: string): Promise<any> {
    return this.request('DELETE', `earnings-management/${id}`);
  }

  /**
   * Creates a new custom earnings.
   *
   * @param earnings - The earnings to create.
   * @returns {Promise<*>}
   */
  createCustomEarnings(earnings): Promise<any> {
    return this.request('POST', `earnings-management`, { ...earnings });
  }

  async _getTasks(taskType?: 'regular' | 'review') {
    const query = qs.stringify({ taskType });
    const tasks = await this.request('GET', `tasks?${query}`);

    tasks.forEach((task) => this.tasks.set(task.name, task));

    return tasks;
  }

  /**
   * Returns the list of regular tasks.
   *
   * @returns {Promise<void>} - A list of tasks.
   */
  getTasks(): Promise<void> {
    return this._getTasks('regular');
  }

  /**
   * Returns the list of all tasks.
   *
   * @returns {Promise<void>} - A list of tasks.
   */
  getAllTasks(): Promise<void> {
    return this._getTasks();
  }

  /**
   * Deletes a given task
   *
   * @param name - The task name.
   * @returns {Promise<*>}
   */
  deleteTask(name: string): Promise<any> {
    return this.request('DELETE', `tasks/${name}`);
  }

  /**
   * Returns the list of presets.
   *
   *
   * @returns {Promise<void>} - A list of presets.
   */
  getPresets(): Promise<void | null> {
    return this.request('GET', `presets`);
  }

  /**
   * Returns the list of review tasks.
   *
   * @returns {Promise<void>} - A list of tasks.
   */
  getReviewTasks(): Promise<void> {
    return this._getTasks('review');
  }

  async getLatestTask(taskId: string) {
    if (!this.tasks.has(taskId)) {
      this.tasks.set(
        taskId,
        await this.request('GET', `tasks/${taskId}?include_deleted=true`),
      );
    }

    return this.tasks.get(taskId);
  }

  /**
   * Creates the given task.
   *
   * @param task: A dictionary comprising of `name` and `definition` fields.
   *
   * @returns {Promise<*>} The updated task.
   */
  async createTaskVersion(task: Task): Promise<any> {
    const updated = await this.request(
      'POST',
      `tasks/${task.name}/versions`,
      task,
    );
    this.tasks.set(updated.name, {
      ...updated.task,
      latest_version: updated,
    });
    return updated;
  }

  getTaskReferenceImageUrl(id: string) {
    return this.getUrl(`task_reference_images/${id}/file`);
  }

  async createTaskReferenceImage({ name, latest_version: { version } }, data) {
    const referenceImage = await this.request(
      'POST',
      'task_reference_images',
      {
        task_name: name,
        task_version: version,
        data,
      },
    );
    this.tasks.get(name).latest_version.reference_images.push(referenceImage);

    return referenceImage;
  }

  async updateTaskReferenceImage(id: string, data) {
    const referenceImage = await this.request(
      'PATCH',
      `task_reference_images/${id}`,
      data,
    );

    const parent = this.tasks.get(referenceImage.task_name);
    const existing = parent.latest_version.reference_images.find(
      (r) => r.id === referenceImage.id,
    );
    if (existing) {
      Object.assign(existing, referenceImage);
    }

    return referenceImage;
  }

  async deleteTaskReferenceImage(referenceImage: {
    task_name: string;
    id: string;
  }) {
    await this.request('DELETE', `task_reference_images/${referenceImage.id}`);

    const parent = this.tasks.get(referenceImage.task_name);
    const idx = parent.latest_version.reference_images.findIndex(
      (r) => r.id === referenceImage.id,
    );

    if (idx !== -1) {
      parent.latest_version.reference_images.splice(idx, 1);
    }

    return referenceImage;
  }

  getViewer(): '' | Promise<any> {
    return this.username && this.request('GET', `users/${this.username}`);
  }

  getUsers(): Promise<any> {
    return this.request('GET', `users`);
  }

  getUser(username: string): Promise<any> {
    return this.request('GET', `users/${username}`);
  }

  /**
   * Creates a new user. Note that this operation is NOT idempotent.
   *
   * @param user - The user to create.
   * @returns {Promise<*>}
   */
  createUser(user): Promise<any> {
    return this.request('POST', `users/${user.username}`, { ...user });
  }

  /**
   * Saves the existing user. Note that this operation is idempotent.
   *
   * @param user - An existing user whose attributes we want to save.
   * @returns {Promise<*>}
   */
  updateUser(user): Promise<any> {
    return this.request('PATCH', `users/${user.username}`, { ...user });
  }

  /**
   * Returns the user-task pending and completed counts per worklist.
   *
   * @param username The user whose stats we want.
   * @param completed Filter by completion
   * @param sort Sort results
   * @returns {Promise<*>}
   */
  getWorklistsForUser({ username, completed, sort }): Promise<any> {
    const query = qs.stringify({ username, completed, sort });
    return this.request('GET', `worklists?${query}`);
  }

  getWorklistsForTask({ taskName, completed, sort }): Promise<any> {
    const query = qs.stringify({ task_name: taskName, completed, sort });
    return this.request('GET', `worklists?${query}`);
  }

  getWorklist(worklistId: string): Promise<any> {
    return this.request('GET', `worklists/${worklistId}`);
  }

  downloadWorklistResults(
    worklistId = '',
    taskName = '',
    includeFileMetadata = false,
  ): Promise<any> {
    const params = qs.stringify({
      worklist_id: worklistId,
      task_name: taskName,
      include_file_metadata: includeFileMetadata,
    });
    return this.openToApiResult(`worklists/download-results?${params}`);
  }

  clearWorklist(worklistId: string): Promise<any> {
    return this.request('PATCH', `worklists/${worklistId}/clear`);
  }

  deleteWorklist(worklistId: string): Promise<any> {
    return this.request('DELETE', `worklists/${worklistId}`);
  }

  createUploadedWorklist(username, data): Promise<any> {
    const newStructure = { username, assignments: [...data] };
    return this.request('POST', `worklists/-/upload/`, newStructure);
  }

  /**
   * Duplicate the worklist for the user recieved.
   *
   * @param worklistId - An existing worklist id
   * @param username - An existing username
   * @returns {Promise<*>}
   */
  duplicateWorklist({ worklistId, username }): Promise<any> {
    return this.request('POST', `worklists/${worklistId}/duplicate`, {
      username,
    });
  }

  createAcceptRejectAssignments(id: string, body): Promise<any> {
    return this.request(
      'POST',
      `accept_reject_worklist/${this.username}/${id}`,
      body,
    );
  }

  /**
   * Returns a particular assignment
   *
   * @param assignmentId - The unique ID of the assignment.
   * @returns {Promise<*>} - An assignment instance.
   */
  getAssignment(assignmentId: string): Promise<any> {
    const queryString = qs.stringify({ assignmentId });
    return this.request('GET', `assignments?${queryString}`);
  }

  getNextAssignments({ worklistId, username, limit = 1 }): Promise<any> {
    const queryString = qs.stringify({
      username,
      limit,
      worklistId,
      completed: false,
    });

    return this.request('GET', `assignments?${queryString}`);
  }

  async getDataImage(path: string) {
    const fullPath = `data/${path}`;
    const response_bfly = await this.requestRaw('GET', fullPath, undefined);

    const json_data = await response_bfly?.json();
    const response = await fetch(json_data.download_url, { method: 'GET' });

    try {
      const blob = await response!.blob();

      return new Promise((resolve) => {
        const image = new Image();
        image.src = URL.createObjectURL(blob);
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', () => resolve(image));
      });
    } catch (e) {
      captureException(e);
      throw new HttpError(500);
    }
  }

  getAnalytics(analyticsName: string) {
    const path = `analytics/${analyticsName}`;
    return this.request('GET', path, undefined, {
      fullData: true,
      noAuth: true,
    });
  }

  /**
   * Returns the label uniquely identified by the given assignment ID.
   *
   * @param assignmentId - A UUID of an assignment ID.
   * @returns {Promise<*>}
   */
  getLabel(assignmentId: string): Promise<any> {
    return this.request('GET', `labels/${assignmentId}`);
  }

  /**
   * Returns labels by user and by task or worklist ID
   * @param {string} [taskId] - Task name
   * @param {string} [worklistId] - A worklist UUID.
   * @param {number} [limit] - max amount of items to return
   * @param {string} username
   * @returns {Promise<*>}
   */
  getLabels({
    taskId,
    worklistId,
    username,
    limit = 50,
    cursor = null,
  }): Promise<any> {
    const query = qs.stringify({
      username,
      limit,
      worklistId,
      task: taskId,
      cursor,
    });
    return this.request('GET', `labels?${query}`, undefined, {
      fullData: true,
    });
  }

  /**
   * Returns all labels for the given file.
   *
   * @param imageId: A UUID for an arbitrary file.
   * @returns {Promise<*>}
   */
  getLabelsByImageId(imageId: string): Promise<any> {
    const query = qs.stringify({ imageId });
    return this.request('GET', `labels?${query}`);
  }

  getAdminReviewLabels(
    task: string,
    imageId: string,
    enabled?: boolean,
  ): Promise<any> {
    const query = qs.stringify({ task, enabled, imageId });
    return this.request('GET', `-/admin/labels?${query}`);
  }

  /**
   * Saves the label for the given assignment.
   *
   * @param assignmentId - A UUID representing the assignment being labeled.
   * @param data - The data request.
   * @returns {Promise<*>}
   */
  saveLabel(assignmentId: string, data): Promise<any> {
    return this.request('PUT', `labels/${assignmentId}`, { ...data });
  }

  enableLabel(assignmentId: string, data): Promise<any> {
    return this.request('PUT', `labels/${assignmentId}/enable`, data);
  }

  /**
   * Returns all DL Results by imageId, project, modelVersionId or organization ID
   * @param project - The name of a project.
   * @param dlModelVersionId - A UUID for a model version.
   * @param organizationId - A UUID for an organization
   * @param search - A user inputted query term.
   * @returns {Promise<*>}
   */
  getDlResults({
    project,
    dlModelVersionId,
    organizationId,
    search,
    limit = 25,
    cursor,
  }: Record<string, string | number | null> = {}): Promise<any> {
    const query = qs.stringify({
      project,
      dlModelVersionId,
      organizationId,
      search,
      limit,
      cursor,
    });

    return this.request('GET', `dl-results?${query}`, undefined, {
      fullData: true,
    });
  }

  /**
   * Returns all DL Results for the given file.
   *
   * @param imageId: A UUID for an arbitrary file.
   * @returns {Promise<*>}
   */
  getDlResultsByImageId(imageId: string): Promise<any> {
    const query = qs.stringify({ imageId });
    return this.request('GET', `dl-results?${query}`);
  }

  /**
   * Returns the DL Result for the given DL Result ID.
   *
   * @param dlResultId: A UUID for a DL Result.
   * @returns {Promise<*>}
   */
  getDlResultByDlResultId(dlResultId: string): Promise<any> {
    return this.request('GET', `dl-results/${dlResultId}`);
  }

  /**
   * Returns all active DL Result Flags for the given DL Result ID and user.
   *
   * @param dlResultId: A UUID for a DL Result.
   * @param username: A username.
   * @returns {Promise<*>}
   */
  getDlResultFlags(dlResultId: string, username: string): Promise<any> {
    const query = qs.stringify({
      dlResultId,
      username,
    });
    return this.request('GET', `dl-result-flags?${query}`);
  }

  /**
   * Creates an (in)active DL Result Flag for the given DL Result ID, user,
   * and frame number.
   *
   * @param dlResultId: A UUID for a DL Result.
   * @param username: A username.
   * @returns {Promise<*>}
   */
  createDlResultFlag(
    dlResultId: string,
    username: string,
    isFlagged: boolean,
    frameNumber?: number,
  ): Promise<any> {
    return this.request('POST', 'dl-result-flags', {
      dl_result_id: dlResultId,
      username,
      frame_number: frameNumber,
      is_flagged: isFlagged,
    });
  }

  /**
   * Returns all Model Versions associated with a DL Result
   *
   * @returns {Promise<*>}
   */
  getDlcModelVersions(): Promise<any> {
    return this.request('GET', 'dlc/model_versions');
  }

  /**
   * Returns all organizations with names associated with a DL Result
   *
   * @returns {Promise<*>}
   */
  getDlcOrganizations(): Promise<any> {
    return this.request('GET', 'dlc/organizations');
  }

  /**
   * Run all deployed processes/models on the image_id.
   */
  runInference(imageId: string): Promise<any> {
    const data = { image_id: imageId };
    return this.request('POST', 'jobs/process-engine/-/images', data);
  }

  // -------------------------------------------------------------------------
  // DLC Calls proxied through Label-Collector backend
  //--------------------------------------------------------------------------

  /**
   * Returns all Capture Modes.
   */
  getCaptureModes(): Promise<any> {
    return this.request('GET', 'capture-modes/');
  }

  /**
   * Get all DLC models.
   */
  getDlcModels(): Promise<any> {
    return this.request('GET', 'dl-models/');
  }

  /**
   * Get all DLC models for process
   */
  getDlcModelsForProcessId(processId: string): Promise<any> {
    return this.request('GET', `dl-models/?process_id=${processId}`);
  }

  /**
   * Returns all DLC processes.
   */
  getDlcProcesses(): Promise<any> {
    return this.request('GET', 'dlc-processes/');
  }

  /**
   * Returns a specific DLC process.
   */
  getDlcProcess(processId: string): Promise<any> {
    return this.request('GET', `dlc-processes/${processId}/`);
  }

  /**
   * Creates a DLC process.
   */
  createDlcProcess(process: string): Promise<any> {
    return this.request('POST', 'dlc-processes/', process);
  }

  /**
   * Update a DLC process.
   */
  updateDlcProcess(processId: string, data): Promise<any> {
    return this.request('PATCH', `dlc-processes/${processId}/`, data);
  }

  /**
   * Returns all DLC presets
   */
  getDlcPresets(): Promise<any> {
    return this.request('GET', 'dlc-presets/');
  }

  /**
   *  Returns all DLC Image Process Names
   */
  getDlcImagedProcessName(): Promise<any> {
    return this.request('GET', 'dlc-image-process-names/');
  }

  //--------------------------------------------------------------------------

  /**
   * Saves the given corrupt file.
   *
   * @param imageId - The image ID of the file being disabled.
   * @param reason - The reason the file was disabled.
   * @returns {Promise<*>}
   */
  saveCorruptFile(imageId: string, reason: string): Promise<any> {
    const data = { image_id: imageId, reason };
    return this.request('PUT', `corrupt_files/${imageId}`, { ...data });
  }

  /**
   * Returns the file for the given image ID.
   *
   * @param imageId: A UUID for a Study Image.
   * @returns {Promise<*>}
   */
  getFileByImageId(imageId: string): Promise<any> {
    return this.request('GET', `files/${imageId}`);
  }

  /**
   * Returns the list of files filtered by the following parameters.
   *
   * @param studyImageId: A UUID for a Study Image.
   * @param rawFileType: A raw file type.
   * @returns {Promise<*>}
   */
  getFiles(
    studyImageId: string,
    rawFileType?: 'B_MODE' | 'TRANSVERSE_B_MODE',
  ): Promise<any> {
    const query = qs.stringify({ studyImageId, rawFileType });
    return this.request('GET', `files?${query}`);
  }

  /**
   * Returns the set of extracted frame records.
   *
   * Note that this only returns the records, not the images themselves.
   *
   * @param imageId - The image ID to filter on.
   * @param startFrame - The first (inclusive) frame number in the sequence.
   * @param endFrame - The last (inclusive) frame number in the sequence.
   * @returns {Promise<*>} - A list of ExtractedFrame records.
   */
  getExtractedFrames(
    imageId: string,
    startFrame?: number,
    endFrame?: number,
  ): Promise<any> {
    let url = `extracted_frames?image_id=${imageId}`;
    if (
      startFrame != null &&
      endFrame != null &&
      startFrame >= 0 &&
      endFrame >= 0
    ) {
      url += `&frame_number_min=${startFrame}&frame_number_max=${endFrame}`;
    }

    return this.request('GET', url);
  }

  getFrameInterval(file): number {
    const fileMetadata = file.file_metadata;

    let frameTime;
    if (fileMetadata.FrameTime != null) {
      frameTime = fileMetadata.FrameTime;
    } else if (fileMetadata.FrameTimeVector != null) {
      // FIXME: This should handle cases where these aren't all the same.
      frameTime = fileMetadata.FrameTimeVector[0];
    }

    // This is a little weird, but it was the old logic in <DicomFile>.
    return frameTime ? parseFloat(frameTime) : DEFAULT_FRAME_INTERVAL;
  }

  /**
   * Gets the EF value using Simpson's method.
   *
   * @param file - The file being annotated.
   * @param annotations - The apical-ef contours.
   * @returns {Promise<*>} - A promise containing the ejection fraction.
   */
  async getSimpsonsEf(file, annotations): Promise<any> {
    const url = `metrics/simpsons/${file.image_id}`;
    const data = {
      image_id: file.image_id,
      ed_contour: annotations['LV-ED'][0][1],
      es_contour: annotations['LV-ES'][0][1],
    };

    const response = await this.request('PUT', url, { ...data });
    return response.ejection_fraction.toFixed(1);
  }

  async request<T = any, Options extends RequestOptions = {}>(
    method: Method,
    path: string,
    data?: {},
    options?: Options,
  ): Promise<(Options extends { fullData: true } ? any : T) | null> {
    const response = await this.requestRaw(method, path, data, options);
    if (!response) {
      return response;
    }

    if (!response.ok) {
      const error = await Errors.resolveError(response);
      captureException(error);
      throw error;
    }

    const body = await response.json();

    if (options && options.fullData) {
      return body;
    }

    return body.data;
  }

  async requestBlob<Options extends RequestOptions = {}>(
    method: Method,
    path: string,
    data?: {},
    options?: Options,
  ): Promise<{ blob: Blob; filename: string | undefined } | null> {
    const response = await this.requestRaw(method, path, data, options);
    if (!response) {
      return response;
    }

    if (!response.ok) {
      const error = await Errors.resolveError(response);
      captureException(error);
      throw error;
    }

    let filename = response.headers
      .get('Content-Disposition')
      ?.split('; filename=')[1];
    if (filename && filename.startsWith('"') && filename.endsWith('"')) {
      filename = filename.slice(1, -1);
    }

    return {
      blob: await response.blob(),
      filename,
    };
  }

  /**
   * Returns the list of projects
   *
   * @returns {Promise<void>} - A list of projects.
   */
  getProjects(): Promise<void | null> {
    return this.request('GET', 'projects');
  }

  createProject(project: string): Promise<any> {
    return this.request('POST', 'projects', project);
  }

  editProject(projectName: string, project): Promise<any> {
    return this.request('PUT', `projects/${projectName}`, project);
  }

  deleteProject(projectName: string): Promise<any> {
    return this.request('DELETE', `projects/${projectName}`);
  }

  /**
   * Returns the project identified by name.
   *
   * @param name - Name of a project.
   * @returns {Promise<*>}
   */
  getProject(name: string): Promise<any> {
    return this.request('GET', `projects/${name}`);
  }

  associatePresetsWithProject(projectName: string, data): Promise<any> {
    return this.request('POST', `projects/${projectName}/presets`, data);
  }

  associateTasksWithProject(projectName: string, data): Promise<any> {
    return this.request('POST', `projects/${projectName}/tasks`, data);
  }

  dissociatePresetWithProject(projectName: string, name): Promise<any> {
    return this.request('DELETE', `projects/${projectName}/presets/${name}`);
  }

  dissociateTaskWithProject(projectName: string, name): Promise<any> {
    return this.request('DELETE', `projects/${projectName}/tasks/${name}`);
  }

  getEarnings({ startDate, endDate, username }: Record<string, string> = {}) {
    const query = qs.stringify({ username, startDate, endDate });
    return this.request('GET', `task-earnings?${query}`);
  }

  getEarningsDetails({
    startDate,
    endDate,
    username,
  }: Record<string, string> = {}) {
    const query = qs.stringify({ username, startDate, endDate });
    return this.request('GET', `task-earnings/details?${query}`);
  }

  /**
   * Return the pose of the probe given an assignment and a list of at least 3
   * points.
   *
   * @param assignmentId - A UUID representing the assignment being labeled.
   * @param data - The data request.
   * @returns A translation and rotation arrays representing the pose of the
   *  probe. For example:
   *  data: {
   *    quaternions: [0.5744450457240139, 0.5117431886200752, 0.3718864501873188, 0.5194538155709014]
   *    translation: [-5.172954738643576, -46.71069376923634, 80.63147230209877]
   *  }
   */
  getProbePose(assignmentId: string, data) {
    return this.request('PUT', `probe_pose/${assignmentId}`, { ...data });
  }

  getUrl(path) {
    return `${window.bflyConfig.API_URL}/api/v1/${path}`;
  }

  async openToApiResult(
    path,
    data?: {},
    { noAuth = false, allowNotFound = false } = {},
  ): Promise<void> {
    const result = await this.requestBlob('GET', path, data, {
      noAuth,
      allowNotFound,
    });

    if (!result?.blob) {
      return;
    }

    saveAs(result.blob, result.filename);
  }

  async requestRaw(
    method: Method,
    path: string,
    data?: {},
    { noAuth = false, allowNotFound = false } = {},
  ): Promise<Response | null> {
    const url = this.getUrl(path);
    const init: RequestInit = {
      method,
      credentials: 'same-origin',
    };

    if (this.authorization && !noAuth) {
      init.headers = { Authorization: this.authorization };
    }
    if (data) {
      init.headers = { 'Content-Type': 'application/json', ...init.headers };
      init.body = JSON.stringify({ data });
    }

    const response = await fetch(url, init);

    if (
      response.status === 204 ||
      (allowNotFound && response.status === 404 && method === 'GET')
    ) {
      return null;
    }

    return response;
  }
}
