/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Point } from '@bfly/utils/math';

import { EllipseData, PathData, RectData, Trace } from '../types.d';
import TraceableOverlay from './TraceableOverlay';

interface Props {
  trace: Trace;
  results: (EllipseData | PathData | Point)[] | null;
  onTrace: (
    trace: Trace,
    data: EllipseData | RectData | PathData | null,
    index: number,
  ) => void;
  disabled: boolean;
  onClearFrame: () => void;
}

function TracesOverlay({
  trace,
  results,
  disabled,
  onTrace,
  onClearFrame,
}: Props) {
  if (!results) {
    return null;
  }

  return (
    <>
      {results.map((frameResult, idx) => (
        <TraceableOverlay
          key={`${trace.traceId}-${idx}`}
          trace={trace}
          onTrace={(t, d) => onTrace(t, d, idx)}
          results={frameResult}
          onClearFrame={onClearFrame}
          disabled={disabled}
        />
      ))}
    </>
  );
}

export default TracesOverlay;
