import { DependencyList, useEffect, useState } from 'react';
import useFetchHandler from './useFetchHandler';
import { usePrevious } from '@restart/hooks';

export interface PaginationResult {
  pageData: Array<any> | null;
  accumulatedData: Array<any>;
  isLoading: boolean;
  error: Error | null;
  hasMore: boolean;
  getNext: () => void;
}

const useCursorPaginationHandler = (
  request: (parameters: Record<string, any>) => Promise<any>,
  params: Record<string, any>,
  deps: DependencyList,
): PaginationResult => {
  const [cursor, setCursor] = useState<string | null>(null);
  const [nextCursor, setNextCursor] = useState('');
  const [accumulatedData, setAccumulatedData] = useState<Array<any>>([]);
  const [hasMore, setHasMore] = useState(true);
  const prevCursor = usePrevious(cursor);

  useEffect(() => {
    if (prevCursor === cursor) {
      setAccumulatedData([]);
      if (cursor) {
        setCursor(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, ...deps]);

  const { data, loading, error } = useFetchHandler(
    () => request({ ...params, cursor }),
    [cursor, ...deps],
  );

  useEffect(() => {
    if (!loading) {
      if (data?.data && data?.meta) {
        setAccumulatedData((prevProps) => [...prevProps, ...data?.data]);
        setHasMore(data?.meta.has_next_page);
        setNextCursor(data?.meta.cursors[data?.meta.cursors.length - 1]);
      }
    }
  }, [
    loading,
    data?.data,
    data?.meta,
    data?.meta?.has_next_page,
    data?.meta?.cursors,
    data?.meta?.cursors?.length,
  ]);

  return {
    pageData: data?.data,
    accumulatedData,
    isLoading: loading,
    error,
    hasMore,
    getNext: () => setCursor(nextCursor),
  };
};

export default useCursorPaginationHandler;
