import styled from 'astroturf';
import { routerShape } from 'found/lib/PropTypes';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Form from '@bfly/ui/Form';
import Header from '@bfly/ui/Header';

import AssignmentForm from '../components/AssignmentForm';
import Page from '../components/Page';

const WorklistProgress = styled(ProgressBar)`
  height: 0.4rem;
  border-radius: 0;
`;

const propTypes = {
  data: PropTypes.object.isRequired,

  match: PropTypes.object.isRequired,

  // The routing mechanism used for links.
  router: routerShape.isRequired,
};

function EditWorklistAssignmentPage(props) {
  const {
    data,
    router,
    match: { params },
  } = props;
  const { worklist, assignment, task } = data;

  const toNextWorklist = () => {
    router.replace({
      pathname: `/${params.username}/worklists/${params.worklistId}`,
      state: { lastAssignment: assignment.assignment_id },
    });
  };

  useEffect(() => {
    document.title = `${worklist.name} - ${task.name} - Butterfly`;
  });

  return (
    <AssignmentForm
      {...props}
      onSubmit={toNextWorklist}
      header={
        <>
          <WorklistProgress now={worklist.completedRatio} />
          <Page.Header backTo={`/${params.username}`}>
            <Header.Title>
              {worklist.name}: {task.name}
            </Header.Title>
            <Header.Actions align="center">
              <Header.Action onClick={toNextWorklist}>
                Cancel Edit
              </Header.Action>
              <Form.Submit>Submit and Continue</Form.Submit>
            </Header.Actions>
          </Page.Header>
        </>
      }
    />
  );
}

EditWorklistAssignmentPage.propTypes = propTypes;
EditWorklistAssignmentPage.getData = AssignmentForm.getData;

export default EditWorklistAssignmentPage;
