import { InferType, number, object, string } from 'yup';

const schema = object({
  completed: number(),
  taskDescription: string(),
  version: number(),
  totalAmount: number(),
  rate: number(),
}).camelCase();

export type EarningDetail = InferType<typeof schema>;

export const deserializeEarningDetails = (items) =>
  items.map((d) => schema.cast(d));

export default schema;
