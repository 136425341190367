import React from 'react';
import { css } from 'astroturf';

import Text from '@bfly/ui/Text';
import Dropdown from '@bfly/ui/Dropdown';
import DropdownIcon from '@bfly/icons/Dropdown';
import { Worklist } from 'src/schema/Worklist';

const styles = css`
  .arrow {
    margin-left: 1rem;
  }
`;
interface Props {
  worklist: Worklist;
  setSelectedWorklist: (worklist: Worklist) => void;
  handleDeleteWorklist: (worklist: Worklist) => void;
  handleClearWorklist: (worklist: Worklist) => void;
  setHideCurateModal?: (value: boolean) => void;
  setHideDuplicateModal?: (value: boolean) => void;
}

const WorklistDropdownOptions = ({
  worklist,
  setSelectedWorklist,
  setHideCurateModal,
  setHideDuplicateModal,
  handleDeleteWorklist,
  handleClearWorklist,
}: Props) => {
  const handleCurate = () => {
    setSelectedWorklist(worklist);
    if (setHideCurateModal) setHideCurateModal(false);
  };

  const handleDuplicate = () => {
    setSelectedWorklist(worklist);
    if (setHideDuplicateModal) setHideDuplicateModal(false);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle>
        <Text transform="capitalize" variant="inherit">
          Options
        </Text>
        <DropdownIcon width="10" className={styles.arrow} />
      </Dropdown.Toggle>
      <Dropdown.Menu data-bni-id="worklist-options-dropdown">
        <Dropdown.Item onClick={handleDuplicate}>Duplicate</Dropdown.Item>

        {worklist.completedRatio === 100 && (
          <Dropdown.Item onClick={handleCurate}>Curate</Dropdown.Item>
        )}

        <Dropdown.Divider />

        {worklist.numCompletedAssignments === 0 && (
          <Dropdown.Item
            onClick={() => handleDeleteWorklist(worklist)}
            theme="danger"
          >
            Delete
          </Dropdown.Item>
        )}

        {worklist.completedRatio < 100 &&
          worklist.numCompletedAssignments > 0 && (
            <Dropdown.Item
              onClick={() => handleClearWorklist(worklist)}
              theme="danger"
            >
              Clear
            </Dropdown.Item>
          )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default WorklistDropdownOptions;
