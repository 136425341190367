import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';

import LoadingSheet from './LoadingSheet';
import Plotly from './Plotly';

// eslint-disable-next-line
const darkTheme = require('../constants/plotlyTheme.json');

const BasePlot = createPlotlyComponent(Plotly);

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
};

function Plot({ layout, isLoading = false, ...otherProps }) {
  const plotParams = useMemo(
    () => ({
      layout: {
        ...layout,
        template: darkTheme,
        autosize: true,
      },
    }),
    [layout],
  );

  // Plotly updates the entire PlotParams on update.
  // So we save it all in state
  const [props, setState] = useState(plotParams);

  // Update PlotParams state if incoming props change
  if (props !== plotParams) setState(plotParams);

  return (
    <LoadingSheet isLoading={isLoading}>
      <BasePlot
        {...otherProps}
        {...props}
        onInitialized={(figure) => setState(figure)}
        onUpdate={(figure) => setState(figure)}
        useResizeHandler
        style={{
          width: '100%',
          height: '100%',
          margin: '1rem 0',
        }}
      />
    </LoadingSheet>
  );
}

Plot.propTypes = propTypes;
export default Plot;
