import React from 'react';
import Layout from '@4c/layout';

import PreviewListItem from './PreviewListItem';

const ITEM_SIZE_PX = 100;
const ITEM_SPACING_PX = 10;

const styles = css`
  .root {
    overflow: hidden;
    padding-left: 50%;
  }

  .container {
    transition: 0.3s ease-in-out margin-left;
  }

  .item {
    margin: calc(0.5 * ${ITEM_SPACING_PX}px);
  }
`;

// FIXME: move this back upstream and make the API more ammenable to this use case
export default function PreviewRoll({ images, activeIndex, onSelect }) {
  const fullPreviewSize = ITEM_SIZE_PX + ITEM_SPACING_PX;
  const marginLeft = -(activeIndex + 0.5) * fullPreviewSize;

  return (
    <div className={styles.root}>
      <Layout
        direction="row"
        style={{ marginLeft }}
        className={styles.container}
      >
        {images.map((image, index) => (
          <PreviewListItem
            key={index} // eslint-disable-line react/no-array-index-key
            eventKey={index}
            index={index}
            image={image}
            size={ITEM_SIZE_PX}
            onSelect={onSelect}
            active={activeIndex === index}
            className={styles.item}
          />
        ))}
      </Layout>
    </div>
  );
}
