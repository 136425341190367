import snakeCase from 'lodash/snakeCase';

export function stringify(queryObj) {
  if (!queryObj) {
    return '';
  }

  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(queryObj)) {
    if (value != null) {
      params.append(snakeCase(key), value);
    }
  }

  return params.toString();
}
