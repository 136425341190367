import React from 'react';
import Form from '@bfly/ui/Form';

import { Intervals } from '../schema/AnnotationTask';
import AccordionFieldArray from './AccordionFieldArray';
import { QuestionForm } from './QuestionsForm';

const defaultIntervals = Intervals.default();

interface Props {
  name?: string;
}

// TODO: limit one intervals item and fix backing out validation error
function IntervalsForm({ name = 'definition.intervals' }: Props) {
  return (
    <Form.FieldSet legend="Intervals">
      <AccordionFieldArray<Intervals>
        name={name}
        label="Intervals"
        labelSrOnly
        defaultValue={defaultIntervals}
      >
        {({ value, helpers }) =>
          value.map((item, idx) => {
            const fieldName = `${name}[${idx}]`;

            return (
              <AccordionFieldArray.Item
                key={name}
                name={fieldName}
                onRemove={helpers.remove(item)}
                title={
                  item && item.displayText
                    ? `${item.displayText}`
                    : 'New Interval'
                }
              >
                <QuestionForm defaultTypeValue="tag" hideTagStyle />
              </AccordionFieldArray.Item>
            );
          })
        }
      </AccordionFieldArray>
    </Form.FieldSet>
  );
}

export default IntervalsForm;
