/* eslint-disable react/no-multi-comp */
import { css } from 'astroturf';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { alpha } from '../utils/Color';
import { useShortcut } from './KeyboardShortcutManager';

const styles = css`
  @import '~@bfly/ui/styles/theme';

  @keyframes stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 2rem;
    margin: 1rem 0;
    background: #ddd;
  }

  .large {
    min-height: 6rem;
  }

  .interval {
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none;
  }

  .segment {
    position: relative;
    min-height: 1rem;
    flex: 1 1 0;
    height: 100%;
    z-index: 2;
  }

  .indicator {
    composes: interval;

    box-sizing: content-box;
    border: 2px solid #333;
    margin: -2px;
    z-index: 2;
  }

  .hover {
    composes: interval;

    background: transparentize(#000, 0.7);
    z-index: 2;
  }

  .range {
    composes: interval;

    z-index: 2;
    background-color: transparentize(#000, 0.3);
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;

    &.selected {
      animation: stripes 0.5s linear infinite;
    }
  }

  .disabled {
    cursor: not-allowed;
    background-color: #b75555;
  }

  .enabled-frame {
    background: #ddd;
    transform: scaleY(1.2);
    pointer-events: auto;
    cursor: pointer;
    transition: transform 200ms;

    &:hover {
      transform: scale(1.5, 1.5);
    }
  }
`;

export function getContiguousIntervals(intervalAnnotations) {
  const intervals = new Map();
  const current = new Map();

  const addToCurrent = (id, idx) => current.set(id, { start: idx, end: idx });

  const moveToResult = (id) => {
    const newInterval = current.get(id);
    current.delete(id);
    const old = intervals.get(id);
    intervals.set(id, (old || []).concat(newInterval));
  };

  Object.entries(intervalAnnotations).forEach(([key, tagValues]) => {
    const frameIdx = +key;

    tagValues.forEach((tagValue) => {
      const interval = current.get(tagValue);

      if (!interval) {
        addToCurrent(tagValue, frameIdx);
        return;
      }

      if (interval.end + 1 === frameIdx) {
        interval.end = frameIdx;
      } else {
        moveToResult(tagValue);
        addToCurrent(tagValue, frameIdx);
      }
    });
  });

  Array.from(current.keys(), moveToResult);

  return intervals;
}

export function pointerToFrameIndex({ currentTarget, pageX }, count) {
  const { left, right } = currentTarget.getBoundingClientRect();
  const range = Math.abs(left - right);
  const offsetX = (pageX - left) / range;

  const frameIndex = Math.min(
    count - 1,
    Math.max(0, Math.floor(offsetX * count)),
  );

  return frameIndex;
}

const getCellStyle = (idx, width, length = 1) => ({
  left: `${idx * width}%`,
  width: `${length * width}%`,
});

const Interval = ({
  start,
  length = 1,
  width,
  className,
  style,
  ...props
}) => (
  <div
    {...props}
    className={classNames(styles.interval, className)}
    style={{ ...style, ...getCellStyle(start, width, length) }}
  />
);

/**
 * Represents a collection of frame intervals. Split into a
 * a new component so that frame index changes doesn't rerender all the interval
 * overlays.
 */
// eslint-disable-next-line prefer-arrow-callback
const Intervals = React.memo(function Intervals({
  width,
  intervalsDefinition,
  intervalAnnotations,
}) {
  if (!intervalAnnotations) return null;

  const { tagOptions } = intervalsDefinition;

  const tagsByValue = {};
  for (const tag of tagOptions) tagsByValue[tag.value] = tag;

  const intervalsByTag = getContiguousIntervals(intervalAnnotations);

  return (
    <>
      {!!intervalsByTag.size && (
        // this controls the height of the intervals segment so that it stacks appropriately
        // atop any traces segments
        <div className={styles.segment}>
          {Array.from(intervalsByTag.entries(), ([tagValue, tagIntervals]) =>
            tagIntervals.map(({ start, end }) => (
              <Interval
                key={`${tagsByValue[tagValue].value}_${start}_${end}`}
                style={{ backgroundColor: tagsByValue[tagValue].color }}
                width={width}
                start={start}
                length={end - start + 1}
              />
            )),
          )}
        </div>
      )}
    </>
  );
});

/**
 * Represents a collection of frames that contain traces. Split into a
 * a new component so that frame index changes doesn't rerender all the trace
 * overlays.
 */
// eslint-disable-next-line prefer-arrow-callback
const Traces = React.memo(function Traces({
  width,
  tracesDefinition,
  traceAnnotations,
}) {
  return (
    <>
      {tracesDefinition.map((trace) => {
        const { traceId, color, interpolate } = trace;

        const values = traceAnnotations[traceId];
        if (!values || !values.length) return null;

        return (
          <div className={styles.segment} key={traceId}>
            {values.map(([idx], ii) => {
              const nextIdx = ii + 1;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={ii}>
                  <Interval
                    style={{ backgroundColor: color }}
                    width={width}
                    start={idx}
                  />
                  {interpolate && values[nextIdx] && (
                    <Interval
                      style={{
                        backgroundColor: alpha(color, 0.25),
                      }}
                      width={width}
                      start={idx + 1}
                      length={values[nextIdx][0] - idx - 1}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
    </>
  );
});

const EnabledFrames = React.memo(({ enabledFrames, width, onSelectIndex }) => (
  <>
    {enabledFrames.map((frame) => (
      <Interval
        key={`frame_${frame}`}
        className={styles.enabledFrame}
        start={frame}
        width={width}
        onClick={() => onSelectIndex(frame)}
      />
    ))}
  </>
));

const useBoolRef = (dflt = false) => {
  const flag = useRef(dflt);
  return [
    () => flag.current,
    (nextValue) => {
      flag.current = !!nextValue;
    },
  ];
};
/**
 * A hook that returns a `hoverIndex` and `range` of selected frames as well as
 * an object of event handlers to be added to an element.
 *
 * It handles all the pointer interactions for the frame scrubber.
 */
function useFrameSelectionEvents({
  activeIndex,
  frameCount,
  onSelectIndex,
  onSelectRange,
  onTogglePlayback,
  enabledFrames,
  readOnly,
}) {
  const [isTwoStageSelecting, toggleSelecting] = useBoolRef(false);
  const [range, setRange] = useState(null);
  const allowRangeSelect = !!onSelectRange && !readOnly;

  const [hoverIndex, setHoverIndex] = useState(null);
  const width = (1 / frameCount) * 100;

  const enabledFramesSet = useMemo(
    () => new Set(enabledFrames),
    [enabledFrames],
  );

  const hasEnabledFrames = enabledFramesSet.size > 0;

  const commitRange = useCallback(
    (r) => onSelectRange(sortBy(r)),
    [onSelectRange],
  );

  if (isTwoStageSelecting() && range) {
    const [start, end] = range;
    if (activeIndex !== end) {
      setRange([start, activeIndex]);
    }
  }

  const setNextEnabledFrame = () => {
    if (!enabledFrames) return;

    const nextEnabledFrame =
      enabledFrames
        .sort((a, b) => a - b)
        .find((frame) => frame > activeIndex) || enabledFrames[0];
    onSelectIndex(nextEnabledFrame);
  };

  const setPreviousEnabledFrame = () => {
    if (!enabledFrames) return;

    const prevEnabledFrame =
      enabledFrames
        .sort((a, b) => b - a)
        .find((frame) => frame < activeIndex) || enabledFrames[0];

    onSelectIndex(prevEnabledFrame);
  };

  useShortcut({
    z: () => {
      if (readOnly) {
        return;
      }
      if (hasEnabledFrames && !enabledFramesSet.has(activeIndex)) return;

      toggleSelecting(!isTwoStageSelecting());
      onTogglePlayback(true);

      if (!isTwoStageSelecting()) {
        commitRange(range);
        setRange(null);
      } else if (activeIndex != null) {
        setRange([activeIndex, activeIndex]);
      }
    },
    'Alt+ArrowLeft': () => {
      if (enabledFrames.length) {
        setPreviousEnabledFrame();
      }
    },
    'Alt+ArrowRight': () => {
      if (enabledFrames.length) {
        setNextEnabledFrame();
      }
    },
  });

  const handlePointerDown = useCallback(
    (e) => {
      if (!e.isPrimary) return;

      toggleSelecting(false);
      onTogglePlayback(true);

      e.preventDefault();
      e.target.setPointerCapture(e.pointerId);

      const nextIdx = pointerToFrameIndex(e, frameCount, width);

      if (!hasEnabledFrames || enabledFramesSet.has(nextIdx)) {
        setRange([nextIdx, nextIdx]);
      }

      if (hoverIndex != null) setHoverIndex(null);
    },
    [
      hasEnabledFrames,
      enabledFramesSet,
      frameCount,
      hoverIndex,
      onTogglePlayback,
      toggleSelecting,
      width,
    ],
  );

  return [
    hoverIndex,
    range && sortBy(range),
    {
      onPointerDown: allowRangeSelect ? handlePointerDown : undefined,
      onPointerUp: useCallback(
        (e) => {
          if (!e.isPrimary) return;
          e.preventDefault();

          const nextIdx = pointerToFrameIndex(e, frameCount, width);
          const isClick = !range || range[0] === range[1];

          if (range && (!hasEnabledFrames || enabledFramesSet.has(nextIdx))) {
            const [start] = range;
            commitRange([start, nextIdx]);
            setRange(null);
          }

          const nextHover = range ? range[1] : nextIdx;
          if (isClick && nextHover != null) {
            onSelectIndex(nextHover);
          }
        },
        [
          frameCount,
          width,
          range,
          hasEnabledFrames,
          enabledFramesSet,
          commitRange,
          onSelectIndex,
        ],
      ),
      onPointerLeave: useCallback(
        (e) => {
          if (e.isPrimary && hoverIndex != null) {
            setHoverIndex(null);
          }
        },
        [hoverIndex],
      ),
      onPointerMove: useCallback(
        (e) => {
          if (!e.isPrimary) return;

          e.preventDefault();
          const nextIdx = pointerToFrameIndex(e, frameCount, width);

          if (nextIdx != null)
            if (
              range &&
              (!hasEnabledFrames || enabledFramesSet.has(nextIdx))
            ) {
              const [start] = range;
              setRange([start, nextIdx]);
              onSelectIndex(nextIdx);
            } else if (nextIdx !== hoverIndex) {
              setHoverIndex(nextIdx);
            }
        },
        [
          enabledFramesSet,
          hasEnabledFrames,
          frameCount,
          hoverIndex,
          onSelectIndex,
          range,
          width,
        ],
      ),
    },
  ];
}

const propTypes = {};

function FrameScrubber({
  frameCount,
  activeIndex,
  onTogglePlayback,
  selectedInterval,
  onSelectIndex,
  onSelectInterval,
  tracesDefinition,
  intervalsDefinition,
  intervalAnnotations,
  traceAnnotations,
  enabledFrames,
  readOnly,
}) {
  const [hoverIndex, pendingRange, selectionEvents] = useFrameSelectionEvents({
    activeIndex,
    frameCount,
    onSelectIndex,
    onTogglePlayback,
    enabledFrames,
    onSelectRange: intervalsDefinition && onSelectInterval,
    readOnly,
  });

  const width = (1 / frameCount) * 100;
  const currentRange = pendingRange || selectedInterval;

  return (
    <div
      className={classNames(
        styles.container,
        intervalsDefinition && styles.large,
        enabledFrames && enabledFrames.length ? styles.disabled : null,
      )}
      {...selectionEvents}
    >
      {intervalsDefinition && (
        <Intervals
          intervalsDefinition={intervalsDefinition}
          intervalAnnotations={
            intervalAnnotations?.[intervalsDefinition.questionId]
          }
          width={width}
        />
      )}

      {tracesDefinition && (
        <Traces
          tracesDefinition={tracesDefinition}
          traceAnnotations={traceAnnotations}
          width={width}
        />
      )}

      {enabledFrames && (
        <EnabledFrames
          enabledFrames={enabledFrames}
          width={width}
          onSelectIndex={onSelectIndex}
        />
      )}

      {/* Single Frame Cine's are always selected so we don't need to visually indicate */}
      {currentRange && frameCount > 1 && (
        <div
          draggable="off"
          className={classNames(
            styles.range,
            currentRange === selectedInterval && styles.selected,
          )}
          style={getCellStyle(
            currentRange[0],
            width,
            currentRange[1] - currentRange[0] + 1,
          )}
        />
      )}
      {hoverIndex != null && (
        <div
          draggable="off"
          className={styles.hover}
          style={getCellStyle(hoverIndex, width)}
        />
      )}

      <div
        draggable="off"
        className={styles.indicator}
        style={getCellStyle(activeIndex, width)}
      />
    </div>
  );
}

FrameScrubber.propTypes = propTypes;

export default FrameScrubber;
