import { css } from 'astroturf';
import Link from 'found/lib/Link';
import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import Layout from '@4c/layout';
import CheckIco from '@bfly/icons/CheckMarkSmall';
import Button from '@bfly/ui/Button';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';
import Modal from '@bfly/ui/Modal';
import { useToast } from '@bfly/ui/ToastContext';

import AppPage from '../components/AppPage';
import { useApi } from '../components/AuthProvider';
import CinePlayer from '../components/CinePlayer';
import Table from '../components/Table';
import * as Assignments from '../utils/Assignments';
import executeWithErrorToast from '../utils/executeWithErrorToast';
import getFileData from '../utils/getFileData';

const JsonSnippet = lazy(() => import('../components/JsonSnippet'));

const styles = css`
  .annotation {
    height: auto;
    flex: 1;
    text-align: left !important;
    padding: 0 !important;

    & pre {
      color: #fff;
      overflow: auto;
      max-height: 20rem;
      padding: 10px 15px;
      margin: 0;
    }
  }

  tr.disabled td {
    text-decoration: line-through;
  }
  tr.enabled td {
  }

  .button {
    padding: 10px;
    align-items: center;
  }
`;

const propTypes = {
  data: PropTypes.object.isRequired,
};

function onSuccess(x) {
  return [true, x];
}

function onFailure(_x) {
  return [false, null];
}

async function getData({ params, context }) {
  const { imageId } = params;
  const { api } = context;

  const [
    viewer,
    [showLabels, labels],
    [showDlResults, dlResults],
    { file, frames },
  ] = await Promise.all([
    AppPage.getData({ context }),
    api.getLabelsByImageId(imageId).then(onSuccess, onFailure),
    api.getDlResultsByImageId(imageId).then(onSuccess, onFailure),
    getFileData({ api, imageId }),
  ]);

  return {
    viewer,
    imageId,
    file,
    frames,
    showLabels,
    labels,
    showDlResults,
    dlResults,
  };
}

function FileViewerPage({ data }) {
  const {
    viewer,
    imageId,
    file,
    frames,
    showLabels,
    labels,
    showDlResults,
    dlResults,
  } = data;
  const api = useApi();
  const [currentLabel, setCurrentLabel] = useState(null);
  const { images } = Assignments.useFrameData({ frames });
  const toast = useToast();

  const runInferenceRequest = async () => {
    await executeWithErrorToast(toast, () => api.runInference(imageId));
    toast.success('Inference Queued');
  };

  useEffect(() => {
    document.title = `File ${imageId} - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>{imageId}</Header.Title>
      </Header>

      <Layout align="flex-start">
        <MainContent size="large">
          <CinePlayer defaultPlay api={api} file={file} frames={images} />
          <Layout direction="row" wrap justify="space-evenly">
            {showLabels && (
              <div>
                <Table className="mt-4 w-auto mx-auto">
                  <thead>
                    <tr>
                      <th>Assignment</th>
                      <th>Username</th>
                      <th>Annotations</th>
                      <th>Enabled</th>
                    </tr>
                  </thead>
                  <tbody>
                    {labels.map((label) => (
                      <tr
                        key={label.created_at}
                        className={
                          styles[label.enabled ? 'enabled' : 'disabled']
                        }
                      >
                        <td>
                          <Link
                            to={`/${label.assignment.username}/worklists/${label.assignment.worklist_id}/${label.assignment.assignment_id}`}
                          >
                            <div>{label.assignment.task}</div>
                          </Link>
                          <div className="mt-2">
                            {new Date(label.created_at).toLocaleDateString({
                              date: 'medium',
                            })}
                          </div>
                        </td>
                        <td>{label.assignment.username}</td>
                        <td>
                          <Button
                            theme="link"
                            onClick={() => setCurrentLabel(label)}
                          >
                            View Label
                          </Button>
                        </td>
                        <td className={styles.center}>
                          {label.enabled && <CheckIco />}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
            {showDlResults && (
              <div align="center">
                <Table className="mt-4 w-auto mx-auto">
                  <thead>
                    <tr>
                      <th>Project</th>
                      <th>Version</th>
                      <th>Tool</th>
                      <th>Mode</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dlResults.map((dlResult) => (
                      <tr key={dlResult.created_at}>
                        <td>
                          <Link
                            to={`/-/admin/dlc/inference-inspector/${dlResult.dl_result_id}`}
                          >
                            <div>{dlResult.project}</div>
                          </Link>
                          <div className="mt-2">
                            {new Date(dlResult.created_at).toLocaleDateString({
                              date: 'medium',
                            })}
                          </div>
                        </td>
                        <td>{dlResult.version}</td>
                        <td>{dlResult.tool_name}</td>
                        <td>{dlResult.tool_mode}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className={styles.button}>
                  <Button theme="secondary" onClick={runInferenceRequest}>
                    Queue Processes
                  </Button>
                </div>
              </div>
            )}
          </Layout>
          <Modal
            backdrop
            show={!!currentLabel}
            onHide={() => setCurrentLabel(null)}
          >
            <Modal.Header>
              {/* eslint-disable-next-line camelcase */}
              <Modal.Title>{currentLabel?.assignment_id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <React.Suspense fallback="loading...">
                <JsonSnippet json={currentLabel} />
              </React.Suspense>
            </Modal.Body>
            <Modal.Footer />
          </Modal>
        </MainContent>
      </Layout>
    </AppPage>
  );
}

FileViewerPage.propTypes = propTypes;
FileViewerPage.getData = getData;

export default FileViewerPage;
