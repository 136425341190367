const isModifier = (s) =>
  s === 'ctrlKey' || s === 'metaKey' || s === 'altKey' || s === 'shiftKey';

const normalizeKey = (key) => {
  const value = key.trim().toLowerCase();

  if (!value) return key;
  if (value === 'ctrl' || value === 'control') return 'ctrlKey';
  if (value === 'cmd' || value === 'command') return 'metaKey';
  if (value === 'alt' || value === 'option') return 'altKey';
  if (value === 'shift') return 'shiftKey';
  return key.trim();
};

const matchesKey = (evt, key) => {
  // case sensitive if the shift key is held down
  const nKey = evt.shiftKey ? key : key.toLocaleLowerCase();
  const eKey = evt.shiftKey ? evt.key : evt.key.toLocaleLowerCase();
  return eKey === nKey;
};

export default function parseShortcut(shortcut, delimiter = '+') {
  return shortcut
    .split(delimiter)
    .map((key) => {
      const nKey = normalizeKey(key);
      return (evt) => (isModifier(nKey) ? evt[nKey] : matchesKey(evt, nKey));
    })
    .reduce((acc, next) => (evt) => acc(evt) && next(evt));
}
