import { InferType, array, date, number, object, string } from 'yup';

const schema = object({
  id: string().required(),
  name: string().required(),
  username: string().required(),
  taskName: string().required(),
  priority: number().required(),
  numAssignments: number().required(),
  numCompletedAssignments: number().required(),
  completedRatio: number().required(),
  deadline: date().nullable(),
  childrenWorklist: array(),
  lastCompleted: date().nullable(),
}).camelCase();

export type Worklist = InferType<typeof schema>;

export const deserialize = (worklist) => {
  if (!worklist) return null;
  const result = schema.cast(worklist);

  result.completedRatio = Math.floor(
    (result.numCompletedAssignments / result.numAssignments) * 100,
  );
  return result;
};
