import React, { useContext, useMemo, useState } from 'react';
import { boolean, object, string } from 'yup';
import Layout from '@4c/layout';
import Header from '@bfly/ui/Header';
import ToastContext from '@bfly/ui/ToastContext';

import AppPage from '../components/AppPage';
import { useApi } from '../components/AuthProvider';
import * as Task from '../schema/AnnotationTask';
import * as Label from '../schema/Label';
import * as Assignments from '../utils/Assignments';
import executeWithErrorToast from '../utils/executeWithErrorToast';
import getFileData from '../utils/getFileData';
import CineReview from './CineReview';
import TracesReview from './TracesReview';

const LabelEnabledSchema = object({
  enabled: boolean().default(true),
  assignmentId: string().required(),
}).snakeCase();

async function getData({ params, context }) {
  const { api } = context;
  const { task: taskName, imageId } = params;

  const [viewer, allLabels, rawTask, { file, frames }] = await Promise.all([
    AppPage.getData({ context }),
    api.getAdminReviewLabels(taskName, imageId),
    api.getLatestTask(taskName),
    getFileData({ api, imageId }),
  ]);

  const task = Task.deserialize(rawTask);
  const labels = allLabels.map((label) =>
    Label.deserialize(label, task.definition, {
      username: label.assignment.username,
    }),
  );

  return {
    viewer,
    imageId,
    file,
    frames,
    task,
    labels,
  };
}

function LabelsReviewPage({ data }) {
  const { viewer, imageId, file, task, frames, compFile, compFrames, labels } =
    data;

  const api = useApi();
  const toast = useContext(ToastContext);
  const defaultValue = useMemo<Set<string>>(() => {
    const disabled = new Set<string>();
    labels.forEach((label) => {
      if (!label.enabled) {
        disabled.add(label.assignmentId);
      }
    });

    return disabled;
  }, [labels]);
  const [disabled, setDisabled] = useState<Set<string>>(defaultValue);

  const { images, compImages } = Assignments.useFrameData({
    frames,
    compFrames,
  });

  const handleChangeDisable = async (assignmentId) => {
    const isDisabled = disabled.has(assignmentId);

    const label = await executeWithErrorToast(toast, () =>
      api.enableLabel(
        assignmentId,
        LabelEnabledSchema.cast({ assignmentId, enabled: isDisabled }),
      ),
    );

    toast!.success(`Trace ${label.enabled ? 'Enabled' : 'Disabled'}`);
    setDisabled((d) => {
      const s = new Set(d);
      if (!label.enabled) {
        s.add(assignmentId);
      } else {
        s.delete(assignmentId);
      }
      return s;
    });
  };

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>
          Review {task.name} - {imageId}
        </Header.Title>
      </Header>
      <Layout align="flex-start">
        {/* eslint-disable no-nested-ternary */}
        {task.definition.type === 'cine' ? (
          <CineReview
            task={task}
            labels={labels}
            images={images}
            file={file}
            compImages={compImages}
            compFile={compFile}
            disabled={disabled}
            onChangeDisabled={handleChangeDisable}
          />
        ) : task.definition.type === 'pixel' ? (
          <TracesReview
            task={task}
            labels={labels}
            images={images}
            file={file}
            disabled={disabled}
            onChangeDisabled={handleChangeDisable}
          />
        ) : null}
      </Layout>
    </AppPage>
  );
}

LabelsReviewPage.getData = getData;
export default LabelsReviewPage;
