import Layout from '@4c/layout';
import styled, { css } from 'astroturf';
import copyToClipBoard from 'copy-text-to-clipboard';
import { Match, Router, withRouter } from 'found';
import React from 'react';
import { uncontrollable } from 'uncontrollable';
import { DrawingCanvas } from '@bfly/annotation-tools';
import Spinner from '@bfly/ui/Spinner';

import Api from '../Api';
import { PageContext } from './AppPage';
import CineControls from './CineControls';
import FrameScrubber from './FrameScrubber';
import { withShortcuts } from './KeyboardShortcutManager';
import { File } from '../models';

const Loading = styled(Spinner)`
  height: 3rem;
  position: absolute;
  top: 2rem;
  left: 2rem;
`;

const styles = css`
  .indicator {
    position: absolute;
    top: 1rem;
    right: 2rem;
    z-index: 20;
  }
`;

const DEFAULT_CINE_WIDTH = 800;
const DEFAULT_CINE_HEIGHT = 600;

interface IdLabelProps {
  label?: string;
  imageId: string;
  onClick: (e: React.MouseEvent, viewerIsAdmin: boolean, imageId) => void;
  textAlign?: 'right' | 'left';
}

function IdLabel({
  label,
  imageId,
  onClick,
  textAlign = 'right',
}: IdLabelProps) {
  return (
    <small
      style={{
        display: 'block',
        padding: '.4rem',
        textAlign,
      }}
    >
      <PageContext.Consumer>
        {({ viewerIsAdmin }) => (
          <button
            type="button"
            onClick={(e) => onClick(e, viewerIsAdmin, imageId)}
          >
            {label}
            {label && ' : '}
            {imageId}
          </button>
        )}
      </PageContext.Consumer>
    </small>
  );
}

interface IndicatorProps {
  frameIndex: number;
}

interface FrameScrubberProps {
  frameIndex: number;
  onSelectIndex: (number) => void;
  onTogglePlayback: (boolean) => void;
}

interface FrameOverlayProps {
  frameIndex: number;
}

interface Props {
  /** An instance of a file to display as the primary file. See models.File. */
  file: File;

  /** An instance of a file to display as the secondary file. See
   *  models.File. */
  secondaryFile: File;

  /** The list of frames of the primary file in the cine. */
  frames: [HTMLImageElement];

  /** The list of frames of the secondary file in the cine. */
  secondaryFrames?: [HTMLImageElement];

  /** An instance of the frontend API. */
  api: typeof Api;

  /** The width of the cine. The ratio of width to height should be 4:3. */
  cineWidth: number;

  /** The height of the cine. */
  cineHeight: number;

  /** Any ReactNode to display in the top right corner of the player (ie. calculations, quality values) */
  renderIndicator?: (props: IndicatorProps) => React.ElementType;

  /** Any ReactNode to display in the top right corner of the secondary
   *  frame (ie. quality values), if there are secondary frames */
  renderSecondaryIndicator?: (props: IndicatorProps) => React.ElementType;

  /** Component that enables scrubbing or stepping, frame by frame, through the cine */
  renderFrameScrubber: (props: FrameScrubberProps) => React.ElementType;

  /** Current playback state of the cine */
  paused: boolean;

  /** Current frameIndex of the frame displayed in the cine */
  frameIndex: number;

  /** Function that enables keyboard shortcuts for playing the cine */
  registerShortcut?: (map: Record<string, () => boolean | void>) => () => void;

  /** Function that sets the paused state */
  onTogglePlayback: (boolean) => void;

  /** Function that sets the current frameIndex */
  onSelectFrame: (number) => void;

  router: Router;

  match: Match;

  cursor: string | null | undefined;

  onFrameUpdate?: (number) => void;

  /** Any ReactNode to overlay on the primary file frame */
  renderFrameOverlay?: (props: FrameOverlayProps) => React.ElementType;

  /** Any ReactNode to overlay on the secondary file frame, if there are
   *  secondary frames  */
  renderSecondaryFrameOverlay?: (
    props: FrameOverlayProps,
  ) => React.ElementType;
}

/**
 * Displays PNG images
 */
class CinePlayer extends React.Component<Props> {
  frameInterval: number;

  unregister: (() => void) | null = null;

  nextFrameHandle: NodeJS.Timeout | null = null;

  wheelRafHandle: number | null = null;

  base: string;

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    cineWidth: DEFAULT_CINE_WIDTH,
    cineHeight: DEFAULT_CINE_HEIGHT,
    paused: false,
    frameIndex: 0,
  };

  constructor(props) {
    super(props);
    this.frameInterval = props.api.getFrameInterval(props.file);
    this.base = props.match.location.pathname;
  }

  componentDidMount() {
    const {
      registerShortcut,
      frames,
      frameIndex,
      match: { location },
      onFrameUpdate,
    } = this.props;
    if (location.query.frame) {
      this.pause();
      this.props.onSelectFrame(parseInt(location.query.frame, 10));
    }
    onFrameUpdate?.(frameIndex);

    if (!this.props.paused && frames[frameIndex]) this.scheduleNextFrame();

    if (!registerShortcut) return;

    this.unregister = registerShortcut({
      ' ': () => this.props.onTogglePlayback(!this.props.paused),
      ArrowLeft: () => this.incrementFrameIndex(-1),
      ArrowRight: () => this.incrementFrameIndex(1),
      'Shift+ArrowLeft': () => this.incrementFrameIndex(-10),
      'Shift+ArrowRight': () => this.incrementFrameIndex(10),
    });
  }

  componentDidUpdate(prevProps) {
    const { paused, frames, frameIndex, onFrameUpdate } = this.props;
    if (frameIndex !== prevProps.frameIndex) {
      onFrameUpdate?.(frameIndex);
    }
    const frameIsLoaded = !paused && frames[frameIndex];
    if (paused) {
      // add the frame param if pausing or user changes the frame while paused
      if (!prevProps.paused || prevProps.frameIndex !== frameIndex) {
        window.history.replaceState(
          {},
          '',
          `${this.base}?frame=${frameIndex}`,
        );
      }
      // revert to base URL if beginning to play after paused state
    } else if (prevProps.paused) {
      window.history.replaceState({}, '', this.base);
    }

    if (this.nextFrameHandle && !frameIsLoaded) {
      clearTimeout(this.nextFrameHandle);
      this.nextFrameHandle = null;
    }
    if (!this.nextFrameHandle && frameIsLoaded) {
      this.scheduleNextFrame();
    }
  }

  componentWillUnmount() {
    if (this.unregister) this.unregister();
    if (this.nextFrameHandle) clearTimeout(this.nextFrameHandle);
    if (this.wheelRafHandle) cancelAnimationFrame(this.wheelRafHandle);
  }

  handleWheel = ({ evt }) => {
    this.pause();
    evt.preventDefault();

    if (this.wheelRafHandle) return;
    this.wheelRafHandle = requestAnimationFrame(() => {
      this.wheelRafHandle = null;
      if (evt.deltaX > 0 || evt.deltaY > 0 || evt.deltaZ > 0) {
        this.incrementFrameIndex(1);
      } else if (evt.deltaX < 0 || evt.deltaY < 0 || evt.deltaZ < 0) {
        this.incrementFrameIndex(-1);
      }
    });
  };

  handleFileKeyClick = (event, viewerIsAdmin, imageId) => {
    const { router } = this.props;
    if (viewerIsAdmin && (event.ctrlKey || event.metaKey) && event.shiftKey)
      router.push(`/-/admin/file/${imageId}`);
    else copyToClipBoard(imageId);
  };

  incrementFrameIndex = (delta) => {
    const { frames, secondaryFrames, frameIndex, onSelectFrame } = this.props;

    if (frames.length === 1) return;
    const maxFrames = Math.max(frames.length, secondaryFrames?.length ?? 0);
    let nextFrameIndex = (frameIndex + delta) % maxFrames;
    if (nextFrameIndex < 0) nextFrameIndex += maxFrames;

    onSelectFrame(nextFrameIndex);
  };

  pause = () => {
    if (!this.props.paused) this.props.onTogglePlayback(true);
  };

  nextFrame = () => {
    if (this.props.paused) return;
    const startTime = Date.now();
    this.incrementFrameIndex(1);
    this.scheduleNextFrame(startTime);
  };

  scheduleNextFrame(startTime = Date.now()) {
    this.nextFrameHandle = setTimeout(
      this.nextFrame,
      Math.max(this.frameInterval - (Date.now() - startTime), 0),
    );
  }

  render() {
    const {
      cineHeight,
      cineWidth,
      file,
      secondaryFile,
      frames,
      secondaryFrames,
      frameIndex = 0,
      paused,
      cursor,
      onTogglePlayback,
      onSelectFrame,
      renderFrameScrubber = () => (
        <FrameScrubber
          readOnly
          frameIndex={frameIndex}
          frameCount={Math.max(frames.length, secondaryFrames?.length ?? 0)}
          activeIndex={frameIndex}
          onSelectIndex={onSelectFrame}
          onTogglePlayback={onTogglePlayback}
        />
      ),
      renderFrameOverlay,
      renderSecondaryFrameOverlay,
      renderIndicator,
      renderSecondaryIndicator,
    } = this.props;

    const frame = frames[Math.min(frameIndex, frames.length - 1)];
    const secondaryFrame =
      secondaryFrames &&
      secondaryFrames[Math.min(frameIndex, secondaryFrames.length - 1)];
    const canvasWidth = secondaryFrame ? cineWidth / 2 : cineWidth;

    return (
      <div style={{ backgroundColor: 'black', position: 'relative' }}>
        {frames[frames.length - 1] === undefined && <Loading />}
        {!secondaryFrame && renderIndicator && (
          <div className={styles.indicator}>
            {renderIndicator({
              frameIndex: Math.min(frameIndex, frames.length - 1),
            })}
          </div>
        )}
        <Layout
          direction="row"
          pad={false}
          justify="space-evenly"
          alignContent="space-evenly"
        >
          <div css="position: relative;">
            {secondaryFrame && renderIndicator && (
              <div className={styles.indicator}>
                {renderIndicator({ frameIndex })}
              </div>
            )}
            <DrawingCanvas
              image={frame}
              cursor={cursor}
              width={canvasWidth}
              height={cineHeight}
              onWheel={this.handleWheel}
              onMouseDown={this.pause}
              style={{
                backgroundColor: 'black',
                margin: '0 auto',
              }}
            >
              {renderFrameOverlay && renderFrameOverlay({ frameIndex })}
            </DrawingCanvas>
            {secondaryFile && (
              <IdLabel
                label="Id"
                imageId={file.image_id}
                onClick={this.handleFileKeyClick}
                textAlign="left"
              />
            )}
          </div>

          {secondaryFile && (
            <div css="position: relative;">
              {renderSecondaryIndicator && (
                <div className={styles.indicator}>
                  {renderSecondaryIndicator({
                    frameIndex: Math.min(
                      frameIndex,
                      (secondaryFrames?.length ?? 1) - 1,
                    ),
                  })}
                </div>
              )}
              <DrawingCanvas
                image={secondaryFrame}
                cursor={cursor}
                width={canvasWidth}
                height={cineHeight}
                onWheel={this.handleWheel}
                onMouseDown={this.pause}
                style={{
                  backgroundColor: 'black',
                  margin: '0 auto',
                }}
              >
                {renderSecondaryFrameOverlay &&
                  renderSecondaryFrameOverlay({ frameIndex })}
              </DrawingCanvas>
              {secondaryFile && (
                <IdLabel
                  label="Id"
                  imageId={secondaryFile.image_id}
                  onClick={this.handleFileKeyClick}
                />
              )}
            </div>
          )}
        </Layout>
        {!secondaryFile && (
          <IdLabel imageId={file.image_id} onClick={this.handleFileKeyClick} />
        )}
        {frames.length > 1 && (
          <>
            <CineControls
              paused={paused}
              onIncrementFrameIndex={this.incrementFrameIndex}
              onTogglePaused={onTogglePlayback}
            />
            {renderFrameScrubber({
              frameIndex,
              onTogglePlayback,
              onSelectIndex: onSelectFrame,
            })}
          </>
        )}
      </div>
    );
  }
}

export default uncontrollable(withRouter(withShortcuts(CinePlayer)), {
  frameIndex: 'onSelectFrame',
  paused: 'onTogglePlayback',
});
