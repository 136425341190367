import React from 'react';
import Card from '@bfly/ui/Card';
import Text from '@bfly/ui/Text';

interface Props {
  children: React.ReactNode;
  className?: string;
}

function SummaryCardHeader({ children, className }: Props) {
  return (
    <Card.Header variant="body" color="body" className={className}>
      {children}
    </Card.Header>
  );
}

function SummaryCardBody({ children, className }: Props) {
  return (
    <Card.Body className={className}>
      <Text variant="lg" color="headline">
        {children}
      </Text>
    </Card.Body>
  );
}

function SummaryCard({ children, className }: Props) {
  return <Card className={className}>{children}</Card>;
}

export default Object.assign(SummaryCard, {
  Header: SummaryCardHeader,
  Body: SummaryCardBody,
});
