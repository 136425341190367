import { css } from 'astroturf';
import React from 'react';
import { defineMessages } from 'react-intl';
import DropdownList from '@bfly/ui/DropdownList';

import { Project } from '../models';

const styles = css`
  .dropdown {
    min-width: 30rem;
  }
`;

type Props = {
  value: string | undefined;
  projects: Project[];
  onChange: Function;
};

const dropdown = defineMessages({
  placeholder: {
    id: 'presetProject.placeholder',
    defaultMessage: 'Select a Project',
  },
  empty: {
    id: 'presetProject.empty',
    defaultMessage: 'All',
  },
});

function ProjectFilterDropdown({ value, onChange, projects }: Props) {
  return (
    <DropdownList
      className={styles.dropdown}
      dataKey="name"
      textField="name"
      placeholder={dropdown.placeholder}
      value={value}
      onChange={(v) => onChange(v)}
      data={projects}
      emptyMessage={dropdown.empty}
      allowEmpty
    />
  );
}

export default ProjectFilterDropdown;
