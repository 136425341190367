import React from 'react';
import { Rect } from 'react-konva';
import { useLocalTransform } from '@bfly/annotation-tools';
import { BoundingBox } from '@bfly/annotation-tools/lib/types';
import useShapeTransformer from '@bfly/annotation-tools/lib/useShapeTransformer';
import { toolColor } from '@bfly/annotation-tools/lib/utils/Colors';
import { Point, rotatePoint } from '@bfly/utils/math';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RectData extends BoundingBox {}

interface Props {
  disabled?: boolean;
  data?: RectData;
  color?: string;
  scaleFromCenter?: boolean;
  /**
   * Specifies the triggering mouse button required to create or modify a Rect
   *
   * @default 0
   */
  pointerButton?: number;
  onTrace(trace: RectData | null): void;
}

function RectTool({
  data,
  onTrace,
  disabled,
  pointerButton,
  scaleFromCenter = false,
  color = toolColor,
}: Props) {
  const transform = useLocalTransform();

  const [transformer, boundingBox] = useShapeTransformer({
    disabled,
    pointerButton,
    scaleFromCenter,
    boundingBox: data,
    onTransform: onTrace,
  });

  if (!boundingBox) return null;

  const { height, width, rotation, center } = boundingBox;

  const [cx, cy] = center;
  const corner: Point = [cx - width / 2, cy - height / 2];
  const [dx, dy] = rotatePoint(corner, rotation, center);

  return (
    <>
      <Rect
        x={dx}
        y={dy}
        opacity={1}
        stroke={color}
        strokeWidth={transform.unscale(2)}
        rotation={rotation}
        width={width}
        height={height}
      />
      {transformer}
    </>
  );
}

export default RectTool;
