/* eslint-disable react/no-array-index-key */
import Form from '@bfly/ui/Form';
import PropTypes from 'prop-types';
import React from 'react';

import Api from '../Api';
import AnnotatableCine from './AnnotatableCine';
import { withShortcuts } from './KeyboardShortcutManager';
import TraceableOverlay from './TraceableOverlay';
import TracesOverlay from './TracesOverlay';

function getCursorStyle(color) {
  const cursorCanvas = document.createElement('canvas');
  cursorCanvas.width = 17;
  cursorCanvas.height = 17;

  const ctx = cursorCanvas.getContext('2d');

  ctx.fillStyle = 'white';
  ctx.fillRect(0, 7, 17, 3);
  ctx.fillRect(7, 0, 3, 17);

  ctx.fillStyle = color;
  ctx.fillRect(1, 8, 15, 1);
  ctx.fillRect(8, 1, 1, 15);

  return `url(${cursorCanvas.toDataURL()}) 8 8, auto`;
}

function getFirstAnnotatedFrame(results) {
  if (results) {
    for (const trace of Object.values(results)) {
      if (trace && trace[0] != null) return trace[0][0];
    }
  }
  return 0;
}

const propTypes = {
  file: PropTypes.object.isRequired,
  frames: PropTypes.arrayOf(PropTypes.instanceOf(Image)).isRequired,
  compFile: PropTypes.object,
  compFrames: PropTypes.arrayOf(PropTypes.instanceOf(Image)),
  api: PropTypes.instanceOf(Api).isRequired,
  intervalAnnotations: PropTypes.object,
  traceAnnotations: PropTypes.object,
  onSelectFrame: PropTypes.func,
  enabledFrames: PropTypes.arrayOf(PropTypes.number),
  dlResultOutputs: PropTypes.object,
};

const defaultProps = {
  onSelectFrame: () => {},
};

class TraceableCine extends React.Component {
  constructor(props) {
    super(props);
    const { traceAnnotations } = props;

    this.state = {
      frameIndex: getFirstAnnotatedFrame(traceAnnotations),
      activeTrace: null,
      paused: !!props.readOnly,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.activeTrace === prevState.activeTrace) return null;

    const { task, activeTrace } = nextProps;
    const active = task.traces.find((t) => t.traceId === activeTrace);

    return {
      activeTrace,
      cursorStyle: getCursorStyle(active?.color || 'red'),
    };
  }

  handleSelectFrame = (frameIndex) => {
    const { onSelectFrame } = this.props;

    this.setState({ frameIndex });
    onSelectFrame(frameIndex);
  };

  handleTogglePlayback = (paused) => {
    this.setState({ paused });
  };

  /**
   * Draws a new trace on the cine while ensuring that the configured amounts are maintained.
   *
   * Configuration includes: numFrames, maxPerFrame and enabledFrames.
   *
   * Num Frames - The number of frames the trace can be performed on for a given cine
   * Max Per Frame - The maximum number of times a given trace can be drawn on a single frame
   * Enabled Frames - A whitelist of of frame indices that are the only traceable frames for the given cine
   *
   * @param trace
   * @param value
   * @param idx
   */
  handleTrace = (trace, value, idx) => {
    const { traceId, numFrames, maxPerFrame } = trace;
    const { traceAnnotations, onTrace, enabledFrames } = this.props;
    const { frameIndex } = this.state;

    /*
     * Steps to ensure that the right amount of traces:
     * 1. Accumulate traces by frame index
     * 2. Check to see if maxPerFrame is maintained. If not remove by FIFO
     * 3. Check to see if numFrames is maintained. If not remove by FIFO.
     *  - enabled frames ignores numFrames configuration.
     */

    //  1. Accumulate traces by frame index
    const resultsByFrame = new Map();
    traceAnnotations[traceId].forEach((result) => {
      const frame = result[0];
      if (!resultsByFrame.get(frame)) {
        resultsByFrame.set(frame, []);
      }

      resultsByFrame.set(frame, [...resultsByFrame.get(frame), result]);
    });

    resultsByFrame.set(frameIndex, resultsByFrame.get(frameIndex) ?? []);

    const frameResults = resultsByFrame.get(frameIndex);

    if (value === null) {
      // Delete Active Trace button sends us null.
      frameResults.splice(frameResults.length - 1, 1);
    } else {
      frameResults[idx ?? frameResults.length] = [frameIndex, value];
    }

    // 2. Check to see if maxPerFrame is maintained
    if (frameResults.length > maxPerFrame) {
      while (frameResults.length > maxPerFrame) {
        frameResults.shift();
      }
    }

    // 3. Check to see if numFrames is maintained

    // If numFrames is 0 then ignore removal (aka unlimited frames)
    if (numFrames && !enabledFrames.length) {
      const otherTracedFrames = [...resultsByFrame.keys()].filter(
        (otherFrameIndex) => otherFrameIndex !== frameIndex,
      );

      if (resultsByFrame.size > numFrames) {
        for (let i = 0; i < resultsByFrame.size - numFrames; i++) {
          resultsByFrame.delete(otherTracedFrames[i]);
        }
      }
    }

    const traceResults = [...resultsByFrame.values()].flat();

    onTrace({ ...traceAnnotations, [traceId]: traceResults });
  };

  handleClearFrame = () => {
    const { traceAnnotations, onTrace } = this.props;
    const { frameIndex } = this.state;

    const traces = {};
    const keys = Object.keys(traceAnnotations);

    for (const key of keys) {
      traces[key] = traceAnnotations[key]
        ? traceAnnotations[key].filter(([idx]) => idx !== frameIndex)
        : [];
    }

    onTrace(traces);
  };

  getFrameResults() {
    const results = {};
    this.props.task.traces.forEach((trace) => {
      results[trace.traceId] = this.getFrameValue(trace);
    });
    return results;
  }

  getFrameValue(trace) {
    const { traceAnnotations } = this.props;

    const values = traceAnnotations[trace.traceId];
    if (!values || !values.length) {
      return [];
    }

    const { frameIndex } = this.state;

    if (trace.interpolate && trace.maxPerFrame <= 1) {
      return values
        .map((value) => this.interpolate(trace, values, value))
        .filter(Boolean);
    }

    return values
      .filter(([otherFrameIndex]) => otherFrameIndex === frameIndex)
      .map(([_, value]) => value);
  }

  interpolate(trace, values, value) {
    const { frameIndex } = this.state;

    const [nextFrameIndex, nextPoints] = value;
    if (nextFrameIndex === frameIndex) {
      return nextPoints;
    }

    if (!nextPoints) {
      return undefined;
    }

    const nextValueIndex = values.findIndex(
      ([otherFrameIndex]) => otherFrameIndex >= frameIndex,
    );

    const prevValue = values[nextValueIndex - 1];
    if (!prevValue) {
      return undefined;
    }

    const [prevFrameIndex, prevPoints] = prevValue;
    if (!prevPoints) {
      return undefined;
    }

    const prevFrameDelta = frameIndex - prevFrameIndex;
    const totalFrameDelta = nextFrameIndex - prevFrameIndex;
    const nextFrameWeight = prevFrameDelta / totalFrameDelta;
    const prevFrameWeight = 1 - nextFrameWeight;

    const points = prevPoints.map((prevPoint, i) => {
      const nextPoint = nextPoints[i];
      return [
        prevFrameWeight * prevPoint[0] + nextFrameWeight * nextPoint[0],
        prevFrameWeight * prevPoint[1] + nextFrameWeight * nextPoint[1],
      ];
    });

    points.interpolated = true;

    return points;
  }

  render() {
    const {
      task,
      frames,
      activeTrace,
      intervalAnnotations,
      traceAnnotations,
      registerShortcut,
      enabledFrames,
      file,
      compFrames,
      compFile,
      api,
      dlResultOutputs,
      readOnly,
      ...props
    } = this.props;
    const { frameIndex, paused, cursorStyle } = this.state;
    const { traces } = task;
    const active = task.traces.find((t) => t.traceId === activeTrace);
    const frameResults = this.getFrameResults();
    const frame = frames[frameIndex];

    const hasEnabledFrames = enabledFrames && enabledFrames.length;
    const disabled =
      props.readOnly ||
      (hasEnabledFrames && !enabledFrames.includes(frameIndex));

    return (
      <Form.Field name="activeInterval">
        {({ value, onChange }) => (
          <AnnotatableCine
            api={api}
            file={file}
            frames={frames}
            compFile={compFile}
            compFrames={compFrames}
            task={task}
            paused={paused}
            frameIndex={frameIndex}
            onSelectFrame={this.handleSelectFrame}
            onTogglePlayback={this.handleTogglePlayback}
            cursor={activeTrace ? cursorStyle : 'not-allowed'}
            enabledFrames={enabledFrames}
            intervalAnnotations={intervalAnnotations}
            traceAnnotations={traceAnnotations}
            selectedInterval={value}
            onSelectInterval={onChange}
            dlResultOutputs={dlResultOutputs}
            readOnly={readOnly || !intervalAnnotations}
            traceOverlay={
              !!frame && (
                <>
                  {traces.map((trace) => (
                    <TracesOverlay
                      key={trace.traceId}
                      trace={trace}
                      onTrace={this.handleTrace}
                      results={frameResults[trace.traceId]}
                      onClearFrame={this.handleClearFrame}
                      disabled={disabled}
                    />
                  ))}
                  {active && (
                    <TraceableOverlay
                      key={active.traceId}
                      trace={active}
                      onClearFrame={this.handleClearFrame}
                      disabled={disabled}
                      onTrace={this.handleTrace}
                      registerShortcut={registerShortcut}
                    />
                  )}
                </>
              )
            }
          />
        )}
      </Form.Field>
    );
  }
}

TraceableCine.propTypes = propTypes;
TraceableCine.defaultProps = defaultProps;

export default withShortcuts(TraceableCine);
