/* The TaskDefinitionPage provides a wizard for creating and editing tasks. */

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import MainContent from '@bfly/ui/MainContent';

import TaskDefinitionForm from 'components/TaskDefinitionForm';

const propTypes = {
  data: PropTypes.object.isRequired,
};

async function getData({ params, context }) {
  const { taskId } = params;
  const { api } = context;

  return {
    task: await api.getLatestTask(taskId),
  };
}

function TaskDefinitionPage({ data }) {
  const { task } = data;

  useEffect(() => {
    document.title = task
      ? `Task - ${task.name} - Butterfly`
      : `New Task - Butterfly`;
  });

  return (
    <MainContent size="medium">
      <TaskDefinitionForm
        task={task}
        successMessage={
          <FormattedMessage
            id="tasks.update.success"
            defaultMessage="Task updated successfully"
          />
        }
      />
    </MainContent>
  );
}

TaskDefinitionPage.propTypes = propTypes;
TaskDefinitionPage.getData = getData;

export default TaskDefinitionPage;
