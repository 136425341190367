import { useCallback, useEffect } from 'react';
import { useCommittedRef } from '@restart/hooks';

export default function useRafInterval(callback, delay) {
  const handle = 0;
  const start = new Date().getTime();

  const savedCallback = useCommittedRef(callback);
  const handleRef = useCommittedRef(handle);
  const startRef = useCommittedRef(start);

  const loop = useCallback(() => {
    const current = new Date().getTime();
    const delta = current - startRef.current;

    if (delay === null) return;

    if (delta >= delay * 5 && savedCallback.current) {
      savedCallback.current();
      startRef.current = new Date().getTime();
    }

    cancelAnimationFrame(handleRef.current);
    handleRef.current = requestAnimationFrame(loop);
  }, [delay, handleRef, savedCallback, startRef]);

  useEffect(() => {
    handleRef.current = requestAnimationFrame(loop);
    return () => cancelAnimationFrame(handleRef.current);
  }, [handleRef, loop]);
}
