import { File } from '../models';
import {
  Line,
  LineOverlay,
  ProjectOverlays,
  projectLineOverlaySchema,
  projectOverlaysSchema,
} from '../schema/ProjectOverlays';
import { getPhysicalDeltas, getRibSpaceSectorCoords } from './calculations';

const PROJECTS = {
  LUNG_B_LINE_COUNTING: 'LUNG_B_LINE_COUNTING',
};

function makeProjectLineOverlay(
  lines: Line[],
  key: string,
  color: string,
): LineOverlay {
  return projectLineOverlaySchema.cast({
    key,
    color,
    lines,
  });
}

async function getRibSpaceSectorOverlay(file: File): Promise<LineOverlay> {
  const [imageHeight, imageWidth] = [
    file.file_metadata.Rows,
    file.file_metadata.Columns,
  ];
  const physicalDeltas = getPhysicalDeltas(file);

  return makeProjectLineOverlay(
    [
      await getRibSpaceSectorCoords(
        [imageHeight, imageWidth],
        physicalDeltas?.y,
      ),
    ],
    'rib_space_sector',
    'red',
  );
}

export async function getProjectOverlays(
  project: string,
  file: File,
): Promise<ProjectOverlays> {
  /**
   * Get cine overlays for a project.
   *
   * @param: project - A project name.
   * @param: file - An image file.
   * @returns: Any overlay coordinates to display on the cine for the project.
   */
  const projectLineOverlays: LineOverlay[] = [];

  if (project === PROJECTS.LUNG_B_LINE_COUNTING) {
    projectLineOverlays.push(await getRibSpaceSectorOverlay(file));
  }

  return projectOverlaysSchema.cast({
    projectLineOverlays,
  });
}
