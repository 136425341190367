import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import ProgressBar from 'react-bootstrap/ProgressBar';
import BlankSlate from '@bfly/ui/BlankSlate';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';

import AppPage from './AppPage';
import NavLink from './NavLink';
import Table from './Table';

const propTypes = {
  data: PropTypes.object.isRequired,
};

async function getData({ params, context }) {
  const { username } = params;
  const { api } = context;

  const [viewer, userWorklists] = await Promise.all([
    AppPage.getData({ context }),
    api.getWorklistsForUser({
      username,
      completed: true,
      sort: '-last_completed',
    }),
  ]);

  return {
    viewer,
    username,
    userWorklists,
  };
}

function CompletedWorklistsPage({ data, match: { params } }) {
  const { userWorklists, viewer } = data;

  const hasData = !isEmpty(userWorklists);

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>
          {params.username === viewer.username
            ? 'My Completed Assignments'
            : `Completed Assignments for ${params.username}`}
        </Header.Title>
      </Header>
      <MainContent size="medium">
        <Nav variant="tabs">
          <Nav.Item>
            <NavLink exact to={`/${params.username}`}>
              In Progress
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/${params.username}/completed`}>Completed</NavLink>
          </Nav.Item>
        </Nav>
        {hasData ? (
          <Table>
            <thead>
              <tr>
                <th>Tasks</th>
                <th css="text-align: center;">Progress</th>
                <th css="text-align: center;">Deadline</th>
                <th>Completed At</th>
              </tr>
            </thead>
            <tbody>
              {userWorklists.map(
                ({
                  id,
                  name,
                  deadline,
                  num_assignments: numAssignments,
                  num_completed_assignments: numCompleted,
                  last_completed: lastCompleted,
                }) => (
                  <tr key={id}>
                    <td>{name}</td>
                    <td css="text-align: center;">
                      <ProgressBar
                        now={numCompleted}
                        max={numAssignments}
                        variant="success"
                      />
                    </td>
                    <td css="text-align: center;">
                      {deadline
                        ? new Date(deadline).toLocaleDateString({
                            date: 'medium',
                          })
                        : 'No Deadline'}
                    </td>
                    <td>
                      {new Date(lastCompleted).toLocaleDateString({
                        date: 'medium',
                      })}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </Table>
        ) : (
          <BlankSlate>
            <BlankSlate.Title>There Are No Completed Tasks</BlankSlate.Title>
          </BlankSlate>
        )}
      </MainContent>
    </AppPage>
  );
}

CompletedWorklistsPage.getData = getData;
CompletedWorklistsPage.propTypes = propTypes;

export default CompletedWorklistsPage;
