/* eslint-disable react/style-prop-object */
import React from 'react';
import { FormattedNumber } from 'react-intl';

interface Props {
  value: number;
}

function FormattedCurrency({ value }: Props) {
  return <FormattedNumber value={value} style="currency" currency="USD" />;
}

export default FormattedCurrency;
