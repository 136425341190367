import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '@bfly/ui/Spinner';

import useFetchHandler from '../utils/useFetchHandler';

/**
 * Represents a value displayed to the user that is realized via a Promise.
 */
function DeferredValue({ promise }: { promise: Promise<any> }) {
  const { data, loading, error } = useFetchHandler(() => promise, [promise]);

  if (!promise) {
    return null;
  }

  if (loading) {
    return <Spinner style={{ height: 14 }} />;
  }

  if (data && !error) {
    return data;
  }

  return null;
}

DeferredValue.propTypes = {
  promise: PropTypes.object.isRequired,
};

export default DeferredValue;
