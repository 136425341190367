import HttpError from 'found/lib/HttpError';
import Route from 'found/lib/Route';
import React from 'react';

import FileViewerPage from '../pages/FileViewerPage';

function throw403ForNonInternal({ context }) {
  if (!context.api.viewerIsInternal) throw new HttpError(403);
}

export default (
  <Route getData={throw403ForNonInternal}>
    <Route
      path="file/:imageId"
      Component={FileViewerPage}
      getData={FileViewerPage.getData}
    />
  </Route>
);
