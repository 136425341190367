import { WorklistUploadRow } from 'src/models/Worklist';

const validationErrorsMessages = {
  invalidFieldsValues:
    'Fields username, name and task name should be equal in the csv',
  hasNotAssignmentId: 'All the worklist should have the assignment id',
};

const areValuesValid = (curr: WorklistUploadRow, firstRowValues) => {
  const { task_name: taskName, name: worklistName } = firstRowValues;
  const fieldsConstraints =
    curr.task_name === taskName && curr.name === worklistName;
  return fieldsConstraints;
};

const hasOneAssignmentId = (curr: WorklistUploadRow) => {
  const check =
    curr.image_id.length > 0 ||
    curr.image_cloud_url.length > 0 ||
    curr.dl_result_id.length > 0 ||
    curr.review_assignment_id.length > 0;
  return check;
};

interface ValidationState {
  invalidFieldsValues: boolean;
  hasNotAssignmentId: boolean;
}

const checkValidationState = (validationState: ValidationState) => {
  let errorMessage = '';
  Object.entries(validationState).forEach(([state, isInvalid]) => {
    if (isInvalid) {
      errorMessage = validationErrorsMessages[state];
    }
  });
  return errorMessage;
};

/**
 * Format the worklist object removing empty properties
 * Reduce the payload size
 * @param Array unformatted worklists
 * @returns Array of worklists
 */
const formatAssignmentUploads = (assignments: WorklistUploadRow[]) =>
  assignments.map((assignment) =>
    Object.fromEntries(
      Object.entries(assignment).filter(([_, v]) => {
        if (typeof v === 'string') {
          return v.length > 0;
        }
        return v;
      }),
    ),
  );

export {
  validationErrorsMessages,
  areValuesValid,
  hasOneAssignmentId,
  checkValidationState,
  formatAssignmentUploads,
};
