import { init } from '@sentry/browser';

const dsn = window.bflyConfig.SENTRY_DSN;

if (dsn) {
  init({
    dsn,
    release: process.env.VERSION,
  });
}
