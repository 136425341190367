import { DependencyList, useEffect, useState } from 'react';

export interface FetchResult<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
}
const useFetchHandler = <T>(
  thenable: () => Promise<T>,
  deps: DependencyList,
): FetchResult<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const controller = { fresh: true };

    setLoading(true);

    Promise.resolve(thenable())
      .then((result) => {
        if (!controller.fresh) return null;

        return setData(result);
      })
      .catch((err) => {
        if (!controller.fresh) return null;

        return setError(err);
      })
      .finally(() => {
        if (!controller.fresh) return null;

        return setLoading(false);
      });

    return () => {
      controller.fresh = false;
    };
    // eslint-disable-next-line
  }, deps);

  return { data, loading, error };
};

export default useFetchHandler;
