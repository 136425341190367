import Link from 'found/lib/Link';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import ProgressBar from 'react-bootstrap/ProgressBar';
import BlankSlate from '@bfly/ui/BlankSlate';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';

import AppPage from './AppPage';
import NavLink from './NavLink';
import Table from './Table';

const propTypes = {
  data: PropTypes.object.isRequired,
};

async function getData({ params, context }) {
  const { username } = params;
  const { api } = context;

  const [viewer, userWorklists] = await Promise.all([
    AppPage.getData({ context }),
    api.getWorklistsForUser({
      username,
      completed: false,
    }),
  ]);

  return {
    viewer,
    username,
    userWorklists,
  };
}

function HomePage({ data, match: { params } }) {
  const { username, userWorklists, viewer } = data;

  const hasData = !isEmpty(userWorklists);

  useEffect(() => {
    document.title =
      viewer.username === username
        ? `Butterfly Annotation Cloud`
        : `Home - ${username} - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>
          {params.username === viewer.username
            ? 'My Assignments'
            : `Assignments for ${params.username}`}
        </Header.Title>
      </Header>
      <MainContent size="medium">
        <Nav variant="tabs">
          <Nav.Item>
            <NavLink exact to={`/${params.username}`}>
              In Progress
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={`/${params.username}/completed`}>Completed</NavLink>
          </Nav.Item>
        </Nav>
        {hasData ? (
          <Table>
            <thead>
              <tr>
                <th>Tasks</th>
                <th css="text-align: center;">Progress</th>
                <th css="text-align: center;">Completed</th>
                <th>Deadline</th>
              </tr>
            </thead>
            <tbody>
              {userWorklists.map(
                ({
                  id,
                  name,
                  deadline,
                  num_assignments: numAssignments,
                  num_completed_assignments: numCompleted,
                }) => (
                  <tr key={id}>
                    <td>
                      <Link to={`/${username}/worklists/${id}`}>{name}</Link>
                    </td>
                    <td css="text-align: center;">
                      <ProgressBar now={numCompleted} max={numAssignments} />
                    </td>
                    <td css="text-align: center;">
                      {numCompleted} / {numAssignments}
                    </td>
                    <td>
                      {deadline
                        ? new Date(deadline).toLocaleDateString({
                            date: 'medium',
                          })
                        : 'No Deadline'}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </Table>
        ) : (
          <BlankSlate>
            <BlankSlate.Title>There Are No Pending Tasks</BlankSlate.Title>
          </BlankSlate>
        )}
      </MainContent>
    </AppPage>
  );
}

HomePage.getData = getData;
HomePage.propTypes = propTypes;

export default HomePage;
