import { css } from 'astroturf';
import React, { useState } from 'react';
import NestedForm from 'react-formal/lib/NestedForm';
import Card from '@bfly/ui/Card';
import Form from '@bfly/ui/Form';
import FormCheck from '@bfly/ui/FormCheck';

import { Skip } from '../schema/AnnotationTask';
import { QuestionForm } from './QuestionsForm';

const styles = css`
  @import '~@bfly/ui/styles/theme';

  .card {
    &,
    &:hover,
    &:active {
      border-color: $grey-80;
    }
  }
`;

type Props = {
  name?: string;
  defaultValue?: {
    displayText: string;
    question: object;
  };
};

function SkipForm({
  name = 'definition.skip',
  defaultValue,
  ...props
}: Props) {
  const [open, setOpen] = useState(!!defaultValue);

  const toggle = (onChange) => {
    setOpen(!open);
    onChange(open ? {} : defaultValue);
  };

  return (
    <Form.Field name={name}>
      {({ onChange }) => (
        <>
          <FormCheck
            theme="light"
            checked={open}
            onChange={() => toggle(onChange)}
          >
            Skippable
          </FormCheck>

          {open && (
            <Form.FieldSet legend="Skip">
              <NestedForm
                {...props}
                defaultValue={defaultValue}
                as="div"
                name={name}
                schema={Skip}
              >
                <Form.HorizontalFieldGroup
                  name="displayText"
                  label="Skip Title"
                />

                <Card className={styles.card}>
                  <Card.Title>Skip Question</Card.Title>
                  <Card.Body>
                    <NestedForm as="div" name="question">
                      <QuestionForm defaultRequiredValue />
                    </NestedForm>
                  </Card.Body>
                </Card>
              </NestedForm>
            </Form.FieldSet>
          )}
        </>
      )}
    </Form.Field>
  );
}

export default SkipForm;
