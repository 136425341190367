import React from 'react';
import Form from '@bfly/ui/Form';
import AnnotatableCine from './AnnotatableCine';

const propTypes = {};

function IntervalsCine(props) {
  const {
    api,
    enabledFrames,
    file,
    frames,
    compFile,
    compFrames,
    onSelectFrame,
    readOnly,
    intervalAnnotations,
    task,
    dlResultOutputs,
  } = props;

  return (
    <Form.Field name="activeInterval">
      {({ value, onChange }) => (
        <AnnotatableCine
          api={api}
          file={file}
          frames={frames}
          compFile={compFile}
          compFrames={compFrames}
          task={task}
          onSelectFrame={onSelectFrame}
          defaultPaused={!!readOnly}
          readOnly={readOnly}
          enabledFrames={enabledFrames}
          dlResultOutputs={dlResultOutputs}
          selectedInterval={value}
          onSelectInterval={onChange}
          intervalAnnotations={intervalAnnotations}
        />
      )}
    </Form.Field>
  );
}

IntervalsCine.propTypes = propTypes;

export default IntervalsCine;
