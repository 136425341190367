import { Data } from 'plotly.js';
/* eslint-disable @typescript-eslint/camelcase */
import React, { useMemo } from 'react';

import { Earning } from '../schema/Earning';
import Plot from './Plot';

const layout = {
  height: 400,
  xaxis: {
    title: 'Date',
  },
  yaxis: {
    title: 'Cost',
  },
  plot_bgcolor: '#262626',
  paper_bgcolor: '#262626',
};

interface Props {
  expenses: Earning[];
}

function ExpensesPlot({ expenses }: Props) {
  const plotData: Data[] = useMemo(
    () => [
      {
        x: expenses.map((d) => d.endDate),
        y: expenses.map((d) => d.totalAmount),
        type: 'scatter',
      },
    ],
    [expenses],
  );

  return <Plot data={plotData} layout={layout} />;
}

export default ExpensesPlot;
