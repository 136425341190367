import { bool, number, object, string } from 'yup';

export enum Grade {
  Onboarding = 'onboarding',
  Incapable = 'incapable',
  Capable = 'capable',
  Expert = 'expert',
}

const schema = object({
  contractId: string(),
  username: string().required(),
  task: string().required(),
  rate: number().nullable().default(0),
  assignmentEnabled: bool().nullable(),
  grade: string().oneOf(Object.values(Grade)).default('incapable'),
}).camelCase();

export const serialize = (value) =>
  object({
    rate: number().transform((v) => v || 0),
  })
    .snakeCase()
    .cast(value);

export default schema;
