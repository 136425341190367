import React from 'react';
import { Circle, Text } from 'react-konva';

import { CoordinateOutputs, OPACITY } from '../schema/DlResultOutputs';

const LABEL_HEIGHT = 50;
const LABEL_WIDTH = 100;
const LABEL_OFFSET = 10;

interface Props {
  coordinateOutputs: CoordinateOutputs[];
  frameIndex: number;
  smoothing?: boolean;
}

function getLabelCoordinate(pointCoordinate) {
  /**
   * Calculate the coordinates for the named_coordinates label of the
   * corresponding named_coordinates point.
   *
   * The named_coordinates label is displayed above the point by default.
   * However, the label display is appropriately adjusted to below the point
   * if the point is positioned high enough within the canvas.
   *
   * @param pointCoordinate - The coordinates of a named_coordinates
   *    point
   * @returns: The coordinates of the corresponding named_coordinates
   *    label
   */

  const [pointX, pointY] = pointCoordinate;
  const labelX = pointX - LABEL_WIDTH / 2;

  let labelY;
  if (pointY - LABEL_OFFSET - LABEL_HEIGHT < 0) {
    labelY = pointY + LABEL_OFFSET;
  } else {
    labelY = pointY - LABEL_OFFSET - LABEL_HEIGHT;
  }

  return { labelX, labelY };
}

function CoordinateOutputsOverlay({
  coordinateOutputs,
  frameIndex,
  smoothing,
}: Props) {
  return (
    <>
      {coordinateOutputs.flatMap((output) => {
        const framePoints =
          smoothing && output.smoothedPoints
            ? output.smoothedPoints[frameIndex]
            : output.points[frameIndex];
        return framePoints.flatMap((point, index) => {
          const { labelX, labelY } = getLabelCoordinate(point);

          const pointOpacity = point[0] > 0 ? 1.0 : 0.0;
          const pointSmoothedOpacity =
            point.length < 3 ? pointOpacity : point[2];

          return [
            <Circle
              /* eslint-disable-next-line react/no-array-index-key */
              key={`${output.key}_${index}_point`}
              x={point[0]}
              y={point[1]}
              radius={5}
              fill={output.color}
              stroke={output.color}
              opacity={OPACITY * pointSmoothedOpacity}
            />,
            <Text
              /* eslint-disable-next-line react/no-array-index-key */
              key={`${output.key}_${index}_label`}
              x={labelX}
              y={labelY}
              width={LABEL_WIDTH}
              height={LABEL_HEIGHT}
              align="center"
              verticalAlign={
                point[1] - LABEL_OFFSET - LABEL_HEIGHT < 0 ? 'top' : 'bottom'
              }
              text={output.label}
              fill={output.color}
              opacity={pointSmoothedOpacity}
            />,
          ];
        });
      })}
    </>
  );
}

export default CoordinateOutputsOverlay;
