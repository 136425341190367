import LoadingIndicator from '@bfly/ui/LoadingIndicator';
import { Auth0DecodedHash } from 'auth0-js';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';

import Api from '../Api';
import Auth from '../Auth';

interface AuthContextValue {
  login: (params?: object) => void;
  logout: () => void;
  acceptToken: () => Promise<Auth0DecodedHash | null>;
  isAuthenticated: () => boolean;
}

const AuthContext = React.createContext<AuthContextValue>(null as any);
const ApiContext = React.createContext<Api>(null as any);

export const useAuth = () => useContext(AuthContext);
export const useApi = () => useContext(ApiContext);

const propTypes = {
  children: PropTypes.func.isRequired,
};

export const auth = new Auth();

function AuthProvider({ children }) {
  const [api, setApi] = useState<Api>(null as any);
  const [isLoading, setIsLoading] = useState(true);

  const initializeApi = async (token?) => {
    const initializedApi = await new Api(token).resolveViewerIsAdmin();
    setApi(initializedApi);
    setIsLoading(false);
  };

  useEffect(() => {
    initializeApi(auth.authResponse?.accessToken);
  }, []);

  const { current: authContext } = useRef({
    login: auth.login.bind(auth),
    logout() {
      auth.logout();
    },
    async acceptToken() {
      const result = await auth.acceptToken();
      if (result) await initializeApi(result.accessToken);
      return result;
    },
    isAuthenticated: auth.isAuthenticated.bind(auth),
  });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <ApiContext.Provider value={api}>
      <AuthContext.Provider value={authContext}>
        {children(api, authContext)}
      </AuthContext.Provider>
    </ApiContext.Provider>
  );
}

AuthProvider.propTypes = propTypes;

export default AuthProvider;
