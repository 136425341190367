import { InferType, array, boolean, mixed, number, object, string } from 'yup';
import { decodeContours } from '../utils/Decompression';
import { movingPointAverageWithAlpha } from '../utils/smoothing';

const DEFAULT_COLOR = 'blue';
export const OPACITY = 0.3;

export const OUTPUT_TYPES = {
  BOOLEAN: 'bool',
  CENTROID: 'centroid',
  FLOAT: 'float',
  INTEGER: 'integer',
  NAMED_COORDINATES: 'named_coordinates',
  QUALITY: 'quality',
  RAGGED_CONFLUENT_LINES: 'ragged_confluent_lines',
  RAGGED_LINES: 'ragged_lines',
  RAGGED_NAMED_COORDINATES: 'ragged_named_coordinates',
  SEGMENTATIONS: 'segmentations',
};

const isNumericOutputType = (output): boolean =>
  output.output_type === OUTPUT_TYPES.INTEGER ||
  output.output_type === OUTPUT_TYPES.FLOAT;

const isLineOutputType = (output): boolean =>
  output.output_type === OUTPUT_TYPES.RAGGED_LINES ||
  output.output_type === OUTPUT_TYPES.RAGGED_CONFLUENT_LINES;

const isCoordinateOutputType = (output): boolean =>
  output.output_type === OUTPUT_TYPES.CENTROID ||
  output.output_type === OUTPUT_TYPES.NAMED_COORDINATES ||
  output.output_type === OUTPUT_TYPES.RAGGED_NAMED_COORDINATES;

type FETCH_TYPE = 'FRAME' | 'CINE';
type FILE_TYPE = 'b_mode' | 'transverse_b_mode';

const frameNumericOutputSchema = object({
  key: mixed().required(),
  value: array().of(number()),
  label: string(),
}).camelCase();

const cineNumericOutputSchema = object({
  key: mixed().required(),
  value: number(),
  label: string(),
}).camelCase();

const frameBooleanOutputSchema = object({
  key: mixed().required(),
  value: array().of(boolean()),
  label: string(),
}).camelCase();

const cineBooleanOutputSchema = object({
  key: mixed().required(),
  value: boolean(),
  label: string(),
}).camelCase();

const frameDataOutputSchema = object({
  key: mixed().required(),
  value: array().of(mixed()),
  label: string(),
}).camelCase();

const cineDataOutputSchema = object({
  key: mixed().required(),
  value: mixed(),
  label: string(),
}).camelCase();

const lineComponentOutputSchema = object({
  key: string().required(),
  label: string(),
  color: string().required(),
  points: array().of(array().of(array().of(number()))),
  type: string(),
}).camelCase();

const coordinateOutputSchema = object({
  key: string().required(),
  label: string().required(),
  color: string().required(),
  points: array().of(array().of(array().of(number()))),
  smoothedPoints: array().of(array().of(array().of(number()))),
}).camelCase();

const dlResultOutputSchema = object({
  frameDataOutputs: array().of(frameDataOutputSchema),
  cineDataOutputs: array().of(cineDataOutputSchema),
  qualities: array().of(number()).required(),
  lineComponentOutputs: array().of(lineComponentOutputSchema),
  coordinateOutputs: array().of(coordinateOutputSchema),
}).camelCase();

type CineNumericOutputs = InferType<typeof cineNumericOutputSchema>;
type FrameNumericOutputs = InferType<typeof frameNumericOutputSchema>;
type CineBooleanOutputs = InferType<typeof cineBooleanOutputSchema>;
type FrameBooleanOutputs = InferType<typeof frameBooleanOutputSchema>;
export type LineComponentOutputs = InferType<typeof lineComponentOutputSchema>;
export type CoordinateOutputs = InferType<typeof coordinateOutputSchema>;
export type DlResultOutputs = InferType<typeof dlResultOutputSchema>;

export const deserialize = (dlResult, fileType?: FILE_TYPE) => {
  if (!dlResult) return null;

  const getFetchInfo = (fetchType: FETCH_TYPE) => {
    let interpSpec, interpretation, specField, interpretationField;
    if (fetchType === 'FRAME') {
      specField = 'frame_interp_spec';
      interpretationField = 'frame_interpretation';
    } else {
      specField = 'cine_interp_spec';
      interpretationField = 'cine_interpretation';
    }

    if (!fileType) {
      interpSpec = dlResult.results[specField];
      interpretation = dlResult.results[interpretationField];
    } else {
      interpSpec = dlResult.results[fileType][specField];
      interpretation = dlResult.results[fileType][interpretationField];
    }

    return { interpSpec, interpretation };
  };

  const parseLineOutputs = (
    fetchType: FETCH_TYPE,
  ): LineComponentOutputs[] | undefined => {
    const { interpSpec, interpretation } = getFetchInfo(fetchType);

    return interpSpec?.outputs.filter(isLineOutputType).map((output) => ({
      label: output.label || output.op_name,
      key: output.op_name,
      points: interpretation[output.op_name],
      color: output.color ? `#${output.color}` : DEFAULT_COLOR,
      type: output.output_type,
    }));
  };

  const parseCoordinateOutputs = (
    fetchType: FETCH_TYPE,
  ): CoordinateOutputs[] | undefined => {
    const { interpSpec, interpretation } = getFetchInfo(fetchType);

    return interpSpec?.outputs
      .filter(isCoordinateOutputType)
      .map((output) => ({
        label:
          output.output_type === OUTPUT_TYPES.CENTROID
            ? undefined
            : output.label,
        key: output.op_name,
        points:
          output.output_type === OUTPUT_TYPES.RAGGED_NAMED_COORDINATES
            ? interpretation[output.op_name]
            : interpretation[output.op_name].map((point) => [point]),
        smoothedPoints:
          output.output_type === OUTPUT_TYPES.RAGGED_NAMED_COORDINATES
            ? undefined
            : movingPointAverageWithAlpha(
                interpretation[output.op_name],
                3,
              ).map((pointWithAlpha) => [pointWithAlpha]),
        color: output.color ? `#${output.color}` : DEFAULT_COLOR,
      }));
  };

  const parseNumericOutputs = (
    fetchType: FETCH_TYPE,
  ): FrameNumericOutputs[] | CineNumericOutputs[] | undefined => {
    const { interpSpec, interpretation } = getFetchInfo(fetchType);

    return interpSpec?.outputs.filter(isNumericOutputType).map((output) => ({
      label: output.label || output.op_name,
      key: output.op_name,
      value: interpretation[output.op_name],
    }));
  };

  const parseBooleanOutputs = (
    fetchType: FETCH_TYPE,
  ): FrameBooleanOutputs[] | CineBooleanOutputs[] | undefined => {
    const { interpSpec, interpretation } = getFetchInfo(fetchType);

    return interpSpec?.outputs
      .filter((output) => output.output_type === OUTPUT_TYPES.BOOLEAN)
      .map((output) => ({
        label: output.label || output.op_name,
        key: output.op_name,
        value: interpretation[output.op_name],
      }));
  };

  const parseSegmentationOutputs = (
    fetchType: FETCH_TYPE,
  ): LineComponentOutputs[] | undefined => {
    const { interpSpec, interpretation } = getFetchInfo(fetchType);

    return interpSpec?.outputs
      .filter((output) => output.output_type === OUTPUT_TYPES.SEGMENTATIONS)
      .map((output) => ({
        label: output.label || output.op_name,
        key: output.op_name,
        contours: decodeContours(interpretation[output.op_name]),
        color: output.color ? `#${output.color}` : DEFAULT_COLOR,
      }))
      .flatMap((contourData) => {
        const instances = contourData.contours[0];
        return instances.map((contours, instance) => ({
          label: contourData.label,
          key: `${contourData.key}_${instance}`,
          color: contourData.color,
          points: contours,
          type: OUTPUT_TYPES.SEGMENTATIONS,
        }));
      });
  };

  const parseQualities = () => {
    const { interpSpec, interpretation } = getFetchInfo('FRAME');
    return interpSpec?.outputs
      .filter((output) => output.output_type === OUTPUT_TYPES.QUALITY)
      .map((output) => interpretation[output.op_name])[0];
  };

  const frameBooleanOutputs = parseBooleanOutputs('FRAME') || [];
  const cineBooleanOutputs = parseBooleanOutputs('CINE') || [];

  const frameNumericOutputs = parseNumericOutputs('FRAME') || [];
  const cineNumericOutputs = parseNumericOutputs('CINE') || [];

  const frameDataOutputs = [...frameBooleanOutputs, ...frameNumericOutputs];
  const cineDataOutputs = [...cineBooleanOutputs, ...cineNumericOutputs];

  const frameSegmentationOutputs = parseSegmentationOutputs('FRAME') || [];
  const cineSegmentationOutputs = parseSegmentationOutputs('CINE') || [];

  const frameLineOutputs = parseLineOutputs('FRAME') || [];
  const cineLineOutputs = parseLineOutputs('CINE') || [];
  const lineComponentOutputs = [
    ...cineLineOutputs,
    ...frameLineOutputs,
    ...frameSegmentationOutputs,
    ...cineSegmentationOutputs,
  ];

  const frameCoordinateOutputs = parseCoordinateOutputs('FRAME') || [];
  const cineCoordinateOutputs = parseCoordinateOutputs('CINE') || [];
  const coordinateOutputs = [
    ...frameCoordinateOutputs,
    ...cineCoordinateOutputs,
  ];

  const qualities = parseQualities();

  return dlResultOutputSchema.cast({
    qualities,
    frameDataOutputs,
    cineDataOutputs,
    lineComponentOutputs,
    coordinateOutputs,
  });
};

/**
 * Returns a copy of a DlResultOutputs object with frame outputs sliced
 * appropriately based off of a given start frame and/or end frame.
 *
 * @param dlResultOutput - the DlResultOutputs object to slice.
 * @param startFrame - The first (inclusive) frame number in the sequence, 0 if
 * unspecified.
 * @param endFrame - The last (exclusive) frame number in the sequence to slice
 * up to. Slices through the end of the frame sequence if unspecified.
 */
export const sliceFrames = (
  dlResultOutput: DlResultOutputs,
  startFrame?: number,
  endFrame?: number,
) => {
  const {
    qualities,
    frameDataOutputs,
    cineDataOutputs,
    lineComponentOutputs,
    coordinateOutputs,
  } = dlResultOutput;

  return {
    cineDataOutputs,
    frameDataOutputs: frameDataOutputs.map((output) => ({
      key: output.key,
      value: output.value.slice(startFrame, endFrame),
      label: output.label,
    })),
    qualities: qualities?.slice(startFrame, endFrame),
    lineComponentOutputs: lineComponentOutputs.map((output) => ({
      label: output.label,
      key: output.key,
      points: output.points.slice(startFrame, endFrame),
      color: output.color,
      type: output.type,
    })),
    coordinateOutputs: coordinateOutputs.map((output) => ({
      label: output.label,
      key: output.key,
      points: output.points.slice(startFrame, endFrame),
      color: output.color,
    })),
  };
};
