import CineFetcher from './CineFetcher';

let cineFetcher;

export default async function getFileData({
  api,
  imageId,
  startFrame,
  endFrame,
}) {
  if (!cineFetcher) {
    cineFetcher = new CineFetcher(api);
  }

  const [file, frames] = await Promise.all([
    api.getFileByImageId(imageId),
    cineFetcher.fetch(imageId, startFrame, endFrame),
  ]);

  return { file, frames };
}
