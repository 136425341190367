/* Contains the schemas for the various types of Annotation Tasks. */

import camelCase from 'lodash/camelCase';
import isPlainObject from 'lodash/isPlainObject';
import snakeCase from 'lodash/snakeCase';
import { array, bool, lazy, number, object, string } from 'yup';

import strictObject from './strictObject';

const Char = string().max(1).min(1);

const HexColor = string().matches(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i);

export const BaseQuestion = strictObject({
  type: string().oneOf(['bool', 'number', 'string', 'tag']),
  displayText: string().required(),
  questionId: string().required(),

  required: bool().default(false),
  dependsOn: strictObject({
    questionId: string(),
    values: array().min(1),
  })
    .default(null)
    .nullable(),
});

const ConfigTypes = {
  bool: BaseQuestion.shape({
    defaultValue: bool(),
    style: string().oneOf(['checkbox', 'button']),
    trueDisplayText: string(),
    falseDisplayText: string(),
  }),
  number: BaseQuestion.shape({
    isInt: bool(),
    minimumValue: number(),
    maximumValue: number(),
    defaultValue: number(),
  }),
  string: BaseQuestion.shape({
    defaultValue: string(),
  }),
  tag: BaseQuestion.shape({
    style: string().oneOf(['button', 'checkbox', 'dropdown']),

    tagOptions: array().of(
      strictObject({
        displayText: string().required(),
        value: string().required(),
        color: HexColor,
        shortcut: Char,
      }),
    ),
    defaultValue: string(),
  }),
};

export const Question = lazy((value) =>
  !value
    ? BaseQuestion.default(undefined)
    : ConfigTypes[value.type] || BaseQuestion,
);

export const Skip = strictObject({
  displayText: string().required(),
  question: Question,
});

export const Intervals = ConfigTypes.tag.shape({
  type: string().default('tag'),
  style: string().default('button'),
});

export const Calculation = strictObject({
  calculationId: string().default(''),
  displayText: string().required(),
  calculationType: string().required(),
  visible: bool().default(true),
});

export const Trace = strictObject({
  traceId: string().default(''),
  type: string().oneOf(['point', 'line', 'ellipse', 'polygon', 'rectangle']),
  displayText: string().required(),
  interpolate: bool().default(false),
  shortcut: Char,
  color: HexColor,
  /** Zero indicates an unbounded line */
  numPoints: number().min(0),

  /** Maximum number of times the trace can be drawn on each frame */
  maxPerFrame: number().min(1).default(1),

  /** The number of frames the trace can be draw */
  numFrames: number().min(0).default(1),

  /** Defaults to true for backwards compatibility */
  required: bool().default(true),
});

const BaseTask = strictObject({
  taskId: string().default(''),
  displayText: string().required(),
  type: string().oneOf(['cine', 'frame', 'pixel']).required(),
  version: string(),
  instructions: string(),
  instructionsVimeoId: string(),
  skip: Skip.default(null)
    .nullable()
    .transform((v) => {
      // comes in as an empty object
      if (v && !Object.keys(v).length) return null;
      return v;
    }),
});

export const TaskTypes = {
  frame: BaseTask.shape({
    intervals: Intervals.default(null),
    questions: array().of(Question),
  }),
  cine: BaseTask.shape({
    questions: array().of(Question),
  }),
  pixel: BaseTask.shape({
    intervals: array().of(Intervals),
    traces: array().of(Trace),
    questions: array().of(Question),
    calculations: array().of(Calculation),
  }),
  graph: BaseTask.shape({
    traces: array().of(Trace),
  }),
};

const AnnotationTask = object({
  name: string().required(),
  version: number(),
  enabled: bool(),
  definition: lazy((value) => TaskTypes[value.type] || BaseTask.default(null)),
  referenceImages: array(),
}).camelCase();

export default AnnotationTask;

export const deserialize = (task) => {
  // we cast a pixel task's interval field to an array if it has one that isn't already an array
  if (
    task.latest_version.definition.type === 'pixel' &&
    task.latest_version.definition.intervals
  ) {
    if (!Array.isArray(task.latest_version.definition.intervals)) {
      // eslint-disable-next-line no-param-reassign
      task.latest_version.definition.intervals = [
        task.latest_version.definition.intervals,
      ];
    }
  }

  const result = AnnotationTask.cast({
    ...task,
    ...task.latest_version,
  });
  return result;
};

const convertCase = (obj, converter) => {
  if (!obj) return obj;

  const keys = Object.keys(obj);
  const result = {};
  for (const key of keys) {
    const convertedKey = converter(key);

    if (Array.isArray(obj[key])) {
      result[convertedKey] = obj[key].map((o) => convertCase(o, converter));
    } else if (isPlainObject(obj[key])) {
      result[convertedKey] = convertCase(obj[key], converter);
    } else {
      result[convertedKey] = obj[key];
    }
  }
  return result;
};

export const deserializeDefinition = (definition) => {
  const schema = TaskTypes[definition.type] || BaseTask.default(null);
  // we cast the intervals field for a pixel task to an array to match the schema
  if (definition.type === 'pixel' && definition.intervals) {
    // eslint-disable-next-line no-param-reassign
    definition.intervals = [definition.intervals];
  }
  const result = schema.cast(definition);
  return convertCase(result, camelCase);
};

export const serializeDefinition = (definition) => {
  const schema = TaskTypes[definition.type] || BaseTask.default(null);
  const result = schema.cast(definition);
  result.skip = result.skip || {};
  // we cast the intervals array field for a pixel task to the first element
  // to match the schema
  if (definition.type === 'pixel' && result.intervals?.length) {
    result.intervals = result.intervals[0];
  }
  return convertCase(result, snakeCase);
};
