import Button from '@bfly/ui/Button';
import React from 'react';

type Props = {
  onClick?: (file: File) => void;
  children?: React.ReactNode | React.ReactNode[];
};

/**
 * An input button that is actually a label and file input for uploading files
 *
 */
function UploadCsvInput({ onClick, children }: Props) {
  return (
    <Button as="label" theme="primary" type={null}>
      <input
        type="file"
        onChange={(e) => onClick?.(e.currentTarget.files![0]!)}
        value=""
        accept=".csv"
        className="sr-only"
      />
      {children}
    </Button>
  );
}

export default UploadCsvInput;
