import { InferType, array, bool, number, object, string } from 'yup';

import { Question } from './AnnotationTask';
import strictObject from './strictObject';

const schema = object({
  name: string().required(),
  version: number(),
  enabled: bool(),
  definition: strictObject({
    taskId: string(),
    displayText: string().required().default(''),
    type: string().oneOf(['review']).default('review'),
    instructions: string().default(''),
    taskToReview: string().required().default(''),
    questions: array().of(Question),
  }),
}).camelCase();

export default schema;

export type ReviewTask = InferType<typeof schema>;

export const deserialize = (task) => {
  const result = schema.cast({
    ...task,
    ...task.latest_version,
  });
  return result;
};
