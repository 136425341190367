import React from 'react';
import FieldSet from '@bfly/ui/FieldSet';
import { css } from 'astroturf';
import Text from '@bfly/ui/Text';

type output = {
  key: any;
  label: string;
  value: number;
};

interface Props {
  legend: string;
  outputs: output[];
}

const styles = css`
  .output {
    padding: 0.4rem 1rem;
    overflow-wrap: anywhere;
  }
`;

function NumericOutput({ label, value }) {
  return (
    <div className={styles.output}>
      <Text variant="body-bold" color="headline">
        {label}:{' '}
      </Text>
      {Math.round((value + Number.EPSILON) * 100) / 100}
    </div>
  );
}

function TextOutput({ label, value }) {
  return (
    <div className={styles.output}>
      <Text variant="body-bold" color="headline">
        {label}:{' '}
      </Text>
      {value.toString()}
    </div>
  );
}

function DataOutputsFieldset({ legend, outputs }: Props) {
  return (
    <FieldSet legend={legend}>
      {outputs.map((output) =>
        typeof output.value === 'number' ? (
          <NumericOutput
            key={output.key}
            label={output.label}
            value={output.value}
          />
        ) : (
          <TextOutput
            key={output.key}
            label={output.label}
            value={output.value}
          />
        ),
      )}
    </FieldSet>
  );
}

export default DataOutputsFieldset;
