import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from '@4c/layout';
import Caret from '@bfly/ui/Caret';
import Dropdown from '@bfly/ui/Dropdown';
import Text from '@bfly/ui/Text';
import usePrevious from '@restart/hooks/usePrevious';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';

import * as GraphTracing from '../utils/graphTracing';
import useRafInterval from '../utils/useRafInterval';
import { useShortcut } from './KeyboardShortcutManager';
import Plot from './Plot';

const FREQ_OPTIONS = Array.from({ length: 10 }, (_, i) => i);
const TAB_STEP_SIZE = 0.1;
const PLAY_STEP_SIZE = 0.01;

type Range = [number, number];

const DEFAULT_LAYOUT = {
  // TODO make this configurable via the task definition
  xaxis: { range: [1, 5], rangeslider: {}, showticklabels: false },
  yaxis: {
    fixedRange: true,
    domain: [0, 0.33],
    title: 'P4-O2',
    showticklabels: false,
  },
  yaxis2: {
    fixedRange: true,
    domain: [0.33, 0.66],
    title: 'F4-C4',
    showticklabels: false,
  },
  yaxis3: {
    fixedRange: true,
    domain: [0.66, 1],
    title: 'C4-P4',
    showticklabels: false,
  },
  dragmode: 'pan',
  selectdirection: 'h',
  shapes: [
    // Show a line down the middle to indicate the current "frame"
    {
      type: 'line',
      xref: 'paper',
      yref: 'paper',
      x0: 0.5,
      y0: 0,
      x1: 0.5,
      y1: 1,
      opacity: 1,
      line: {
        width: 3,
        color: '#FF0827',
        dash: 'dot',
      },
    },
  ],
};

const Y_AXIS_TRACES = [
  {
    hoverinfo: 'none',
    line: { color: '#FFFFFF' },
    showlegend: false,
  },
  {
    yaxis: 'y2',
    hoverinfo: 'none',
    line: { color: '#FFFFFF' },
    showlegend: false,
  },
  {
    yaxis: 'y3',
    hoverinfo: 'none',
    line: { color: '#FFFFFF' },
    showlegend: false,
  },
];

const defaultConfig = {
  displayModeBar: false,
  scrollZoom: true,
};

interface Props {
  task: any;
  results: any[];
  onTrace: (results: any) => void;
  file: {
    x: number[];
    y: number[];
  };
  activeTrace;
}

export default function AnnotationGraph({
  file,
  task,
  results,
  onTrace,
  activeTrace,
}: Props) {
  const [xrange, setXrange] = useState<Range>([-10, 10]);

  const [pause, setPause] = useState(true);

  const [freq, setFreq] = useState(1);

  const [annotations, setAnnotations] = useState(() => {
    const dateResults = {};
    Object.keys(results).forEach((key) => {
      dateResults[key] = results[key].map((val) => ({
        val,
        date: new Date(),
      }));
    });
    return dateResults;
  });

  const graphData = useMemo(
    () => Y_AXIS_TRACES.map((trace) => ({ ...trace, y: file.y })),
    [file.y],
  );

  const zoom = (direction: 'in' | 'out') =>
    setXrange((range) => GraphTracing.zoom(range, direction));

  const tab = (direction: 'left' | 'right', amount = TAB_STEP_SIZE) =>
    setXrange((range) => GraphTracing.step(range, direction, amount));

  const togglePlay = () => setPause(!pause);

  const annotate = useCallback(
    (trace) => {
      const xVal = GraphTracing.rangeDiff(xrange, 0.5) + xrange[0];
      const nextAnnotations = { ...annotations };
      const time = new Date();
      Object.keys(results).forEach((key) => {
        nextAnnotations[key] = annotations[key].filter(
          (item) => item.val !== xVal,
        );
      });

      nextAnnotations[trace.traceId] = [
        ...nextAnnotations[trace.traceId],
        {
          val: xVal,
          date: time,
        },
      ];
      setAnnotations(nextAnnotations);
    },
    [results, xrange, annotations],
  );

  const deleteNearest = useCallback(() => {
    setAnnotations((prevAnnotations) => {
      const rangeCenter = 0.5 * (xrange[0] + xrange[1]);
      const minRange = 0.01 * (xrange[0] + xrange[1]);
      const nearest = GraphTracing.findNearest(
        rangeCenter,
        minRange,
        prevAnnotations,
      );
      if (!nearest) {
        return prevAnnotations;
      }
      return {
        ...prevAnnotations,
        [nearest.traceId]: prevAnnotations[nearest.traceId].filter(
          (annotation) => annotation.val !== nearest.val,
        ),
      };
    });
  }, [xrange]);

  const clearFrame = useCallback(() => {
    const nextAnnotations = {};
    Object.keys(results).forEach((key) => {
      nextAnnotations[key] = annotations[key].filter(
        (item) => item.val > xrange[1] || item.val < xrange[0],
      );
    });
    setAnnotations(nextAnnotations);
  }, [results, xrange, annotations]);

  const deleteLast = useCallback(() => {
    const nextAnnotations = { ...annotations };
    let latest = 0;
    let traceId;
    Object.keys(results).forEach((key) => {
      const traces = nextAnnotations[key];
      const len = traces.length;
      if (len > 0 && traces[len - 1].date > latest) {
        latest = traces[len - 1].date;
        traceId = key;
      }
    });
    if (traceId) {
      nextAnnotations[traceId] = nextAnnotations[traceId].filter(
        (trace) => trace.date !== latest,
      );
    }
    setAnnotations(nextAnnotations);
  }, [results, annotations]);

  const prevActiveTrace = usePrevious(activeTrace);

  const shortcuts = useMemo(() => {
    const items = {};
    task.traces.forEach((trace) => {
      if (trace.shortcut) {
        items[trace.shortcut] = () => annotate(trace);
      }
    });
    return items;
  }, [task.traces, annotate]);

  useShortcut({
    ArrowUp: () => zoom('in'),
    ArrowDown: () => zoom('out'),
    ArrowRight: () => tab('right'),
    ArrowLeft: () => tab('left'),
    ' ': togglePlay,
    ...shortcuts,
    '=': clearFrame,
    Backspace: deleteLast,
    s: deleteNearest,
  });

  useRafInterval(
    () => tab('right', PLAY_STEP_SIZE),
    pause || !freq ? null : 10 - freq,
  );

  const layout = useMemo(
    () => ({
      ...DEFAULT_LAYOUT,
      xaxis: {
        ...DEFAULT_LAYOUT.xaxis,
        range: xrange,
      },
      shapes: [
        ...DEFAULT_LAYOUT.shapes,
        ...task.traces
          .map((trace) =>
            annotations?.[trace.traceId]?.map((result) =>
              GraphTracing.toShape(trace, result.val),
            ),
          )
          .filter(Boolean)
          .flat(),
      ],
    }),
    [xrange, task.traces, annotations],
  );

  useEffect(() => {
    if (activeTrace != null && prevActiveTrace !== activeTrace) {
      const currTrace = task.traces.find(
        ({ traceId }) => traceId === activeTrace,
      );
      annotate(currTrace);
    }
  }, [activeTrace, annotate, prevActiveTrace, task.traces]);

  useUpdateEffect(() => {
    const nextResults = {};
    Object.keys(annotations).forEach((traceId) => {
      nextResults[traceId] = annotations[traceId].map((value) => value.val);
    });
    onTrace(nextResults);
  }, [annotations, onTrace]);

  return (
    <>
      <Layout pad wrap align="flex-start">
        <Dropdown>
          <Dropdown.Toggle theme="secondary">
            <Text transform="capitalize" variant="inherit">
              {freq}
            </Text>
            <Caret width={8} height={8} className="ml-2" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {FREQ_OPTIONS.map((val) => (
              <Dropdown.Item onClick={() => setFreq(val)} key={val}>
                {val}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Layout>
      <Plot data={graphData} layout={layout} config={defaultConfig} />
    </>
  );
}
