import Link from 'found/lib/Link';
import React, { useEffect } from 'react';
import Layout from '@4c/layout';
import Button from '@bfly/ui/Button';
import Header from '@bfly/ui/Header';
import Heading from '@bfly/ui/Heading';
import MainContent from '@bfly/ui/MainContent';
import Section from '@bfly/ui/Section';

import AppPage from '../components/AppPage';
import EarningSummary from '../components/EarningSummary';
import ExpensesPlot from '../components/ExpensesPlot';
import FormattedCurrency from '../components/FormattedCurrency';
import SummaryCard from '../components/SummaryCard';
import Table from '../components/Table';
import { User } from '../models';
import { Earning, deserializeEarnings } from '../schema/Earning';
import { formatForRoute } from '../utils/date';

interface Props {
  data: {
    viewer: User;
    earnings: Earning[];
    latest: Earning;
  };
}

async function getData({ context }) {
  const { api } = context;
  const [viewer, { earnings, latest }] = await Promise.all([
    AppPage.getData({ context }),
    api.getEarnings().then(deserializeEarnings),
  ]);

  return { viewer, latest, earnings };
}

function ExpensesPage({ data }: Props) {
  const { viewer, earnings, latest } = data;

  useEffect(() => {
    document.title = `Expenses - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Header>
        <Header.Title>Expenses Overview</Header.Title>
      </Header>
      <MainContent size="medium">
        <Layout direction="column" pad={5}>
          {latest && (
            <Section>
              <Heading>Current Month</Heading>

              <SummaryCard>
                <SummaryCard.Header>Total</SummaryCard.Header>
                <SummaryCard.Body>
                  <FormattedCurrency value={latest.totalAmount} />
                </SummaryCard.Body>
              </SummaryCard>
            </Section>
          )}

          {earnings.length > 0 && (
            <>
              <ExpensesPlot expenses={earnings} />

              <Section>
                <Heading>Monthly Expenses</Heading>
                <Table variant="styled-header">
                  <thead>
                    <tr>
                      <th>Expense</th>
                      <th>Amount</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {earnings.map((earning) => (
                      <tr key={`${earning.startDate}-${earning.endDate}`}>
                        <td>
                          <EarningSummary earning={earning} />
                        </td>
                        <td>
                          <FormattedCurrency value={earning.totalAmount} />
                        </td>
                        <td className="pr-3">
                          <Link
                            theme="secondary"
                            as={Button}
                            to={`/-/admin/expenses/${formatForRoute(
                              earning.startDate,
                            )}/${formatForRoute(earning.endDate)}`}
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Section>
            </>
          )}
        </Layout>
      </MainContent>
    </AppPage>
  );
}

ExpensesPage.getData = getData;

export default ExpensesPage;
