import { css } from 'astroturf';
import React from 'react';
import Tooltip from '@bfly/ui/Tooltip';

const styles = css`
  .container {
    width: 100px;
    height: 20px;
    position: relative;
  }

  .slider {
    appearance: none;
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-image: linear-gradient(
      to right,
      rgba(249, 36, 0, 1),
      rgba(255, 220, 0, 1) 33%,
      rgba(69, 181, 98, 1) 53%
    );
    position: absolute;
  }

  .slider::-webkit-slider-thumb {
    appearance: none;
    width: 1.2rem;
    height: 1.2rem;
    background: white;
    border-radius: 50%;
    cursor: pointer;
  }

  .ticks {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
  }

  .tick {
    position: relative;
    justify-content: center;
    background: black;
    width: 0.2rem;
    height: 2rem;
    opacity: 0.2;
  }
`;

interface Props {
  frameQuality: number;
  onHover?: () => void;
  onHoverOff?: () => void;
}

function QualityIndicator({ frameQuality, onHover, onHoverOff }: Props) {
  return (
    <Tooltip
      id="frame-quality"
      placement="bottom"
      tooltip={frameQuality.toFixed(5)}
    >
      <div
        className={styles.container}
        onMouseEnter={onHover}
        onFocus={onHover}
        onMouseLeave={onHoverOff}
      >
        <input
          type="range"
          className={styles.slider}
          min={0}
          max={1}
          value={frameQuality}
          step={0.00001}
          readOnly
        />
        <div className={styles.ticks}>
          <span />
          <span className={styles.tick} />
          <span className={styles.tick} />
          <span />
        </div>
      </div>
    </Tooltip>
  );
}

export default QualityIndicator;
