/* eslint-disable no-param-reassign */
import { routerShape } from 'found/lib/PropTypes';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { string } from 'yup';
import Layout from '@4c/layout';
import Form from '@bfly/ui/Form';
import Header from '@bfly/ui/Header';
import MainContent from '@bfly/ui/MainContent';

import AppPage from 'components/AppPage';
import { useApi } from 'components/AuthProvider';
import Page from 'components/Page';
import TaskDefinitionControl from 'components/TaskDefinitionControl';
import ReviewAnnotationTask from 'src/schema/ReviewAnnotationTask';

const schema = ReviewAnnotationTask.shape({
  definition: string()
    .required()
    .default(
      `{
  "task_to_review": "",
  "instructions": "",
  "version": "0",
  "display_text": ""
}`,
    )
    .test('validJson', function test(value) {
      try {
        JSON.parse(value);
      } catch (err) {
        return this.createError({ message: `Invalid JSON: ${err.message}` });
      }
      return true;
    }),
});

const propTypes = {
  data: PropTypes.object.isRequired,

  // The routing mechanism used for links.
  router: routerShape.isRequired,
};

async function getData({ params, context }) {
  const { taskId } = params;
  const { api } = context;

  return {
    viewer: await AppPage.getData({ context }),
    reviewTask: taskId ? await api.getLatestTask(taskId) : null,
  };
}

function ReviewTaskPage({ data, router }) {
  const [errors, onError] = useState(() => ({}));
  const { viewer, reviewTask } = data;
  const api = useApi();

  const defaultValue = useMemo(() => {
    if (!reviewTask) {
      return schema.default();
    }
    // we don't want the type to show to a user
    const { type: _, ...definition } = reviewTask.latest_version.definition;
    return {
      name: reviewTask.name,
      enabled: reviewTask.enabled,
      definition: JSON.stringify(definition, null, 2),
    };
  }, [reviewTask]);

  const submitForm = async (updatedTask) => {
    updatedTask.definition = JSON.parse(updatedTask.definition);
    updatedTask.task_type = 'review';
    updatedTask.definition.type = 'review';

    try {
      await ReviewAnnotationTask.validateAt('definition', updatedTask, {
        abortEarly: false,
      });
    } catch (err) {
      const formErrors = { ...errors, ...Form.toErrors(err) };
      onError(formErrors);
      return;
    }

    await api.createTaskVersion(updatedTask);

    router.push('/-/admin/review_tasks');
  };

  useEffect(() => {
    document.title = `${reviewTask ? 'Edit' : 'Create'} Task - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Page.Header backTo="/-/admin/review_tasks">
        <Header.Title>{reviewTask ? 'Edit' : 'Create'} Task</Header.Title>
      </Page.Header>
      <MainContent size="medium">
        <Form
          schema={schema}
          errors={errors}
          onError={onError}
          submitForm={submitForm}
          defaultValue={defaultValue}
        >
          <Form.HorizontalFieldGroup name="name" label="Name" autoFocus />
          {!!reviewTask && (
            <Form.HorizontalFieldGroup
              name="enabled"
              label="Enabled"
              style={{ alignSelf: 'center' }}
            />
          )}
          <Form.HorizontalFieldGroup
            as={TaskDefinitionControl}
            name="definition"
            label="Task Definition"
            events="onChange" // don't validate
            rows="20"
          />
          <Layout justify="flex-end">
            <Form.Submit>Save Task</Form.Submit>
          </Layout>
        </Form>
      </MainContent>
    </AppPage>
  );
}

ReviewTaskPage.propTypes = propTypes;
ReviewTaskPage.getData = getData;

export default ReviewTaskPage;
