/* eslint-disable global-require */
// in custom-plotly.js

import Plotly from 'plotly.js/lib/core';

// Load in the required Plotly modules
Plotly.register([
  require('plotly.js/lib/bar'),
  require('plotly.js/lib/box'),
  require('plotly.js/lib/scatter'),
  require('plotly.js/lib/aggregate'),
  require('plotly.js/lib/groupby'),
  require('plotly.js/lib/pie'),
]);

export default Plotly;
