import React from 'react';
import { Line } from 'react-konva';

import { LineOverlay } from '../schema/ProjectOverlays';

interface Props {
  projectLineOverlays: LineOverlay[];
}

function ProjectLineOverlays({ projectLineOverlays }: Props) {
  return (
    <>
      {projectLineOverlays.map((projectLineOverlay) => {
        const { lines } = projectLineOverlay;
        return lines.map((lineCoordinates, index) => (
          <Line
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${projectLineOverlay.key}_${index}`}
            points={lineCoordinates.flat()}
            stroke={projectLineOverlay.color}
          />
        ));
      })}
    </>
  );
}

export default ProjectLineOverlays;
