import { Data } from 'plotly.js';
import React, { useMemo } from 'react';

import { Project } from '../models';
import { projectDistributionSchema } from '../schema/OrganizationAnalytics';
import { useAnalyticsData } from '../utils/AnalyticsChart';
import Plot from './Plot';

type Props = {
  project: Project;
  distributionType: 'cine' | 'study';
};

type AnalyticsData = {
  splitName: string[];
  count: number[];
};

const baseLayout = {
  height: 400,
  width: 450,
};

const ProjectDistributionChart = ({ project, distributionType }: Props) => {
  const analyticsName = `organization/${project.name}/${distributionType}-split-affiliation`;

  const { data, loading } = useAnalyticsData<AnalyticsData>(
    analyticsName,
    projectDistributionSchema,
  );

  const plotData: Data[] = [
    {
      values: data.count,
      labels: data.splitName,
      type: 'pie',
    },
  ];
  const layout = useMemo(
    () => ({
      ...baseLayout,
      title: `${project.name} - Split affiliation per ${distributionType}`,
    }),
    [project.name, distributionType],
  );

  return <Plot data={plotData} layout={layout} loading={loading} />;
};

export default ProjectDistributionChart;
