import orderBy from 'lodash/orderBy';
import React, { useEffect, useMemo, useState } from 'react';
import Layout from '@4c/layout';
import Card from '@bfly/ui/Card';
import DropdownList from '@bfly/ui/DropdownList';
import Header from '@bfly/ui/Header';
import Heading from '@bfly/ui/Heading';
import MainContent from '@bfly/ui/MainContent';
import Section from '@bfly/ui/Section';
import Text from '@bfly/ui/Text';

import AppPage from '../components/AppPage';
import EarningSummary from '../components/EarningSummary';
import FormattedCurrency from '../components/FormattedCurrency';
import FormattedMonth from '../components/FormattedMonth';
import Page from '../components/Page';
import SummaryCard from '../components/SummaryCard';
import Table from '../components/Table';
import { User } from '../models';
import { Earning, deserializeEarnings } from '../schema/Earning';
import {
  EarningDetail,
  deserializeEarningDetails,
} from '../schema/EarningDetail';
import { parseFromRoute } from '../utils/date';

interface Sort {
  id: keyof EarningDetail;
  value: string;
  direction: 'asc' | 'desc';
}

const SORTS: Sort[] = [
  { id: 'taskDescription', value: 'Task Name (A to Z)', direction: 'asc' },
  { id: 'completed', value: 'Count (High to Low)', direction: 'desc' },
  { id: 'completed', value: 'Count (Low to High)', direction: 'asc' },
  { id: 'totalAmount', value: 'Amount (High to Low)', direction: 'desc' },
  { id: 'totalAmount', value: 'Amount (Low to High)', direction: 'asc' },
  { id: 'rate', value: 'Rate (High to Low)', direction: 'desc' },
  { id: 'rate', value: 'Rate (Low to High)', direction: 'asc' },
];

interface Props {
  data: {
    viewer: User;
    earning: Earning;
    earningsDetails: EarningDetail[];
  };
}

async function getData({ params, context }) {
  const { api } = context;

  const startDate = parseFromRoute(params.startDate)!;
  const endDate = parseFromRoute(params.endDate);

  const [viewer, { earnings }, earningsDetails] = await Promise.all([
    AppPage.getData({ context }),
    api.getEarnings({ startDate, endDate }).then(deserializeEarnings),
    api
      .getEarningsDetails({ startDate, endDate })
      .then(deserializeEarningDetails),
  ]);

  const earning = earnings[0];

  return { viewer, earning, earningsDetails };
}

function ExpenseDetailsPage({ data }: Props) {
  const { viewer, earning, earningsDetails } = data;

  const [sort, setSort] = useState<Sort>(SORTS[0]);

  const items = useMemo(
    () => orderBy(earningsDetails, [sort.id], [sort.direction]),
    [earningsDetails, sort],
  );

  useEffect(() => {
    const dateText = earning.startDate.toLocaleDateString('en-US', {
      month: 'long',
    });
    document.title = `Expense Detail - ${dateText} - Butterfly`;
  });

  return (
    <AppPage viewer={viewer}>
      <Page.Header backTo="/-/admin/expenses">
        <Header.Title>Back to Summary</Header.Title>
      </Page.Header>

      <MainContent size="medium">
        <Layout direction="column" pad={5}>
          <EarningSummary earning={earning}>
            <Heading>
              <FormattedMonth value={earning.startDate} />
              Earnings Detail
            </Heading>
          </EarningSummary>

          <Section>
            <SummaryCard>
              <SummaryCard.Header>Total</SummaryCard.Header>
              <SummaryCard.Body>
                <FormattedCurrency value={earning.totalAmount} />
              </SummaryCard.Body>
            </SummaryCard>
          </Section>

          {earningsDetails.length > 0 && (
            <Section>
              <Layout
                justify="space-between"
                align="flex-end"
                className="my-3"
              >
                <Heading className="mb-0">Task Details</Heading>
                <Layout align="center">
                  <Text color="headline" className="mr-3">
                    Sort By{' '}
                  </Text>
                  <DropdownList
                    data={SORTS}
                    textField="value"
                    value={sort}
                    onChange={(s) => setSort(s)}
                    css="min-width: 25rem;"
                  />
                </Layout>
              </Layout>
              <Card className="py-3 px-5">
                <Table>
                  <thead>
                    <tr>
                      <th>Task Name</th>
                      <th>Rate</th>
                      <th>Count</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((earningsItem, idx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr key={idx}>
                        <td>
                          <Text variant="body-bold" color="headline">
                            {earningsItem.taskDescription}
                          </Text>
                        </td>
                        <td>
                          <FormattedCurrency value={earningsItem.rate} />
                        </td>
                        <td>
                          <Text>{earningsItem.completed}</Text>
                        </td>
                        <td>
                          <Text color="headline">
                            <FormattedCurrency
                              value={earningsItem.totalAmount}
                            />
                          </Text>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Section>
          )}
        </Layout>
      </MainContent>
    </AppPage>
  );
}

ExpenseDetailsPage.getData = getData;

export default ExpenseDetailsPage;
