import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from '@bfly/ui/Button';

interface Props {
  glyph: string;
  tooltip: string;
}

function GlyphControl({ glyph, tooltip, ...props }: Props) {
  const button = (
    <Button {...props}>
      <i className={`fas fa-${glyph}`} />
    </Button>
  );

  if (!tooltip) {
    return button;
  }

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={tooltip}>{tooltip}</Tooltip>}
    >
      {React.cloneElement(button, {
        // Force rendering as <a> instead of <button> so we can show tooltip
        // even when disabled.
        href: '#',
        style: { pointerEvents: 'auto' },
      })}
    </OverlayTrigger>
  );
}

export default GlyphControl;
