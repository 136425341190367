import React, { useMemo, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import Layout from '@4c/layout';
import DropdownList from '@bfly/ui/DropdownList';
import Form from '@bfly/ui/Form';
import FormCheckGroup from '@bfly/ui/FormCheckGroup';
import Multiselect from '@bfly/ui/Multiselect';

import { DlcImageProcessName, Preset } from '../models';
import {
  CaptureMode,
  Model,
  PROCESS_TYPES,
  Process,
  ProcessSchema,
  ProcessType,
} from '../schema/Process';
import ProcessModelsFieldSet from './ProcessModelFieldSet';

const messages = defineMessages({
  processNameDescription: {
    id: 'processName.description',
    defaultMessage: 'Select Process Code to Run...',
  },
  prefixDescription: {
    id: 'prefix.description',
    defaultMessage: 'Associate Process With Prefix to Activate...',
  },
  processTypeDescription: {
    id: 'processType.description',
    defaultMessage: 'process type - currently only IMAGE type is supported.',
  },
  captureModeDescription: {
    id: 'captureMode.description',
    defaultMessage: 'Capture Mode',
  },
  servableDescription: {
    id: 'servable.description',
    defaultMessage: 'Set to release to end-users.',
  },
  deployedDescription: {
    id: 'deployed.description',
    defaultMessage: 'Set to run model on incoming data.',
  },
});

interface Props {
  onSave: (any) => void;
  isDlcOwner: boolean;
  process?: Process;
  processNames: DlcImageProcessName[];
  presets: Preset[];
  captureModes: CaptureMode[];
  projectModelMap: Map<string, Model[]>;
}

function ProcessDefinitionForm(props: Props) {
  const {
    onSave,
    isDlcOwner,
    process,
    processNames,
    presets,
    captureModes,
    projectModelMap,
  } = props;

  const defaultValue = useMemo(
    () => (process ? ProcessSchema.cast(process) : ProcessSchema.default()),
    [process],
  );
  const [value, setValue] = useState(defaultValue);

  // Rules:
  // 1	Servable can only be changed by dl_owner and only enabled if deployed
  //    is enabled.
  // 2	Deployed can be changed as long as the process is not Servable.
  // 3	All other fields can only be changed on a New Process.

  const disableExistingProcessFields: boolean = process != null;
  const disableServable = !(isDlcOwner && (value.servable || value.deployed));

  // TODO (bsternlieb) - probably whacky - but don't want unauthorized
  //  submissions.
  const disableSubmit =
    disableExistingProcessFields && !isDlcOwner && value.servable;

  const fieldsUpdated = (): boolean =>
    value.servable !== process?.servable ||
    value.deployed !== process?.deployed;

  return (
    <Form
      schema={ProcessSchema as any}
      value={value}
      submitForm={onSave as any}
      onChange={setValue}
      successMessage={
        process ? (
          <FormattedMessage
            id="processes.update.success"
            defaultMessage="Process updated successfully"
          />
        ) : (
          <FormattedMessage
            id="processes.create.success"
            defaultMessage="Process created successfully"
          />
        )
      }
    >
      <Form.FieldGroup
        name="dlcProcessType"
        label="Process Type"
        type="radio"
        as={FormCheckGroup}
        data={PROCESS_TYPES}
        placeholder={messages.processTypeDescription}
        direction="row"
        renderItem={(type: ProcessType) => type.valueOf()}
        disabled
      />
      <Form.FieldGroup
        name="dlcProcessName"
        label="Process Name"
        as={DropdownList}
        placeholder={messages.processNameDescription}
        data={processNames}
        mapFromValue={(v) => v && v.value}
        textField="value"
        dataKey="value"
        disabled={disableExistingProcessFields}
      />
      <Form.FieldGroup
        name="presetIds"
        label="Preset Filter"
        as={Multiselect}
        placeholder={messages.prefixDescription.defaultMessage}
        data={presets}
        dataKey="preset_id"
        mapFromValue={(v) => v.map((preset) => preset.preset_id)}
        textField={(v) => `${v.name}: ${v.preset_id}`}
        disabled={disableExistingProcessFields}
      />
      <Form.FieldGroup
        name="captureMode"
        label="Capture Mode"
        as={DropdownList}
        placeholder={messages.captureModeDescription}
        data={captureModes}
        mapFromValue={(v) => v && v.value}
        textField="value"
        dataKey="value"
        disabled={disableExistingProcessFields}
      />
      <Layout pad={10}>
        <Form.FieldGroup
          name="deployed"
          label="Deployed"
          description={messages.deployedDescription}
          disabled={value.servable}
        />
        <Form.FieldGroup
          name="servable"
          label="Servable"
          description={messages.servableDescription}
          // insure we are dlc_owner and that deployed is on to enable.
          disabled={disableServable}
        />
      </Layout>
      <ProcessModelsFieldSet
        projectModelMap={projectModelMap}
        disabled={disableExistingProcessFields}
      />
      <Layout justify="flex-end">
        <Form.Submit disabled={disableSubmit || !fieldsUpdated()}>
          {process ? 'Update' : 'Save'}
        </Form.Submit>
      </Layout>
    </Form>
  );
}

export default ProcessDefinitionForm;
