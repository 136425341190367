import { InferType, array, number, object, string } from 'yup';

type Coordinate = [number, number];

export type Line = Coordinate[];

export const projectLineOverlaySchema = object({
  key: string().required(),
  color: string().required(),
  lines: array().of(array().of(array().of(number()))),
}).camelCase();

export type LineOverlay = InferType<typeof projectLineOverlaySchema>;

export const projectOverlaysSchema = object({
  projectLineOverlays: array().of(projectLineOverlaySchema),
}).camelCase();

export type ProjectOverlays = InferType<typeof projectOverlaysSchema>;
