import HttpError from 'found/lib/HttpError';
import Route from 'found/lib/Route';
import Redirect from 'found/lib/Redirect';
import React from 'react';

import LabelsReviewPage from '../internal/LabelsReviewPage';
import DatasetAnalyticsPage from '../pages/DatasetAnalyticsPage';
import EditProjectPage from '../pages/EditProjectPage';
import ExpenseDetailsPage from '../pages/ExpenseDetailsPage';
import ExpensesPage from '../pages/ExpensesPage';
import InferenceInspectorPage from '../pages/InferenceInspectorPage';
import NewTaskPage from '../pages/admin/tasks/components/NewTaskPage';
import NewUserPage from '../pages/admin/users/components/NewUserPage';
import OrganizationAnalyticsPage from '../pages/OrganizationAnalyticsPage';
import ProjectListPage from '../pages/ProjectListPage';
import ProjectPage from '../pages/ProjectPage';
import ReviewTaskListPage from '../pages/admin/reviewTasks/components/ReviewTaskListPage';
import ReviewTaskPage from '../pages/admin/reviewTasks/components/ReviewTaskPage';
import TaskAnalyticsPage from '../pages/TaskAnalyticsPage';
import TaskDefinitionPage from '../pages/admin/tasks/components/TaskDefinitionPage';
import TaskListPage from '../pages/admin/tasks/components/TaskListPage';
import TaskPage from '../pages/admin/tasks/components/TaskPage';
import TaskReferenceImagePage from '../pages/admin/tasks/components/TaskReferenceImagePage';
import TaskWorklistsPage from '../pages/admin/tasks/components/TaskWorklistsPage';
import UserContractPage from '../pages/admin/users/components/UserContractPage';
import UserListPage from '../pages/admin/users/components/UserListPage';
import UserPage from '../pages/admin/users/components/UserPage';
import UserProfilePage from '../pages/admin/users/components/UserProfilePage';
import UserWorklistDetailPage from '../pages/admin/users/components/UserWorklistDetailPage';
import UserWorklistsPage from '../pages/admin/users/components/UserWorklistsPage';
import CustomEarningsListPage from '../pages/CustomEarningsListPage';
import NewCustomEarningsPage from '../pages/NewCustomEarningsPage';
import InferenceResultListPage from '../pages/InferenceResultListPage';
import InferenceProcessListPage from '../pages/InferenceProcessListPage';
import InferenceProcessPage from '../pages/InferenceProcessPage';
import TaskContractsPage from 'src/pages/admin/tasks/components/TaskContractsPage';

function throw403ForNonAdmins({ context }) {
  if (!context.api.viewerIsAdmin)
    throw new HttpError(403, 'Only administrators can view this resource');
}

export default (
  <Route path="-/admin" getData={throw403ForNonAdmins}>
    <Route
      path="dlc/inference-inspector/:dlResultId"
      Component={InferenceInspectorPage}
      getData={InferenceInspectorPage.getData}
    />

    <Route
      path="dlc/inference-results"
      Component={InferenceResultListPage}
      getData={InferenceResultListPage.getData}
    />

    <Route path="dlc/inference-processes">
      <Route
        Component={InferenceProcessListPage}
        getData={InferenceProcessListPage.getData}
      />
      <Route
        path="-/new"
        Component={InferenceProcessPage}
        getData={InferenceProcessPage.getData}
      />
      <Route
        path=":processId"
        Component={InferenceProcessPage}
        getData={InferenceProcessPage.getData}
      />
    </Route>

    <Route path="file/:imageId">
      <Redirect to="/file/:imageId" />
    </Route>

    <Route
      path="review/:task/:imageId"
      Component={LabelsReviewPage}
      getData={LabelsReviewPage.getData}
    />

    <Route path="tasks">
      <Route Component={TaskListPage} getData={TaskListPage.getData} />
      <Route
        path="-/new"
        Component={NewTaskPage}
        getData={NewTaskPage.getData}
      />
      <Route path=":taskId" Component={TaskPage} getData={TaskPage.getData}>
        <Route
          Component={TaskDefinitionPage}
          getData={TaskDefinitionPage.getData}
        />
        <Route
          path="reference-images"
          Component={TaskReferenceImagePage}
          getData={TaskReferenceImagePage.getData}
        />
        <Route
          path="worklists"
          Component={TaskWorklistsPage}
          getData={TaskWorklistsPage.getData}
        />
        <Route
          path="contracts"
          Component={TaskContractsPage}
          getData={TaskContractsPage.getData}
        />
      </Route>
    </Route>
    <>
      <Route
        path="review_tasks"
        Component={ReviewTaskListPage}
        getData={ReviewTaskListPage.getData}
      />
      <Route
        path="review_tasks/-/new"
        Component={ReviewTaskPage}
        getData={ReviewTaskPage.getData}
      />
      <Route
        path="review_tasks/:taskId"
        Component={ReviewTaskPage}
        getData={ReviewTaskPage.getData}
      />
    </>
    <Route path="users">
      <Route Component={UserListPage} getData={UserListPage.getData} />
      <Route
        path="-/new"
        Component={NewUserPage}
        getData={NewUserPage.getData}
      />
      <Route path=":username" Component={UserPage} getData={UserPage.getData}>
        <Route Component={UserProfilePage} getData={UserProfilePage.getData} />

        <Route
          path="contracts"
          Component={UserContractPage}
          getData={UserContractPage.getData}
        />

        <Route
          path="worklists"
          Component={UserWorklistsPage}
          getData={UserWorklistsPage.getData}
        />
        <Route
          path="worklists/:id"
          Component={UserWorklistDetailPage}
          getData={UserWorklistDetailPage.getData}
        />
      </Route>
    </Route>

    <Route path="projects">
      <Route Component={ProjectListPage} getData={ProjectListPage.getData} />
      <Route
        path="-/new"
        Component={EditProjectPage}
        getData={EditProjectPage.getData}
      />
      <Route path=":name">
        <Route Component={ProjectPage} getData={ProjectPage.getData} />
        <Route
          path="edit"
          Component={EditProjectPage}
          getData={EditProjectPage.getData}
        />
      </Route>
    </Route>
    <Route path="custom_earnings">
      <Route
        Component={CustomEarningsListPage}
        getData={CustomEarningsListPage.getData}
      />
      <Route
        path="-/new"
        Component={NewCustomEarningsPage}
        getData={NewCustomEarningsPage.getData}
      />
    </Route>
    <Route path="expenses">
      <Route Component={ExpensesPage} getData={ExpensesPage.getData} />
      <Route
        path=":startDate/:endDate"
        Component={ExpenseDetailsPage}
        getData={ExpenseDetailsPage.getData}
      />
    </Route>

    <Route path="analytics">
      <Route
        path="organization"
        Component={OrganizationAnalyticsPage}
        getData={OrganizationAnalyticsPage.getData}
      />

      <Route
        path="task"
        Component={TaskAnalyticsPage}
        getData={TaskAnalyticsPage.getData}
      />

      <Route
        path="datasets"
        Component={DatasetAnalyticsPage}
        getData={DatasetAnalyticsPage.getData}
      />
    </Route>
  </Route>
);
