import React from 'react';
import { FormattedMessage } from 'react-intl';
import BlankSlate from '@bfly/ui/BlankSlate';
import MainContent from '@bfly/ui/MainContent';
import Navbar from '@bfly/ui/Navbar';

import Page from './Page';

function MobileWarningPage() {
  return (
    <>
      <Navbar />
      <Page.Container center>
        <MainContent centerText>
          <BlankSlate>
            <BlankSlate.Title>
              <FormattedMessage
                defaultMessage="The device screen is not large enough to enable annotating"
                id="mobileWarningPage.title"
              />
            </BlankSlate.Title>
            <BlankSlate.Body>
              <FormattedMessage
                defaultMessage="Please switch to a larger device or resize the window to continue."
                id="mobileWarningPage.body"
              />
            </BlankSlate.Body>
          </BlankSlate>
        </MainContent>
      </Page.Container>
    </>
  );
}

export default MobileWarningPage;
