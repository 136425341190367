import React, { useCallback, useEffect } from 'react';
import {
  EllipseTool,
  PathTool,
  Point,
  PointTool,
  EllipseData as ToolEllipseData,
  triggerCommand,
} from '@bfly/annotation-tools';

import { EllipseData, PathData, RectData, Trace } from '../types.d';
import RectTool, { RectData as ToolRectData } from './RectTool';
import { toolColor } from '@bfly/annotation-tools/lib/utils/Colors';

const dash = [2, 2];

interface Props {
  trace: Trace;
  results?: any;
  disabled?: boolean;
  onTrace(trace: Trace, data: EllipseData | RectData | PathData | null): void;
  onClearFrame(): void;
  registerShortcut?: (map: Record<string, () => boolean | void>) => () => void;
}

function TraceableOverlay({
  trace,
  onTrace,
  results,
  disabled,
  registerShortcut,
  onClearFrame,
}: Props) {
  const handleTrace = useCallback(
    (data: EllipseData | PathData | null) => {
      onTrace(trace, data);
    },
    [onTrace, trace],
  );

  const handlePointTrace = (point: Point) => handleTrace([point]);

  const handleEllipseOrRectTrace = useCallback(
    ({ center: [cx, cy], height, width, rotation }) => {
      handleTrace([cx, cy, height, width, rotation]);
    },
    [handleTrace],
  );

  useEffect(
    () =>
      registerShortcut?.({
        Backspace: () => triggerCommand('CANCEL_LAST_POINT'),
        Delete: () => handleTrace(null),
        '=': onClearFrame,
      }),
    [registerShortcut, handleTrace, onClearFrame],
  );

  function renderPoint() {
    const point = results && results[0];

    return (
      <PointTool
        point={point}
        color={trace.color}
        interpolated={results?.interpolated}
        disabled={disabled || results?.interpolated}
        onTrace={handlePointTrace}
      />
    );
  }

  function renderPath() {
    const { type, color } = trace;
    // the polygon number is ignored, but still required
    const numPoints = trace.type === 'line' ? trace.numPoints : 2;
    const isClosed = type === 'polygon';

    return (
      <PathTool
        isClosed={isClosed}
        onTrace={handleTrace}
        disabled={disabled || results?.interpolated}
        data={results}
        color={color}
        dath={dash}
        dashEnabled={results?.interpolated}
        pathNumPoints={numPoints}
      />
    );
  }

  function renderEllipse() {
    const { color } = trace;

    let ellipse: ToolEllipseData | undefined;
    if (results) {
      const [cx, cy, height, width, rotation] = results;
      ellipse = { center: [cx, cy], height, width, rotation };
    }

    return (
      <EllipseTool
        color={color}
        data={ellipse}
        disabled={disabled}
        onTrace={handleEllipseOrRectTrace}
      />
    );
  }

  function renderRect() {
    const { color } = trace;

    let rect: ToolRectData | undefined;
    if (results) {
      const [cx, cy, height, width, rotation] = results;
      rect = { center: [cx, cy], height, width, rotation };
    }

    return (
      <RectTool
        color={color}
        data={rect}
        disabled={disabled}
        onTrace={handleEllipseOrRectTrace}
      />
    );
  }
  if (!trace.color) {
    // eslint-disable-next-line no-param-reassign
    trace.color = toolColor;
  }

  if (trace.type === 'point') return renderPoint();
  if (trace.type === 'ellipse') return renderEllipse();
  if (trace.type === 'rectangle') return renderRect();
  return renderPath();
}

export default TraceableOverlay;
