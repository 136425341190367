import PropTypes from 'prop-types';
import React from 'react';
import Layout from '@4c/layout';

import GlyphControl from './GlyphControl';

const styles = css`
  .controls {
    margin: 1rem;
    text-align: center;
  }
`;

const propTypes = {
  paused: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onIncrementFrameIndex: PropTypes.func.isRequired,
  onTogglePaused: PropTypes.func.isRequired,
};

const defaultProps = {
  disabled: false,
};

class CineControls extends React.Component {
  onClickFastBackward = () => {
    this.props.onIncrementFrameIndex(-10);
  };

  onClickStepBackward = () => {
    this.props.onIncrementFrameIndex(-1);
  };

  onClickTogglePaused = () => {
    this.props.onTogglePaused(!this.props.paused);
  };

  onClickStepForward = () => {
    this.props.onIncrementFrameIndex(1);
  };

  onClickFastForward = () => {
    this.props.onIncrementFrameIndex(10);
  };

  render() {
    const { disabled, paused } = this.props;

    return (
      <div className={styles.controls}>
        <Layout pad={4} justify="center">
          <GlyphControl
            theme="link"
            glyph="fast-backward"
            disabled={disabled}
            onClick={this.onClickFastBackward}
          />
          <GlyphControl
            theme="link"
            glyph="step-backward"
            disabled={disabled}
            onClick={this.onClickStepBackward}
          />
          <GlyphControl
            theme="link"
            glyph={paused ? 'play' : 'pause'}
            disabled={disabled}
            onClick={this.onClickTogglePaused}
          />
          <GlyphControl
            theme="link"
            glyph="step-forward"
            disabled={disabled}
            onClick={this.onClickStepForward}
          />
          <GlyphControl
            theme="link"
            glyph="fast-forward"
            disabled={disabled}
            onClick={this.onClickFastForward}
          />
        </Layout>
      </div>
    );
  }
}

CineControls.propTypes = propTypes;
CineControls.defaultProps = defaultProps;

export default CineControls;
