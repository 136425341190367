import { css } from 'astroturf';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'react-spinner';

const styles = css`
  .parent {
    height: 5rem;

    & :global(.react-spinner_bar) {
      background-color: #555;
    }
  }
`;

const propTypes = {
  className: PropTypes.string,
};

function LoadingIndicator({ className, ...props }) {
  return (
    <div {...props} className={classNames(className, styles.parent)}>
      <Spinner />
    </div>
  );
}

LoadingIndicator.propTypes = propTypes;

export default LoadingIndicator;
