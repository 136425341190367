import React from 'react';
import Form from '@bfly/ui/Form';

import { useShortcuts } from './KeyboardShortcutManager';
import ToggleButtonGroup from './ToggleButtonGroup';

export const EraseTag = {
  value: 'erase',
  shortcut: 'Delete',
  displayText: 'Erase',
};

const propTypes = {};

function IntervalsInput({ activeInterval, activeTrace, ...props }) {
  const handleChange = (tagValue) => {
    const { value, onChange } = props;

    if (!activeInterval) return;

    const [startFrameIndex, endFrameIndex] = activeInterval;
    const nextValue = { ...value };

    for (let idx = startFrameIndex; idx < endFrameIndex + 1; idx++) {
      // for now we just support the single value
      if (tagValue !== EraseTag.value) nextValue[idx] = [tagValue];
      else if (nextValue[idx]) delete nextValue[idx];
    }

    onChange(nextValue);
  };
  const deleteInterval = () => {
    // we pass in activeTrace to prevent deleting intervals unintentionally if
    // we are just trying to delete points during an active trace.
    if (activeTrace) {
      return;
    }
    handleChange(EraseTag.value);
  };

  useShortcuts({
    Delete: deleteInterval,
    Backspace: deleteInterval,
  });

  return <ToggleButtonGroup {...props} onChange={handleChange} />;
}

function IntervalsField({ intervals, activeInterval, activeTrace, readOnly }) {
  const { questionId, ...config } = intervals;
  // TODO: this shouldn't happen
  if (config.type !== 'tag') return null;

  return (
    <Form.FieldGroup
      as={IntervalsInput}
      label={config.displayText}
      activeInterval={activeInterval}
      activeTrace={activeTrace}
      name={`intervals.${questionId}`}
      readOnly={readOnly}
      disabled={!activeInterval}
      data={config.tagOptions}
    />
  );
}

IntervalsField.propTypes = propTypes;

export default IntervalsField;
