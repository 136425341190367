import auth0, { Auth0DecodedHash } from 'auth0-js';
import store from 'store/dist/store.modern';

const KEY = '@bfly-label-collector/token';

export interface AuthResponse {
  expiresAt: number;
  accessToken: string;
}

export default class Auth {
  public authResponse: AuthResponse | null;

  private auth0: auth0.WebAuth;

  private tokenRenewalTimeout: number | undefined;

  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: window.bflyConfig.AUTH0_DOMAIN,
      audience: window.bflyConfig.AUTH0_AUDIENCE,
      clientID: window.bflyConfig.AUTH0_CLIENT_ID,
      redirectUri: `${window.location.origin}/login`,
      responseType: 'token',
      scope: 'openid profile',
    });

    this.authResponse = this.loadAuthResponse();
  }

  loadAuthResponse() {
    return store.get(KEY);
  }

  logout() {
    store.remove(KEY);
    clearTimeout(this.tokenRenewalTimeout);
    this.authResponse = null;
    this.auth0.logout({
      returnTo: `${window.location.origin}/logout`,
    });
  }

  login(params?) {
    if (this.isAuthenticated()) return;

    this.auth0.authorize({
      ...params,
    });
  }

  acceptToken(): Promise<Auth0DecodedHash | null> {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          reject(err);
          return;
        }
        if (!authResult) {
          resolve(null);
          return;
        }

        this.setSession(authResult);
        resolve(authResult);
      });
    });
  }

  renewSession() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResponse) => {
        if (err) {
          this.logout();
          reject(err);
          return;
        }
        if (!authResponse) {
          resolve(null);
          return;
        }

        this.setSession(authResponse);
      });
    });
  }

  setSession(authRespone) {
    // Set the time that the Access Token will expire at
    this.authResponse = authRespone;
    this.authResponse!.expiresAt = authRespone.expiresIn * 1000 + Date.now();
    store.set(KEY, this.authResponse);

    this.scheduleRenewal();
  }

  isAuthenticated() {
    if (!this.authResponse) return false;
    return Date.now() < this.authResponse.expiresAt;
  }

  scheduleRenewal() {
    const { expiresAt } = this.authResponse!;
    const timeout = expiresAt - Date.now();

    if (timeout > 0) {
      this.tokenRenewalTimeout = window.setTimeout(this.renewSession, timeout);
    }
  }
}
