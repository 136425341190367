import { InferType, number, object, string } from 'yup';

export const CustomEarningsSchema = object({
  username: string().trim().lowercase().required(),
  title: string().required(),
  description: string().nullable(),
  amount: number().required(),
});

export type CustomEarnings = InferType<typeof CustomEarningsSchema>;
