import React, { useContext, useEffect, useState } from 'react';
import { Match } from 'found';
import Link from 'found/lib/Link';
import isEmpty from 'lodash/isEmpty';
import ProgressBar from 'react-bootstrap/ProgressBar';
import BlankSlate from '@bfly/ui/BlankSlate';
import ToastContext, { ToastManager } from '@bfly/ui/ToastContext';

import Table from 'components/Table';
import { useApi } from 'components/AuthProvider';
import { Worklist, deserialize } from 'src/schema/Worklist';
import executeWithErrorToast from 'src/utils/executeWithErrorToast';
import CurateWorklistModal from 'src/components/CurateWorklistModal';
import WorklistDownloadButton from 'components/WorklistDownloadButton';
import DuplicateWorklistModal from 'src/components/DuplicateWorklistModal';
import WorklistDropdownOptions from 'src/components/WorklistDropdownOptions';

async function getData({ params, context }) {
  const { taskId } = params;
  const { api } = context;

  const worklists = await api
    .getWorklistsForTask({ taskName: taskId })
    .then((serializedWorklists) =>
      serializedWorklists.map((d) => deserialize(d)),
    );

  return {
    worklists,
    api,
  };
}

interface Props {
  match: Match;
  data: {
    worklists: Worklist[];
  };
}

function TaskWorklistsPage({ data, match }: Props) {
  const api = useApi();
  const { worklists } = data;
  const { taskId } = match.params;
  const toast = useContext<ToastManager | null>(ToastContext);
  const [userWorklists, setUserWorklists] = useState(worklists);
  const [hideCurateModal, setHideCurateModal] = useState(true);
  const [hideDuplicateModal, setHideDuplicateModal] = useState(true);
  const [selectedWorklist, setSelectedWorklist] = useState<Worklist | null>(
    null,
  );

  const hasData = !isEmpty(userWorklists);

  const handleDeleteWorklist = async (worklist: Worklist) => {
    await executeWithErrorToast(toast, async () => {
      await api.clearWorklist(worklist.id);
      await api.deleteWorklist(worklist.id);
    });
    toast!.success('Worklist deleted');
    setUserWorklists(
      userWorklists.filter((userWorklist) => worklist.id !== userWorklist.id),
    );
  };

  const handleClearWorklist = async (worklist: Worklist) => {
    await executeWithErrorToast(toast, () => api.clearWorklist(worklist.id));
    toast!.success('Worklist cleared');
    setUserWorklists(
      userWorklists.map((userWorklist) => {
        if (userWorklist.id === worklist.id) {
          // eslint-disable-next-line
          userWorklist = {
            ...userWorklist,
            completedRatio: 100,
            numAssignments: userWorklist.numCompletedAssignments,
          };
        }
        return userWorklist;
      }),
    );
  };

  useEffect(() => {
    document.title = `Task Worklists - ${taskId} -  Butterfly`;
  });

  return (
    <>
      {hasData ? (
        <>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Username</th>
                <th css="text-align: center;">
                  Last
                  <br />
                  Completed
                </th>
                <th css="text-align: center;">Completed</th>
                <th css="text-align: center;">Progress</th>
                <th />
                <th>
                  <WorklistDownloadButton taskName={taskId} />
                </th>
              </tr>
            </thead>
            <tbody>
              {userWorklists.map((worklist) => (
                <tr key={worklist.id}>
                  <td>
                    <Link
                      to={`/-/admin/users/${worklist.username}/worklists/${worklist.id}`}
                    >
                      {worklist.name}
                    </Link>
                  </td>

                  <td>{worklist.username}</td>

                  <td>{worklist.lastCompleted?.toLocaleDateString()}</td>

                  <td css="text-align: center;">
                    {worklist.numCompletedAssignments} /{' '}
                    {worklist.numAssignments}
                  </td>

                  <td css="text-align: center;">
                    <ProgressBar
                      now={worklist.completedRatio}
                      variant={
                        worklist.numCompletedAssignments ===
                        worklist.numAssignments
                          ? 'success'
                          : undefined
                      }
                    />
                  </td>

                  <td>
                    {worklist.completedRatio > 0 && (
                      <WorklistDownloadButton worklistId={worklist.id} />
                    )}
                  </td>

                  <td>
                    <WorklistDropdownOptions
                      worklist={worklist}
                      setSelectedWorklist={setSelectedWorklist}
                      setHideCurateModal={setHideCurateModal}
                      setHideDuplicateModal={setHideDuplicateModal}
                      handleClearWorklist={handleClearWorklist}
                      handleDeleteWorklist={handleDeleteWorklist}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {selectedWorklist && (
            <>
              <CurateWorklistModal
                worklist={selectedWorklist}
                show={!hideCurateModal}
                setHideCurateModal={setHideCurateModal}
              />
              <DuplicateWorklistModal
                worklist={selectedWorklist}
                hideModal={!hideDuplicateModal}
                setHideModal={setHideDuplicateModal}
              />
            </>
          )}
        </>
      ) : (
        <BlankSlate>
          <BlankSlate.Title>No Assigned Tasks</BlankSlate.Title>
        </BlankSlate>
      )}
    </>
  );
}

TaskWorklistsPage.getData = getData;

export default TaskWorklistsPage;
