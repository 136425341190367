import { css } from 'astroturf';
import { Match } from 'found';
import keyBy from 'lodash/keyBy';
import React, { useCallback, useEffect, useState } from 'react';
import { useToast } from '@bfly/ui/ToastContext';

import { useApi } from 'components/AuthProvider';
import ContractRow from 'components/ContractRow';
import Table from 'components/Table';
import { Contract, Task } from 'src/models';
import schema, { serialize } from 'src/schema/Contract';
import useRequestCache from 'src/utils/useRequestCache';

const styles = css`
  .rate {
    min-width: 12rem;
  }

  .grade {
    min-width: 18rem;
    text-align: left !important;
  }
`;

async function getData({ params, context }) {
  const { username } = params;
  const { api } = context;

  const [contracts, tasks] = await Promise.all([
    api.getContracts(username),
    api.getAllTasks(),
  ]);

  const taskToReview = keyBy(
    tasks,
    'latest_version.definition.task_to_review',
  );

  const tasksWithReview = tasks
    .filter((task) => task.task_type !== 'review')
    .map((task) => ({
      ...task,
      reviewTask: taskToReview[task.name],
    }));

  return {
    tasks: tasksWithReview,
    contracts,
  };
}

interface Props {
  match: Match;
  data: {
    tasks: Array<Task & { reviewTask?: Task }>;
    contracts: Contract[];
  };
}

function UserContractPage({ data, match: { params } }: Props) {
  const api = useApi();
  const toast = useToast();

  const { username } = params;
  const { contracts } = data;

  const [contractsByTask, setContractsByTask] = useState(() =>
    contracts.reduce((acc, contract) => {
      acc[contract.task] = schema.cast(contract);
      return acc;
    }, {}),
  );

  const onSave = useCallback(
    (task, value) => {
      const formData: { [key: string]: any } = serialize(value);

      // We return a Promise that resolves _after_ the new state is updated
      // ensuring that the next request has access to the most current state
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const existing = contractsByTask[task];

        // change events can be queued before the create happens
        // we should check our local state to see if this task has already been updated
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (existing) formData.contract_id = existing.contractId;

        try {
          const contract = !existing
            ? await api.createContract(formData)
            : await api.updateContract(existing.contractId, formData);

          setContractsByTask((c) => ({
            ...c,
            [task]: schema.cast(contract),
          }));
          resolve();
        } catch (e) {
          reject(e);
        }
      }).catch((e) => {
        toast!.error(e.message);
      });
    },
    [api, toast, contractsByTask],
  );

  const requestCache = useRequestCache(onSave);

  useEffect(() => {
    document.title = `User Contracts - ${username} - Butterfly`;
  });

  return (
    <div className="px-4">
      <Table>
        <thead>
          <tr>
            <th>Task</th>
            <th className={styles.rate}>Rate</th>
            <th className={styles.grade}>Grade</th>
            <th className="px-0">Assign</th>
          </tr>
        </thead>
        <tbody>
          {data.tasks.map((task) => (
            <React.Fragment key={task.name}>
              <ContractRow
                username={params.username}
                contract={contractsByTask[task.name]}
                onChange={(value) => requestCache.save(task.name, value)}
                task={task}
              />
              {task.reviewTask && (
                <ContractRow
                  isReviewTask
                  task={task.reviewTask}
                  username={params.username}
                  contract={contractsByTask[task.reviewTask.name]}
                  onChange={(value) =>
                    requestCache.save(task.reviewTask!.name, value)
                  }
                />
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Object.assign(UserContractPage, { getData });
