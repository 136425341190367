/**
 * A Queue-like hook that debounces requests such that only the last queued
 * value is processed after the current pending one finishes. Useful for
 * preventing network requests while one is currently inflight but will fire
 * the next one with the most recent value when finished
 */
import { useCallback, useRef } from 'react';

interface RequestQueue<T> {
  save: (key: string, value: T) => void;
}
export default function useRequestCache<T = any>(
  onSave: (key: string, value: T) => Promise<T>,
): RequestQueue<T> {
  const cache = useRef(new Map());

  const processValuesForKey = useCallback(
    (key) => {
      const state = cache.current.get(key);

      const next = () => {
        state.currentRequest = null;
        processValuesForKey(key);
      };

      if (!state.currentRequest && state.nextValue) {
        state.currentRequest = onSave(key, state.nextValue).then(next, next);
        state.nextValue = null;
      }
    },
    [onSave],
  );

  const save = useCallback(
    (key, value) => {
      let state = cache.current.get(key);

      if (!state) {
        cache.current.set(
          key,
          (state = {
            currentRequest: null,
            nextValue: null,
          }),
        );
      }

      state.nextValue = value;
      processValuesForKey(key);
    },
    [processValuesForKey],
  );

  return { save };
}
