/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import Layout from '@4c/layout';
import FieldSet from '@bfly/ui/FieldSet';

import { EraseTag } from './IntervalsField';
import { useTriggerShortcut } from './KeyboardShortcutManager';
import Shortcut from './Shortcut';
import TagButton from './TagButton';

const hasOpenTrace = (traces) => traces.find((t) => t.numPoints === 0);

// TODO: this should probably only show when the _active_ trace is not an ellipse
const onlyEllipsesOrRects = (traces) =>
  traces.every((t) => t.type === 'ellipse' || t.type === 'rectangle');

const propTypes = {};

function triggerFinishTrace() {
  document.dispatchEvent(new MouseEvent('mouseup', { ctrlKey: true }));
}

function CommonShortcuts({
  task,
  readOnly,
  hasEnabledFrames,
  hasDlResultOverlays,
}) {
  const triggerDelete = useTriggerShortcut('Delete');
  const triggerDeleteFrame = useTriggerShortcut('=');
  const cancelPoint = useTriggerShortcut('Backspace');

  const pause = useTriggerShortcut(' ');
  const showInstructions = useTriggerShortcut('-');
  const submit = useTriggerShortcut('Enter');
  let skip = useTriggerShortcut('Escape');

  const nextEnabledFrame = useTriggerShortcut('Alt+ArrowRight');
  const prevEnabledFrame = useTriggerShortcut('Alt+ArrowLeft');

  const toggleDlResultOverlays = useTriggerShortcut('h');

  if (!task.definition.skip) skip = null;

  return (
    <FieldSet legend="Common Shortcuts" className="mt-4">
      <Layout pad direction="column" align="flex-start" justify="center">
        {hasDlResultOverlays && (
          <TagButton onClick={toggleDlResultOverlays}>
            <Shortcut char="h" color="#000" /> Hide / Show Model
          </TagButton>
        )}
        {!readOnly && task.definition.intervals && (
          <>
            <TagButton>
              <Shortcut char="z" color="#000" /> Mark start & end of interval
            </TagButton>
            <TagButton onClick={triggerDelete}>
              <Shortcut char={EraseTag.shortcut} color="#000" /> Erase Interval
            </TagButton>
          </>
        )}
        {!readOnly && task.definition.traces && (
          <>
            {hasOpenTrace(task.definition.traces) && (
              <TagButton onClick={triggerFinishTrace}>
                <Shortcut color="#000" title="control + click">
                  {'ctrl +'}
                  <i
                    className="fas fa-mouse-pointer"
                    style={{ fontSize: '85%' }}
                  />
                </Shortcut>{' '}
                End Unbound Trace
              </TagButton>
            )}
            {!onlyEllipsesOrRects(task.definition.traces) && (
              <TagButton onClick={cancelPoint}>
                <Shortcut char="Backspace" color="#000" /> Cancel Last Point
              </TagButton>
            )}
            <TagButton onClick={triggerDelete}>
              <Shortcut char="Delete" color="#000" /> Delete Active Trace
            </TagButton>

            <TagButton onClick={triggerDeleteFrame}>
              <Shortcut char="=" color="#000" /> Clear Current Frame
            </TagButton>
          </>
        )}
        {!readOnly && skip && (
          <TagButton onClick={skip}>
            <Shortcut char="Escape" color="#000" /> Skip
          </TagButton>
        )}
        <TagButton onClick={pause}>
          <Shortcut char="Space" color="#000" /> Pause / Play Cine
        </TagButton>
        {!readOnly && (
          <TagButton onClick={submit}>
            <Shortcut char="Enter" color="#000" /> Submit
          </TagButton>
        )}
        {hasEnabledFrames ? (
          <>
            <TagButton onClick={prevEnabledFrame}>
              <Shortcut color="#000" title="alt + arrow left">
                {'alt +'}
                <i className="fas fa-arrow-left" style={{ fontSize: '85%' }} />
              </Shortcut>
              Prev Enabled Frame
            </TagButton>
            <TagButton onClick={nextEnabledFrame}>
              <Shortcut color="#000" title="alt + arrow right">
                {'alt +'}
                <i
                  className="fas fa-arrow-right"
                  style={{ fontSize: '85%' }}
                />
              </Shortcut>
              Next Enabled Frame
            </TagButton>
          </>
        ) : null}
        <TagButton onClick={showInstructions}>
          <Shortcut char="-" color="#000" /> Task Instructions
        </TagButton>
      </Layout>
    </FieldSet>
  );
}

CommonShortcuts.propTypes = propTypes;

export default CommonShortcuts;
