import React, { useCallback } from 'react';
import DropdownList from '@bfly/ui/DropdownList';
import FormCheckGroup from '@bfly/ui/FormCheckGroup';
import { withThemeContext } from '@bfly/ui/ThemeContext';

import ToggleButtonGroup from './ToggleButtonGroup';

interface Props {
  id: string;
  name: string;
  value: any;
  onChange: (value: any) => void;
  theme?: 'light' | 'dark';
  readOnly?: boolean;
  configuration: {
    style: 'checkbox' | 'dropdown' | 'button';
    tagOptions: any[];
  };
}

function TagInput({
  id,
  name,
  configuration,
  value,
  onChange,
  theme,
  readOnly,
}: Props) {
  const { style } = configuration;
  const onValueChange = useCallback(({ value: v }) => onChange(v), [onChange]);
  const onValuesChange = useCallback(
    (items) => {
      onChange(
        // items is an array of objects when selecting another item, and an
        // array of values when deselecting an item
        items.map((d) => d.value || d),
      );
    },
    [onChange],
  );

  if (style === 'checkbox') {
    return (
      <FormCheckGroup
        id={id}
        name={name}
        value={value}
        onChange={onValuesChange}
        idField="value"
        theme={theme}
        disabled={readOnly}
        data={configuration.tagOptions}
        renderItem={(item) => item.displayText}
      />
    );
  }

  if (style === 'dropdown') {
    return (
      <DropdownList
        variant={theme}
        value={value}
        onChange={onValueChange}
        data={configuration.tagOptions}
        dataKey="value"
        textField="displayText"
        readOnly={readOnly}
      />
    );
  }

  return (
    <ToggleButtonGroup
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      data={configuration.tagOptions}
    />
  );
}

// FIXME: this is fixed upstream, awaiting release
export default withThemeContext(TagInput);
