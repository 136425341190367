import React, { useCallback, useState } from 'react';
import { useToast } from '@bfly/ui/ToastContext';
import { Match } from 'found';

import Table from 'components/Table';
import ContractRow from 'components/ContractRow';
import { useApi } from 'components/AuthProvider';
import { Contract } from 'src/models';
import schema, { serialize } from 'src/schema/Contract';
import useRequestCache from 'src/utils/useRequestCache';

async function getData({ params, context }) {
  const { taskId } = params;
  const { api } = context;

  const contracts = await api.getContractsByTask({ taskName: taskId });
  return {
    contracts,
  };
}

interface Props {
  match: Match;
  data: {
    contracts: Array<Contract & { user?: string }>;
  };
}

function TaskContractsPage({ data, match: { params } }: Props) {
  const { taskId } = params;
  const castedContracts = data.contracts.map((contract) =>
    schema.cast(contract),
  );

  const [contracts, setContracts] = useState(castedContracts);

  const api = useApi();
  const toast = useToast();

  const onSave = useCallback(
    async (_, value) => {
      const formData: { [key: string]: any } = serialize(value);

      try {
        await api.updateContract(formData.contract_id, formData);
        const fetchedContracts = await api.getContractsByTask({
          taskName: taskId,
        });
        setContracts(fetchedContracts);
      } catch (e) {
        toast!.error(e.message);
      }
    },
    [api, taskId, toast],
  );

  const requestCache = useRequestCache(onSave);

  return (
    <Table>
      <thead>
        <tr>
          <th>User</th>
          <th>Rate</th>
          <th>Grade</th>
          <th>Assign</th>
        </tr>
      </thead>
      <tbody>
        {contracts.map((contract) => (
          <React.Fragment key={Math.random()}>
            <ContractRow
              contract={schema.cast(contract)}
              onChange={(value) =>
                requestCache.save(contract.contractId, value)
              }
              task={contract.task}
              showUserInfo
            />
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
}

TaskContractsPage.getData = getData;

export default TaskContractsPage;
