import React, { useMemo } from 'react';
import FormControl from '@bfly/ui/FormControl';
import debounce from 'lodash/debounce';

const DEBOUNCE_TIMEOUT = 200;

interface Props {
  onSearch: (string?) => void;
  onChange: (string?) => void;
  value?: string;
}

function SearchBox({ onSearch, onChange, value = '' }: Props) {
  const debouncedSearch = useMemo(
    () => debounce(onSearch, DEBOUNCE_TIMEOUT),
    [onSearch],
  );

  const handleSearchChange = (event) => {
    onChange(event.target.value);
    debouncedSearch(event.target.value);
  };

  return (
    <FormControl
      id="search"
      theme="dark"
      type="text"
      placeholder="Search..."
      value={value}
      onChange={handleSearchChange}
    />
  );
}

export default SearchBox;
