import React from 'react';

import { useShortcut } from './KeyboardShortcutManager';

const DisplayText = {
  Delete: 'del',
  Escape: 'esc',
  Backspace: 'bksp',
};
const styles = css`
  .shortcut {
    composes: text-monospace from global;
    padding: 0 0.4rem;
    border-radius: 3px;
    margin-right: 1rem;
  }
`;

function getColor(hex) {
  // eslint-disable-next-line no-param-reassign
  if (hex.length === 4) hex += hex.slice(1);
  return +hex.replace('#', '0x') > 0xffffff / 2 ? '#000' : '#fff';
}

export default function Shortcut({
  color = '#45b562',
  char,
  title,
  handler,
  children,
}) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (handler) useShortcut(char, handler);

  return (
    <kbd
      className={styles.shortcut}
      title={title || char}
      style={{ backgroundColor: color, color: getColor(color) }}
    >
      {children || (DisplayText[char] || char).toLowerCase()}
    </kbd>
  );
}
