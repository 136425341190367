import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import toPairs from 'lodash/toPairs';
import { array, bool, date, lazy, mixed, number, object, string } from 'yup';

const Strip = mixed().default(null).strip();

function createAnswerSchema({ type, ...config }, skipped) {
  let schema = mixed().nullable();
  let { defaultValue } = config;

  switch (type) {
    case 'string':
      schema = string();
      break;
    case 'bool':
      schema = bool();
      if (defaultValue == null) defaultValue = false;
      break;
    case 'number':
      schema = number();

      if (config.isInt) schema = schema.integer();
      if (config.minimum != null) schema = schema.min(config.minimum);
      if (config.maximum != null) schema = schema.max(config.maximum);

      break;
    case 'tag':
      if (config.style === 'checkbox') {
        schema = array().of(string());
        defaultValue = [];
      } else {
        schema = string().nullable();
      }
      break;
    default:
      throw new Error(`unrecognized question type: ${type}`);
  }

  if (config.required && !skipped)
    schema = schema.required(`${config.displayText} is required.`);

  if (config.dependsOn) {
    const { questionId, values } = config.dependsOn;

    schema = mixed().when(questionId, {
      is: (otherValue) => values.includes(otherValue),
      then: schema,
      otherwise: (x) => x.strip(),
    });
  }

  // default added last so it's always set regardless of
  // whether the field needs to be resolved and stripped later
  return schema.default(defaultValue);
}

function keyObjectByIndex(inputArray) {
  if (!Array.isArray(inputArray)) return inputArray;

  const result = {};
  inputArray.forEach(([idx, tags]) => {
    result[idx] = tags || [];
  });
  return result;
}

/**
 * `label` may be a server returned label or the current form value. Note that
 * these have different shapes!
 */
const Label = lazy((label, opts) => {
  const { task } = opts.context;
  // skipped will be on the results when returned from the server, and directly on
  // the label while annotating.
  const { skipped } = label?.annotations || label || {};
  const skipQuestion = task.skip?.question;

  let traces, answers, intervals;
  if ((task.type === 'pixel' || task.type === 'graph') && task.traces) {
    const fields = {};
    task.traces.forEach((q) => {
      fields[q.traceId] = array().default([]);
    });

    traces = object(fields);
    if (!skipped) {
      traces = traces.test(
        'trace-required',
        'Missing required traces.',
        (result) =>
          result &&
          task.traces
            .filter((trace) => trace.required)
            .every((t) => result[t.traceId] && result[t.traceId].length),
      );
    }
  }
  if (task.intervals) {
    const intervalId = task.intervals.questionId;

    let interval = mixed();
    if (!skipped && task.intervals.required) {
      interval = interval.test(
        'not-empty',
        'Please label at least one interval',
        (castValue) => Object.keys(castValue || {}).length !== 0,
      );
    }

    intervals = object({
      [intervalId]: interval,
    })
      // The input and output format for intervals is an array of potentially
      // non-contiguous frame indices and traceId. For updating in the form
      // it's easier to work with an object hash keyed by index so we we convert
      // to that here
      .transform((inputValue) => ({
        [intervalId]: inputValue && keyObjectByIndex(inputValue[intervalId]),
      }));
  }

  if (task.questions) {
    const fields = {};
    task.questions.forEach((question) => {
      fields[question.questionId] = createAnswerSchema(question, skipped);
    });

    answers = object(fields);
  }

  return object({
    traces,
    answers,
    intervals,
    assignmentId: string(),
    enabled: bool(),
    startFrame: number().nullable(),
    endFrame: number().nullable(),
    annotatorId: string().required(),
    annotatedOn: date()
      .required()
      .default(() => new Date(Date.now())),
    version: number(),

    activeInterval: array().of(number()).default(null).nullable(),

    activeTrace: string().default(null).nullable(),

    skipped: bool().default(false),
    skipAnswer: skipQuestion
      ? createAnswerSchema(skipQuestion, !skipped)
      : Strip,
  }).transform((existingLabel) => {
    const { annotations, assignment } = existingLabel || {};

    if (!annotations || !assignment) return existingLabel;

    return {
      assignmentId: existingLabel.assignment_id,
      enabled: existingLabel.enabled,
      annotatorOn: assignment.created_at,
      annotatorId: assignment.username,
      startFrame: assignment.start_frame,
      endFrame: assignment.end_frame,
      skipped: annotations.skipped,
      skipAnswer: skipQuestion && annotations[skipQuestion.questionId],
      answers: pick(
        annotations,
        task.questions?.map((q) => q.questionId),
      ),
      traces: pick(
        annotations,
        task.traces?.map((q) => q.traceId),
      ),
      intervals: task.intervals && {
        [task.intervals.questionId]: annotations[task.intervals.questionId],
      },
    };
  });
});

const SnakeCase = object().snakeCase();
export default Label;

export const deserialize = (existingLabel, task, viewer) => {
  // we cast a pixel task's intervals array to the first element
  // to match the Label schema
  if (Array.isArray(task.intervals) && task.intervals.length) {
    // eslint-disable-next-line no-param-reassign
    task.intervals = task.intervals[0];
  }
  const result = Label.cast(existingLabel, {
    context: { task },
    stripUnknown: true,
  });

  result.annotatorId = viewer.username;
  return result;
};

export const serialize = (value, task) => {
  const context = { task };
  const { traces, intervals, answers, skipped, skipAnswer, ...rest } =
    Label.resolve({ value, context })
      .shape({
        activeTrace: bool().strip(),
        activeInterval: array().strip(),
        // Here we convert back each interval from an index object hash to an array
        intervals: object().transform(
          (_, v) =>
            v &&
            mapValues(v, (interval) =>
              toPairs(interval).map((p) => {
                // eslint-disable-next-line no-param-reassign
                p[0] = parseInt(p[0], 10);
                return p;
              }),
            ),
        ),
      })
      .cast(value, { context });

  if (skipped) {
    rest.results = { skipped };
    if (task.skip?.question)
      rest.results[task.skip.question.questionId] = skipAnswer;
  } else {
    rest.results = { ...traces, ...answers, ...intervals };
  }

  return SnakeCase.cast(rest);
};
