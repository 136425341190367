import { css } from 'astroturf';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Layout from '@4c/layout';
import { useApi } from './AuthProvider';

import DeferredValue from './DeferredValue';
import { CALCULATION_TYPES } from '../utils/calculations';

const styles = css`
  .calcs {
    color: white;
  }
`;

const propTypes = {
  // The file being annotated.
  file: PropTypes.object.isRequired,

  // The list of metrics to display.
  calculations: PropTypes.arrayOf(PropTypes.object.isRequired),

  // The annotations provided on screen.
  traceAnnotations: PropTypes.object.isRequired,
};

function Calculations({ file, calculations, traceAnnotations }) {
  const api = useApi();

  if (!calculations || !calculations.length) {
    return null;
  }

  return (
    <Layout pad className={styles.calcs} direction="column" align="flex-end">
      {calculations.map(
        ({ calculationType, displayText, calculationId, visible }) => {
          const metric = CALCULATION_TYPES[calculationType];

          if (!metric || !visible) return null; // TODO: Handle more gracefully

          return (
            <Layout key={calculationId} align="center" pad>
              <span>{displayText}:</span>
              <DeferredValue
                promise={Promise.resolve(
                  metric.getValue(api, file, traceAnnotations),
                )}
              />
            </Layout>
          );
        },
      )}
    </Layout>
  );
}

Calculations.propTypes = propTypes;

// This is assuming all calculation methods are pure
// and prevents recalculation when the inputs haven't changed
export default memo(Calculations, (prevProps, nextProps) => {
  const areEqual =
    prevProps.file === nextProps.file &&
    prevProps.traceAnnotations === nextProps.traceAnnotations &&
    prevProps.calculations === nextProps.calculations;

  return areEqual;
});
