import { object } from 'yup';

import { analyticsArrayType } from '../utils/AnalyticsChart';

export const fileStudyPerMonthSchema = object({
  date: analyticsArrayType,
  preset: analyticsArrayType,
  studyCount: analyticsArrayType,
  fileCount: analyticsArrayType,
}).camelCase();

export const sourcingVelocitySchema = object({
  weeksAgo: analyticsArrayType,
  preset: analyticsArrayType,
  organizationName: analyticsArrayType,
  count: analyticsArrayType,
}).camelCase();

export const projectDistributionSchema = object({
  splitName: analyticsArrayType,
  count: analyticsArrayType,
}).camelCase();
