import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '@4c/layout';
import BlankSlate from '@bfly/ui/BlankSlate';
import MainContent from '@bfly/ui/MainContent';
import Navbar from '@bfly/ui/Navbar';

import { useAuth } from './AuthProvider';
import Page from './Page';

function ConnectionErrorPage() {
  const auth = useAuth();

  const onClickLogOut = () => {
    auth.logout();
  };

  return (
    <>
      <Navbar>
        <Layout grow overflow="visible" />
        {auth?.isAuthenticated() && (
          <Navbar.Button onClick={onClickLogOut}>
            <FormattedMessage
              defaultMessage="Log Out"
              id="connectionErrorPage.logOut"
            />
          </Navbar.Button>
        )}
      </Navbar>
      <Page.Container center>
        <MainContent centerText>
          <BlankSlate>
            <BlankSlate.Title>
              <FormattedMessage
                defaultMessage="An error occurred while connecting to Butterfly Annotator Cloud."
                id="connectionErrorPage.title"
              />
            </BlankSlate.Title>
            <BlankSlate.Body>
              <FormattedMessage
                defaultMessage="To retry, refresh the page."
                id="connectionErrorPage.body"
              />
            </BlankSlate.Body>
            <BlankSlate.Action href="mailto:annotator-support@butterflynetwork.com">
              <FormattedMessage
                defaultMessage="Contact Support"
                id="connectionErrorPage.support"
              />
            </BlankSlate.Action>
          </BlankSlate>
        </MainContent>
      </Page.Container>
    </>
  );
}

export default ConnectionErrorPage;
