/* Contains the form for creating and editing tasks, within the
TaskDefinitionPage */

import styled from 'astroturf';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import CardDeck from 'react-bootstrap/CardDeck';
import BaseForm from 'react-formal';
import NestedForm from 'react-formal/lib/NestedForm';
import Layout from '@4c/layout';
import Cine from '@bfly/icons/Cine';
import Edit from '@bfly/icons/Edit';
import Button from '@bfly/ui/Button';
import Card from '@bfly/ui/Card';
import Form from '@bfly/ui/Form';
import ToastContext from '@bfly/ui/ToastContext';

import AnnotationTask, {
  Intervals,
  deserializeDefinition,
  serializeDefinition,
} from '../schema/AnnotationTask';
import executeWithErrorToast from '../utils/executeWithErrorToast';
import * as Prism from '../utils/Prism';
import { useApi } from './AuthProvider';
import CalculationsForm from './CalculationsForm';
import IntervalsForm from './IntervalsForm';
import JsonTaskDefinitionModal from './JsonTaskDefinitionModal';
import QuestionsForm, { QuestionForm } from './QuestionsForm';
import SkipForm from './SkipForm';
import TaskDefinitionControl from './TaskDefinitionControl';
import TracesForm from './TracesForm';

// XXX: Button component contains a wrapper, which is messing up spacing.
const CardButton = styled(Card).attrs({ as: 'button', type: 'button' })`
  @import '~@bfly/ui/styles/theme';

  composes: link-dark from '~@bfly/ui/Button.module.scss';

  &.active {
    border-color: $blue;
  }
`;

const propTypes = {
  task: PropTypes.object,
};

const TASK_TYPES = [
  {
    key: 'Cine',
    value: 'cine',
    description: 'Perform a cine level annotation, e.g. view classification',
    icon: <Cine height="15" className="mr-3" />,
  },
  {
    key: 'Frame',
    value: 'frame',
    description: 'Perform a frame level annotation, e.g. fetal structure',
    icon: <i className="fa fa-image mr-3" />,
  },
  {
    key: 'Pixel',
    value: 'pixel',
    description:
      'Perform a pixel level annotation, e.g. trace a line on a femur',
    icon: <Edit height="20" className="mr-3" />,
  },
];

const TYPE_FIELDS = {
  frame: () => (
    <>
      <Form.FieldSet legend="Intervals">
        <NestedForm as="div" name="definition.intervals" schema={Intervals}>
          <QuestionForm defaultTypeValue="tag" hideTagStyle />
        </NestedForm>
      </Form.FieldSet>

      <QuestionsForm />
    </>
  ),
  cine: () => (
    <>
      <QuestionsForm />
    </>
  ),
  pixel: () => (
    <>
      <TracesForm />
      <IntervalsForm />
      <QuestionsForm />
      <CalculationsForm />
    </>
  ),
};

function FormCardGroup({ value, onChange, disabled }) {
  return (
    <CardDeck>
      {TASK_TYPES.map((item) => {
        const isActive = item.value === value;

        const onClick = () => {
          onChange(item.value);
        };

        return (
          <CardButton
            key={item.value}
            disabled={disabled}
            onClick={onClick}
            active={isActive}
          >
            <Card.Header>
              {item.icon}
              {item.key}
            </Card.Header>
            <Card.Body css="text-align: left">
              <Card.Text>{item.description}</Card.Text>
            </Card.Body>
          </CardButton>
        );
      })}
    </CardDeck>
  );
}

function TaskDefinitionForm({ task, onSave, ...props }) {
  const [errors, onError] = useState(() => ({}));

  const api = useApi();
  const toast = useContext(ToastContext);
  const [showJsonModal, setShowJsonModal] = useState(false);

  const handleHide = () => setShowJsonModal(false);
  const handleShow = () => setShowJsonModal(true);

  const defaultValue = useMemo(
    () =>
      task
        ? {
            name: task.name,
            definition: deserializeDefinition(task.latest_version.definition),
          }
        : {
            ...AnnotationTask.default(),
            definition: {
              type: '',
              instructions: '',
              instructionsVimeoId: '',
            },
          },
    [task],
  );

  const [type, setType] = useState(defaultValue.definition.type);
  const [touched, setTouched] = useState({});
  const pristine = Object.keys(touched).length === 0;

  const TypeForm = type && TYPE_FIELDS[type];

  const submitForm = async (t) => {
    const updatedTask = t;
    updatedTask.task_type = 'regular';

    try {
      await AnnotationTask.validateAt('definition', updatedTask, {
        abortEarly: false,
      });
    } catch (err) {
      const formErrors = { ...errors, ...BaseForm.toErrors(err) };
      onError(formErrors);
      throw formErrors;
    }

    updatedTask.definition = serializeDefinition(updatedTask.definition);

    const updated = await executeWithErrorToast(toast, () =>
      api.createTaskVersion(updatedTask),
    );

    if (onSave) onSave(updated);
  };

  // Wrap in function to remove error concerning multiple params
  const handleTouch = (t) => setTouched(t);

  return (
    <>
      <Layout>
        <Layout.Spacer />
        <Button theme="link" onClick={handleShow}>
          JSON
        </Button>
      </Layout>

      <Form
        {...props}
        schema={AnnotationTask}
        errors={errors}
        onError={onError}
        submitForm={submitForm}
        defaultValue={defaultValue}
        onTouch={handleTouch}
        stripUnknown
      >
        <JsonTaskDefinitionModal show={showJsonModal} onHide={handleHide} />

        <Form.FieldGroup
          theme="light"
          name="name"
          label="Name"
          readOnly={!!defaultValue.name}
          autoFocus
        />

        <Form.FieldGroup
          theme="light"
          name="definition.displayText"
          label="Display Name"
        />

        <Form.FieldGroup
          name="definition.type"
          label="Type"
          as={FormCardGroup}
          onChange={setType}
        />

        <Form.FieldGroup
          name="definition.instructions"
          label="Task Instructions (markdown)"
          as={TaskDefinitionControl}
          highlight={Prism.highlightMarkdown}
          events="onChange" // don't validate
          rows="20"
        />

        <Form.FieldGroup
          theme="light"
          name="definition.instructionsVimeoId"
          label="Task Instructions Video ID"
        />

        <SkipForm defaultValue={defaultValue.definition.skip} />

        {TypeForm && <TypeForm />}

        <Layout justify="flex-end">
          <Form.Submit disabled={pristine}>Save Task</Form.Submit>
        </Layout>
      </Form>
    </>
  );
}

TaskDefinitionForm.propTypes = propTypes;

export default TaskDefinitionForm;
