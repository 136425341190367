import { InferType, date, number, object } from 'yup';

const schema = object({
  totalAmount: number(),
  startDate: date(),
  endDate: date().nullable(),
}).camelCase();

export type Earning = InferType<typeof schema>;

export const deserializeEarnings = (items) => {
  const earnings = items.map((d) => schema.cast(d));

  const latestIdx = earnings.findIndex((item) => !item.endDate);
  const latest = latestIdx > -1 && earnings.splice(latestIdx, 1)[0];

  return {
    earnings,
    latest,
  };
};

export default schema;
